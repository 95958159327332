<template>
  <a-spin :spinning="loading">
    <!-- Confirmation Modal -->
    <a-modal
      v-if="confirmationModal"
      style="top: 20px;"
      centered
      :title="$t('label.delete.alert')"
      :closable="false"
      :maskClosable="false"
      v-model:visible="confirmationModal"
    >
      <render-icon icon="question-circle-outlined" :style="{fontSize: '24px', color: 'orange'}"></render-icon>
      <p style="margin-top: 20px;"> {{ confirmationMessage }}</p>
      <span>{{ $t('label.name') + ': ' + recordToDelete.alert_name || undefined }}</span><br/>
      <span>{{ $t('label.type') + ': ' + recordToDelete.usage_type || undefined }}</span><br/>
      <span>{{ $t('label.threshold') + ': ' + recordToDelete.threshold + ' %' || undefined }}</span><br/>
      <p>{{ $t('label.schedule') + ': ' + $t('label.' + recordToDelete.start_day) + '-' + this.$t('label.' + recordToDelete.end_day) + ', ' + (recordToDelete.start_time.split(':')[0] + ':' + recordToDelete.start_time.split(':')[1]) + '-' + (recordToDelete.end_time.split(':')[0] + ':' + recordToDelete.end_time.split(':')[1]) }}</p>
      <template #footer style="text-align: right;">
        <a-button id="alertConfirmationCancelButton" type="default" @click="confirmationModal=false">Cancel</a-button>
        <a-button id="alertConfirmationOkayButton" type="primary" @click="deleteAlert(recordToDelete.id)">OK</a-button>
      </template>
    </a-modal>
    <!-- Add Alert Modal -->
    <a-modal
      v-model:visible="showAddAlert"
      :title="$t('label.add.alert')"
      :closable="true"
      :footer="null"
      centered>
      <a-form
        v-ctrl-enter="submitAddAlert"
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="submitAddAlert"
        layout="vertical"
      >
      <a-form-item ref="enabled" name="enabled">
        <a-checkbox v-model:checked="form.enabled">
          {{ $t('label.activate') + '/' + $t('label.deactivate') }}
        </a-checkbox>
      </a-form-item>
      <a-form-item ref="alertName" name="alertName" :label="$t('label.name')">
        <a-input v-model:value="form.alertName" :placeholder="$t('label.enter.alert.name')"></a-input>
      </a-form-item>
      <a-row>
        <a-col :span="12">
          <a-form-item ref="usageType" name="usageType" :label="$t('label.create.alert.for')">
            <a-radio-group v-model:value="form.usageType">
              <a-radio :style="radioStyle" value="CPU">CPU</a-radio>
              <a-radio :style="radioStyle" value="RAM">RAM usage</a-radio>
              <a-radio :style="radioStyle" value="DISK_USAGE">Disk Usage</a-radio>
              <a-radio :style="radioStyle" value="UP">Up/Down</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item v-if="form.usageType === 'CPU' || form.usageType === 'RAM'" ref="threshold" name="threshold" :label="$t('label.threshold') + ' (%)'">
            <a-input-number v-model:value="form.threshold">
            </a-input-number>
          </a-form-item>
          <div v-if="form.usageType === 'DISK_USAGE'">
            <span v-if="diskData === null || diskData.length === 0">{{ $t('label.select.alert.disk.error') }}</span>
            <span v-else>{{ $t('label.select.alert.disk') }}</span>
            <div v-for="disk in diskData" :key="disk.name">
              <a-form-item :ref="disk.name" :name="disk.name" v-if="diskData.length > 0">
                <a-checkbox v-model:checked="form[disk.name]" @change="diskCheck"/>
                <span style="margin-left: 10px;">{{$t('label.volume') +': ' + disk.name }}</span>
                <span>{{ '  - ' + disk.threshold + ' GB' }}</span>
              </a-form-item>
              <a-form-item v-if="form[disk.name] === true" :ref="'threshold' + disk.name" :name="'threshold' + disk[0]">
                <span style="margin-right: 10px;">{{ $t('label.threshold') + ' (%)' }}</span>
                <a-input-number size="small" v-model:value="form['threshold' + disk.name]">
                </a-input-number>
              </a-form-item>
            </div>
          </div>
        </a-col>
      </a-row>
        <a-form-item ref="schedules" name="schedules" :label="$t('label.select.schedule')">
          <a-select v-model:value="form.schedules">
            <a-select-option v-for="schedule in accountSchedules" :key="schedule.id" :value="schedule.id">
              <span>{{ schedule.name + ' (' + $t('label.' + schedule.start_day) + '-' + $t('label.' + schedule.end_day) + ', ' + (schedule.start_time.split(':')[0] + ':' + schedule.start_time.split(':')[1]) + '-'+ (schedule.end_time.split(':')[0] + ':' + schedule.end_time.split(':')[1]) + ')'}}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <div style="text-align: right;">
          <a-button :loading="loading" style="margin-right: 4px" @click="showAddAlert=false">{{ this.$t('label.cancel') }}</a-button>
          <a-button :loading="loading" type="primary" @click="submitAddAlert()">{{ this.$t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-modal>
    <!-- Edit Alert -->
    <a-modal
      v-model:visible="showEditAlert"
      :title="$t('label.edit.alert')"
      :closable="true"
      :footer="null"
      centered>
      <a-form
        v-ctrl-enter="submitEditAlert"
        :ref="editFormRef"
        :model="editForm"
        :rules="editRules"
        @finish="submitEditAlert"
        layout="vertical"
      >
      <div style="display: flex; justify-content: space-between;">
        <div>
          <a-form-item ref="enabled" name="enabled">
            <a-checkbox v-model:checked="editForm.enabled">
              {{ $t('label.activate') + '/' + $t('label.deactivate') }}
            </a-checkbox>
          </a-form-item>
        </div>
      </div>
      <a-form-item ref="alertName" name="alertName" :label="$t('label.name')">
        <a-input v-model:value="editForm.alertName" :placeholder="$t('label.enter.alert.name')"></a-input>
      </a-form-item>
      <a-row>
        <a-col :span="12">
          <a-form-item ref="usageType" name="usageType" :label="$t('label.create.alert.for')">
            <a-radio-group v-model:value="editForm.usageType">
              <a-radio :style="radioStyle" value="CPU">CPU</a-radio>
              <a-radio :style="radioStyle" value="RAM">RAM usage</a-radio>
              <a-radio :style="radioStyle" value="DISK_USAGE">Disk Usage</a-radio>
              <a-radio :style="radioStyle" value="UP">Up/Down</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item v-if="editForm.usageType === 'CPU' || editForm.usageType === 'RAM'" ref="threshold" name="threshold" :label="$t('label.threshold') + ' (%)'">
            <a-input-number v-model:value="editForm.threshold">
            </a-input-number>
          </a-form-item>
          <div v-if="editForm.usageType === 'DISK_USAGE'">
            <span v-if="diskData === null || diskData.length === 0">{{ $t('label.select.alert.disk.error') }}</span>
            <span v-else>{{ $t('label.select.alert.disk') }}</span>
            <div v-for="disk in diskData" :key="disk.name">
              <a-form-item :ref="disk.name" :name="disk.name" v-if="diskData.length > 0">
                <a-checkbox v-model:checked="editForm[disk.name]" @change="diskCheck"/>
                <span style="margin-left: 10px;">{{$t('label.volume') +': ' + disk.name }}</span>
                <span>{{ '  - ' + disk.threshold + ' GB' }}</span>
              </a-form-item>
              <a-form-item v-if="editForm[disk.name] === true" :ref="'threshold' + disk.name" :name="'threshold' + disk.name">
                <span style="margin-right: 10px;">{{ $t('label.threshold') + ' (%)' }}</span>
                <a-input-number size="small" v-model:value="editForm['threshold' + disk.name]">
                </a-input-number>
              </a-form-item>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-form-item ref="schedules" name="schedules" :label="$t('label.select.schedule')">
        <a-select v-model:value="editForm.schedules">
          <a-select-option v-for="schedule in accountSchedules" :key="schedule.id" :value="schedule.id">
            <span>{{  schedule.name + ' (' + $t('label.' + schedule.start_day) + '-' + $t('label.' + schedule.end_day) + ', ' + (schedule.start_time.split(':')[0] + ':' + schedule.start_time.split(':')[1]) + '-'+ (schedule.end_time.split(':')[0] + ':' + schedule.end_time.split(':')[1]) + ')' }}</span>
          </a-select-option>
        </a-select>
      </a-form-item>
      <div style="text-align: right;">
        <a-button :loading="loading" style="margin-right: 4px" @click="showEditAlert=false">{{ this.$t('label.cancel') }}</a-button>
        <a-button :loading="loading" type="primary" @click="submitEditAlert()">{{ this.$t('label.ok') }}</a-button>
      </div>
      </a-form>
    </a-modal>
    <!-- Table Content -->

    <div style="display: flex; width: 100%; flex-direction: column;">
      <div class="card-grey">
        <div class='card-grey-header' style="display: flex; align-items: center; margin-bottom: 10px; justify-content: space-between;">
          <div class="title" style="display: flex;font-weight: 500; align-items: center;"><i class='cloud-icon-bell is--blue'></i> Alerts</div>
          <a-button @click="openAlertModal()" style="margin-left: 5px;"><PlusOutlined/>{{ $t('label.add.alert') }}</a-button>
        </div>
      <div style="margin-bottom: 20px;">
        <a-table
          :bordered="true"
          size="small"
          style="overflow-y: auto"
          :columns="alertColumns"
          :dataSource="alertList"
          :rowKey="record => record.id"
          :pagination="false"
        >
        <template #alert_status="{ record }">
        <status v-if="record.alert_status" :text="'Activated'" displayText />
        <status v-else :text="'deactivated'" displayText />
      </template>
      <template #alert_name="{ record }">
          <span v-if="record.alert_name === 'undefined' || record.alert_name === '' || record.alert_name === null || record.alert_name === undefined">{{ '-' }}</span>
          <span v-else>{{ record.alert_name }}</span>
        </template>
        <template #name="{ record }">
          <Popover placement="left">
            <template #content>
              <div>
                <span>{{ $t('label.' + record.start_day) + '-' + $t('label.' + record.end_day) + ', ' + (record.start_time.split(':')[0] + ':' + record.start_time.split(':')[1]) + '-' + (record.end_time.split(':')[0] + ':' + record.end_time.split(':')[1]) }}<br/></span>
                <span v-for="(contact) in record.contacts" :key="contact.contact">
                  {{ contact.contact }}
                  <br/>
                </span>
              </div>
            </template>
            <span>{{ record.scheduler_name + ' '}}<InfoCircleOutlined /></span>
          </Popover>
        </template>
        <template #threshold="{ record }">
          <div v-if="record.usage_type === 'DISK_USAGE'">
            <span v-for="disk in record.data" :key="disk.name">{{ disk.name + ': ' + disk.threshold + ' %'}}<br/></span>
          </div>
          <span v-else-if="record.usage_type === 'UP'">{{ '-' }}</span>
          <span v-else>{{ record.threshold + ' %' }}</span>
        </template>
        <template #start_day="{ record }">
          <span>{{ $t('label.' + record.start_day) + '-' + $t('label.' + record.end_day) + ', ' + (record.start_time.split(':')[0] + ':' + record.start_time.split(':')[1]) + '-' + (record.end_time.split(':')[0] + ':' + record.end_time.split(':')[1]) }}</span>
        </template>
        <template #edit="{ record }">
          <span>
            <tooltip-button
              tooltipPlacement="left"
              :tooltip="$t('label.edit.alert')"
              icon="editOutlined"
              type="default"
              @click="editAlert(record)" />
          </span>
          <span style="margin-left: 5px;">
            <tooltip-button
              tooltipPlacement="top"
              :tooltip="$t('label.delete.alert')"
              icon="deleteOutlined"
              type="primary"
              danger
              @click="openDeleteConfirm(record)" />
          </span>
        </template>
        </a-table>
      </div>
      </div>
      <div class="card-grey">

      <div class='card-grey-header' style="margin-bottom: 10px;">{{ 'Alert logs ' + $t('label.30.days') + ' '}}<a-button style='margin-left: 10px' type="default" shape="round" size="small" @click="getAlertLogs()"><ReloadOutlined></ReloadOutlined> {{ $t('label.refresh') }}</a-button></div>
      <div>
        <a-table
          :bordered="true"
          size="small"
          style="overflow-y: auto"
          :columns="logColumns"
          :dataSource="alertLogList"
          :rowKey="record => record.id"
          :pagination="false"
        >
        <template #status_alert="{ record }">
          <status v-if="record.status_alert === true" :text="'Alert'" displayText />
          <status v-else :text="'Solved'" displayText />
        </template>
        </a-table>
      </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import TooltipButton from '@/components/widgets/TooltipButton'
import { reactive, ref, toRaw } from 'vue'
import { api } from '@/api'
import api2 from '@/wpApi/api2'
import { Popover } from 'ant-design-vue'
import Status from '@/components/widgets/Status'

export default {
  name: 'VmMonitoring',
  components: {
    TooltipButton,
    Popover,
    Status
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  watch: {
    resource: {
      deep: true,
      async handler (newItem) {
        if (!newItem || !newItem.id) {
          return
        }
        this.getAlerts()
        this.getAlertLogs()
        await this.getAccountUUID()
        this.getSchedules()
      }
    }
  },
  data () {
    return {
      accountSchedules: [],
      accountUuid: this.$store.getters.userInfo.accountid,
      alertList: [],
      alertLogList: [],
      confirmationModal: false,
      confirmationMessage: '',
      currentAlertEditId: undefined,
      diskData: [],
      loading: false,
      recordToDelete: [],
      showAddAlert: false,
      showDeleteAlert: false,
      showEditAlert: false,
      vmOwnerAccountUUID: '',
      mockAlerts: [
        {
          id: 10,
          alert_name: 'My Alert',
          status: 'alert',
          account_uuid: '84d077a1-bc8f-4e8d-8927-f5060b80e2f8',
          name: '24/7 Schedule',
          instance_name: 'TEST',
          usage_type: 'CPU',
          threshold: 80,
          email: 'test@test.test',
          service: 'telegram',
          phone: '000000',
          start_day: 'monday',
          end_day: 'Sunday',
          start_time: '08:00',
          end_time: '23:59',
          scheduler: 10,
          enabled: true
        },
        {
          id: 6,
          alert_name: '',
          status: 'activated',
          name: '24/7 Schedule',
          account_uuid: '84d077a1-bc8f-4e8d-8927-f5060b80e2f8',
          instance_name: 'TEST',
          usage_type: 'RAM',
          email: 'test@test.test',
          phone: '000000',
          service: 'email',
          start_day: 'monday',
          end_day: 'Tuesday',
          start_time: '08:00',
          end_time: '17:00',
          threshold: 95,
          scheduler: 6,
          enabled: true
        },
        {
          id: 6,
          status: 'activated',
          name: 'Mon-Tue - RAM 90',
          account_uuid: '84d077a1-bc8f-4e8d-8927-f5060b80e2f8',
          instance_name: 'TEST',
          usage_type: 'RAM',
          email: 'test@test.test',
          phone: '000000',
          service: 'email',
          start_day: 'monday',
          end_day: 'Tuesday',
          start_time: '08:00',
          end_time: '17:00',
          threshold: 90,
          scheduler: 10,
          enabled: false
        }
      ],
      mockSchedules: [
        {
          id: 9,
          time: 'Mon-Fri 08:00-22:00',
          receive: 'Telegram'
        },
        {
          id: 11,
          time: 'Mon-Tue 24/7',
          receive: 'Email'
        },
        {
          id: 10,
          time: 'Sunday 08:00-16:00',
          receive: 'Email'
        }
      ],
      alertLogs: [
        {
          message: 'CPU Usage von VM "TerminalServer" beträgt über 95%',
          time: 'Tue 4 16:13'
        },
        {
          message: 'DISK Usage von VM "DemoReCus2-TS" beträgt über 80%',
          time: 'Frei 7 22:01'
        },
        {
          message: 'RAM Auslastung von VM "Anwendungsserver" beträgt 100%',
          time: 'Mon 10 08:55'
        }
      ],
      alertColumns: [
        {
          title: this.$t('label.name'),
          dataIndex: 'alert_name',
          slots: { customRender: 'alert_name' }
        },
        {
          title: this.$t('label.status'),
          dataIndex: 'alert_status',
          slots: { customRender: 'alert_status' }
        },
        {
          title: this.$t('label.type'),
          dataIndex: 'usage_type',
          width: '5em'
        },
        {
          title: this.$t('label.schedule'),
          dataIndex: 'name',
          slots: { customRender: 'name' }
        },
        /* {
          title: this.$t('label.created'),
          dataIndex: 'receive'
        }, */
        {
          title: this.$t('label.threshold'),
          dataIndex: 'threshold',
          slots: { customRender: 'threshold' }
        },
        {
          title: this.$t('label.update.at'),
          dataIndex: 'updated_at'
        },
        {
          title: this.$t('label.edit'),
          dataIndex: 'edit',
          slots: { customRender: 'edit' },
          align: 'center',
          width: '6.5rem'
        }
      ],
      logColumns: [
        {
          title: this.$t('label.status'),
          dataIndex: 'status_alert',
          slots: { customRender: 'status_alert' },
          width: '8em'
        },
        {
          title: this.$t('label.alert.logs'),
          dataIndex: 'value'
        },
        {
          title: this.$t('label.created'),
          dataIndex: 'alert_time',
          width: '12em'
        }
      ],
      mockDiskData: [{ name: 'sda', threshold: 80 }, { name: 'sdb', threshold: 60 }]
    }
  },
  created () {
    this.initForm()
    this.getPrometheusDiskData()
  },
  async mounted () {
    this.getAlerts()
    this.getAlertLogs()
    await this.getAccountUUID()
    this.getSchedules()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.editFormRef = ref()
      this.form = reactive({
        alertName: undefined,
        schedules: this.accountSchedules[0] !== undefined ? this.accountSchedules[0].id : undefined,
        threshold: 50 || undefined,
        usageType: 'CPU',
        enabled: true
      })
      this.editForm = reactive({
        alertName: undefined
      })
      this.rules = reactive({
        schedules: [{ required: true, message: `${this.$t('label.required')}` }],
        threshold: [{ required: true, message: `${this.$t('label.required')}` }],
        usageType: [{ validator: this.validateDiskSelected }]
      })
      this.editRules = reactive({
        schedules: [{ required: true, message: `${this.$t('label.required')}` }],
        threshold: [{ required: true, message: `${this.$t('label.required')}` }],
        usageType: [{ validator: this.validateDiskSelectedEdit }]
      })
      this.radioStyle = reactive({
        display: 'flex',
        alignItems: 'center',
        height: '30px',
        lineHeight: '30px'
      })
    },
    async getAccountUUID () {
      await api('listAccounts', {
        name: this.resource.account,
        listall: true
      }).then(json => {
        this.vmOwnerAccountUUID = json.listaccountsresponse.account[0].id
      }).catch(error => {
        console.error(error)
      })
    },
    diskCheck (e) {
      if (e.target.checked === true) {
        this.rules['threshold' + e.target.id] = [{ required: true, message: `${this.$t('label.required')}` }]
        this.editRules['threshold' + e.target.id] = [{ required: true, message: `${this.$t('label.required')}` }]
      } else {
        this.rules['threshold' + e.target.id] = [{ required: false, message: `${this.$t('label.required')}` }]
        this.editRules['threshold' + e.target.id] = [{ required: false, message: `${this.$t('label.required')}` }]
      }
      if (this.showAddAlert) {
        this.formRef.value.clearValidate()
      } else if (this.showEditAlert) {
        this.editFormRef.value.clearValidate()
      }
    },
    async getPrometheusDiskData () {
      this.loading = true
      await api2.getPrometheusDiskNames(this.$route.params.id).then(response => {
        if (response.data !== null) {
          this.diskData = response.data
          this.loading = false
        }
      }).catch(error => {
        this.diskData = []
        console.log(error)
      })
      this.loading = false
    },
    async getSchedules () {
      await api2.getSchedulesByAccount(this.vmOwnerAccountUUID).then(response => {
        if (response.data !== null) {
          this.accountSchedules = response.data
        } else if (response.data === null || response.data.length === 0) {
          this.$notification.error({
            description: 'There are no Schedules defined for this account. Please create schedules for this account in the account view before adding Alerts.',
            message: 'No Schedules available!',
            duration: 0
          })
        }
      }).catch(error => {
        this.accountSchedules = []
        console.log(error)
        this.$notification.error({
          message: 'Error fetching Schedules for Alerts. Please try again. If this problem persists please conctact your administrator',
          description: 'Error fetching Schedules!',
          duration: 0
        })
      })
    },
    openAlertModal () {
      this.loading = true
      this.showAddAlert = true
      this.form.schedules = this.accountSchedules.length > 0 ? this.accountSchedules[0].id : undefined
      this.loading = false
    },
    getAlertLogs () {
      api2.getAlertLogs(this.$route.params.id).then(response => {
        if (response.data !== null) {
          this.alertLogList = [...response.data].reverse()
        } else {
          this.alertLogList = []
        }
      }).catch(error => {
        this.alertLogList = []
        console.log(error)
      })
    },
    getAlerts () {
      this.loading = true
      api2.getAlertList(this.$route.params.id).then(response => {
        if (response.data !== null) {
          this.alertList = response.data
        } else if (response.data === null) {
          this.alertList = []
        } else {
          this.$notification.error({
            message: 'Error fetching Alerts!',
            description: 'Error fetching Alerts. Please try again. If this problem persists please conctact your administrator',
            duration: 0
          })
        }
        this.loading = false
      })
    },
    submitAddAlert () {
      this.formRef.value.validate().then(() => {
        this.showAddAlert = false
        this.loading = true
        const values = toRaw(this.form)
        var addAlertParams = new FormData()
        addAlertParams.append('uuid', this.vmOwnerAccountUUID)
        addAlertParams.append('instanceuuid', this.$route.params.id)
        addAlertParams.append('name', values.alertName)
        addAlertParams.append('usageType', values.usageType)
        if (values.usageType === 'CPU' || values.usageType === 'RAM') {
          addAlertParams.append('threshold', values.threshold)
          addAlertParams.append('diskObject', null)
        } else if (values.usageType === 'UP') {
          addAlertParams.append('threshold', 0)
          addAlertParams.append('diskObject', null)
        } else {
          addAlertParams.append('threshold', 0)
          var diskObjList = []
          for (var disk of this.diskData) {
            if (values[disk.name] === true) {
              var diskObj = {
                name: disk.name,
                threshold: values['threshold' + disk.name]
              }
              diskObjList.push(diskObj)
            }
          }
          addAlertParams.append('diskObject', JSON.stringify(diskObjList))
        }
        addAlertParams.append('scheduler', values.schedules)
        if (values.enabled) {
          addAlertParams.append('alert_status', 1)
        } else {
          addAlertParams.append('alert_status', 0)
        }
        api2.addAlert(addAlertParams).then(response => {
          this.getAlerts()
          this.$notification.success({
            message: this.$t('label.success'),
            description: this.$t('message.success.create.alert'),
            duration: 6
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: this.$t('message.error.create.alert'),
              description: this.$t('message.error.create.alert2'),
              duration: 0
            })
          })
        })
        this.loading = false
      })
    },
    submitEditAlert () {
      this.editFormRef.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.editForm)
        var editAlertParams = new FormData()
        editAlertParams.append('alertName', values.alertName || undefined)
        editAlertParams.append('id', this.currentAlertEditId)
        editAlertParams.append('usageType', values.usageType)
        if (values.usageType === 'CPU' || values.usageType === 'RAM') {
          editAlertParams.append('threshold', values.threshold)
          editAlertParams.append('jsData', null)
        } else if (values.usageType === 'UP') {
          editAlertParams.append('threshold', 0)
          editAlertParams.append('jsData', null)
        } else {
          editAlertParams.append('threshold', 0)
          var diskObjList = []
          for (var disk of this.diskData) {
            if (values[disk.name] === true) {
              var diskObj = {
                name: disk.name,
                threshold: values['threshold' + disk.name]
              }
              diskObjList.push(diskObj)
            }
          }
          editAlertParams.append('jsData', JSON.stringify(diskObjList))
        }
        editAlertParams.append('schedulerID', values.schedules)
        if (values.enabled) {
          editAlertParams.append('status', 1)
        } else {
          editAlertParams.append('status', 0)
        }
        await api2.editAlert(editAlertParams).then(response => {
          this.getAlerts()
          this.$notification.success({
            message: this.$t('message.success.edit.alert'),
            duration: 5
          })
          this.showEditAlert = false
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: this.$t('message.error.create.alert'),
            description: this.$t('message.error.create.alert2'),
            duration: 0
          })
        })
        this.loading = false
      })
    },
    async editAlert (data) {
      this.loading = true
      await this.getPrometheusDiskData()
      this.currentAlertEditId = data.id
      this.editForm.schedules = data.schedule
      for (const disk of this.diskData) {
        if (data.usage_type === 'DISK_USAGE') {
          var savedDiskObj = data.data.filter(diskObj => diskObj.name === disk.name)
          if (savedDiskObj.length > 0) {
            this.editForm[disk.name] = true
            this.editForm['threshold' + disk.name] = savedDiskObj[0].threshold
          } else {
            this.editForm[disk.name] = false
            this.editForm['threshold' + disk.name] = undefined
          }
        } else {
          this.editForm[disk.name] = false
          this.editForm['threshold' + disk.name] = undefined
        }
      }
      if (data.usage_type === 'CPU' || data.usage_type === 'RAM') {
        this.editForm.threshold = data.threshold || undefined
      } else {
        this.editForm.threshold = undefined
      }
      this.editForm.enabled = data.alert_status
      this.editForm.alertName = (data.alert_name === 'undefined' || data.alert_name === undefined) ? undefined : data.alert_name
      this.editForm.usageType = data.usage_type
      this.loading = false
      this.showEditAlert = true
    },
    openDeleteConfirm (record) {
      this.recordToDelete = record
      this.confirmationModal = true
      this.confirmationMessage = this.$t('message.delete.alert')
    },
    deleteAlert (alertId) {
      var deleteAlertParams = new FormData()
      deleteAlertParams.append('id', alertId)
      api2.deleteAlert(deleteAlertParams).then(response => {
        this.getAlerts()
      })
      this.confirmationModal = false
    },
    validateDiskSelected (rule, value) {
      if (value === 'DISK_USAGE') {
        var errorMessage = this.$t('message.no.volume.selected')
        var isAnyDiskSelected = false
        for (const disk of this.diskData) {
          if (this.form[disk.name] === true) {
            isAnyDiskSelected = true
            if (this.form['threshold' + disk.name] === undefined || this.form['threshold' + disk.name] <= 0) {
              var errorM = this.$t('message.no.threshold.input')
              return Promise.reject(errorM)
            } else { Promise.resolve() }
          }
        }
        if (isAnyDiskSelected) {
          return Promise.resolve()
        } else {
          return Promise.reject(errorMessage)
        }
      } else {
        return Promise.resolve()
      }
    },
    validateDiskSelectedEdit (rule, value) {
      if (value === 'DISK_USAGE') {
        var errorMessage = this.$t('message.no.volume.selected')
        var isAnyDiskSelected = false
        for (const disk of this.diskData) {
          if (this.editForm[disk.name] === true) {
            isAnyDiskSelected = true
            if (this.editForm['threshold' + disk.name] === undefined || this.editForm['threshold' + disk.name] <= 0) {
              var errorM = this.$t('message.no.threshold.input')
              return Promise.reject(errorM)
            } else { Promise.resolve() }
          }
        }
        if (isAnyDiskSelected) {
          return Promise.resolve()
        } else { return Promise.reject(errorMessage) }
      } else {
        return Promise.resolve()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-weight: bold;
  }
</style>
