// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <!-- eslint-disable -->
  <a-layout-sider
    :class="['sider', isDesktop() ? null : 'shadow', theme, fixSiderbar ? 'ant-fixed-sidemenu' : null ]"
    width="256px"
    collapsible
    v-model:collapsed="isCollapsed"
    :trigger="null">
    <!-- eslint-disable -->
    <logo />
    <s-menu
      :collapsed="isCollapsed"
      :menu="menus"
      :theme="theme"
      :mode="mode"
      @select="onSelect"></s-menu>
    <!-- One time Acronis URL for logged in Account -->
    <a-tooltip placement="right">
      <div @click="linkClicked()" class="cyberCloudLink">
        <LinkOutlined v-if="collapsed" style="padding-left: 8px;" class="invoiceIcon" />
        <LinkOutlined v-else class="invoiceIcon" />
        <span v-if="!collapsed">{{ 'Cyber Cloud Login' }}</span>
      </div>
    </a-tooltip>
    <download-invoices :collapsed="isCollapsed" v-if="$store.getters.userInfo.roletype !== 'Admin' && $store.getters.userInfo.rolename === 'ResellerAdmin' && $store.getters.userInfo.rolename !== 'ResellerCustomer'"></download-invoices>
  </a-layout-sider>

</template>

<script>
import ALayoutSider from 'ant-design-vue/es/layout/Sider'
import Logo from '../header/Logo'
import SMenu from './index'
import { mixin, mixinDevice } from '@/utils/mixin.js'
import DownloadInvoices from '@/views/plugins/DownloadInvoices'
import api2 from '@/wpApi/api2'

export default {
  name: 'SideMenu',
  components: { ALayoutSider, Logo, SMenu, DownloadInvoices },
  mixins: [mixin, mixinDevice],
  props: {
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    menus: {
      type: Array,
      required: true
    }
  },
  // WP Implementation
  data () {
    return {
      onetimeUrl: undefined,
      loggedInAccId: this.$store.getters.userInfo.accountid,
      acronisStatusActive: false,
      oneTimeUrlCalled: false
    }
  },
  // /WP Implementation
  computed: {
    isCollapsed () {
      return this.collapsed
    }
  },
  watch: {
    '$route' (to, from) {
      if (from.name === 'dashboard') {
        if (this.$store.getters.getTotalChartObject !== null) {
          this.$store.getters.getTotalChartObject.destroy()
          this.$store.getters.getChartObject.destroy()
        }
      }
    }
  },
  // WP Implementation
  async mounted () {
  },
  // /WP Implementation
  methods: {
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    },
    // WP Implementation
    async linkClicked () {
      await this.fetchAcronisUserStatus()
      if (this.acronisStatusActive) {
        var urlParams = new FormData()
        urlParams.append('accountid', this.loggedInAccId)
        await api2.generateOneTimeUrl(urlParams).then(response => {
          if (response.data && response.data.status !== 'error' && response.data.includes('acronis.com')) {
            var a = document.createElement('a')
            a.href = response.data
            a.setAttribute('target', '_blank')
            a.click()
          } else {
            console.log('Error creating one time Cyber Cloud login!')
          }
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    async fetchAcronisUserStatus () {
      var getUserParams = new FormData()
      getUserParams.append('account', this.loggedInAccId)
      await api2.getAcronisUser(getUserParams).then(response => {
        if (response.data.status === 'ok') {
          this.acronisStatusActive = true
        } else {
          this.$notification.error({
            message: this.$t('label.error'),
            description: this.$t('label.pbs.not.activated')
          })
          this.acronisStatusActive = false
        }
      }).catch(() => {
        this.$notification.error({
          message: this.$t('label.error'),
          description: this.$t('label.pbs.not.activated')
        })
      })
    }
    // /WP Implementation
  }
}
</script>

<style lang="less" scoped>
.cyberCloudLink {
  margin-bottom: 20px;
}
.cyberCloudLink:hover {
  color: #3D7CAF;
  cursor: pointer;
}
.invoiceIcon {
  font-size: 18px;
  margin-left: 1.5rem;
  margin-right: 0.75rem;
}
.sider {
  box-shadow: 2px 0 6px rgba(0, 21, 41, .35);
  position: relative;
  z-index: 10;
  height: auto;

  :deep(.ant-layout-sider-children) {
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
  }

  :deep(.ant-menu-vertical) .ant-menu-item {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  :deep(.ant-menu-inline) .ant-menu-item:not(:last-child) {
    margin-bottom: 0px;
  }

  :deep(.ant-menu-inline) .ant-menu-item {
    margin-top: 0px;
  }

  &.ant-fixed-sidemenu {
    position: fixed;
    height: 100%;
  }

  &.light {
    box-shadow: 2px 0px 8px 0px rgba(29, 35, 41, 0.05);

    .ant-menu-light {
      border-right-color: transparent;
      padding: 14px 0;
    }
  }

  &.dark {
    .ant-menu-dark {
      border-right-color: transparent;
      padding: 14px 0;
    }
  }
}
</style>
