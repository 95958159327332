export default {
  name: 'cyberCloudEndpoint',
  title: 'Cyber Cloud Endpoint',
  icon: 'cloud-server-outlined',
  listView: true,
  component: () => import('@/views/plugins/cyberCloudEndpoint.vue')
  /* name: 'cyberCloud',
  title: 'Cyber Cloud',
  icon: 'cloud-sync', */
  /* children: [
    {
      name: 'cyberCloudEndpoint',
      title: 'Cyber Cloud Endpoint',
      icon: 'cloud-server',
      listView: true,
      component: () => import('@/views/plugins/cyberCloudEndpoint.vue')
    },
    {
      name: 'cyberCloudLogin',
      title: 'Cyber Cloud Login',
      icon: 'link-o',
      component: () => import('@/views/plugins/cyberCloudLogin.vue')
    }
  ] */
}
