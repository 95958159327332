<template>
  <a-spin :spinning="loading">
  <div style="display: flex; justify-content: flex-end;">
    <a-select
      style="width: 9em;"
      @change="onTimeChange"
      default-value="1h"
      size="middle">
      <a-select-option value="1h">
        {{ '1 ' + $t('label.hour') }}
      </a-select-option>
      <a-select-option value="24h">
        {{ '24 ' + $t('label.hours') }}
      </a-select-option>
      <a-select-option value="3d">
        {{ '3 ' + $t('label.days') }}
      </a-select-option>
      <a-select-option value="7d">
        {{ '7 ' + $t('label.days') }}
      </a-select-option>
      <a-select-option value="14d">
        {{ '14 ' + $t('label.days') }}
      </a-select-option>
      <a-select-option value="30d">
        {{ '30 ' + $t('label.days') }}
      </a-select-option>
    </a-select>
  </div>
  <div>
    <canvas id="CpuGraph" ref="canvas" width="400" height="200"></canvas>
  </div>
  <a-divider/>
  <div>
      <canvas id="MemGraph" ref="canvas" width="400" height="200"></canvas>
  </div>
  <a-divider/>
  <div>
    <canvas id="DiskGraph" ref="canvas" width="400" height="200"></canvas>
  </div>
  <a-divider/>
  <div>
    <canvas id="DiskGraph2" ref="canvas" width="400" height="200"></canvas>
  </div>
  <a-divider/>
    <div>
    <canvas id="NetGraph" ref="canvas" width="400" height="200"></canvas>
  </div>
  <a-divider/>
  </a-spin>
</template>

<script>
import api2 from '@/wpApi/api2'
import moment from 'moment'
import Chart from 'chart.js'
import ChartDatasourcePrometheusPlugin from 'chartjs-plugin-datasource-prometheus'
export default {
  name: 'VmMonitoring',
  components: { Chart, ChartDatasourcePrometheusPlugin, moment },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      instanceName: '',
      cpuChart: undefined,
      memChart: undefined,
      diskChart: undefined,
      diskChart2: undefined,
      netChart: undefined,
      startTimeRangeIndicator: undefined
    }
  },
  computed: {
    getCurrentDate: function () {
      var d = new Date()
      // d.subtract(1, 'hours')
      return d
    }
  },
  async mounted () {
    await this.getInstanceName()
    this.initChart()
  },
  methods: {
    onTimeChange (value) {
      this.startTimeRangeIndicator = value
      this.cpuChart.destroy()
      this.memChart.destroy()
      this.diskChart.destroy()
      this.diskChart2.destroy()
      this.netChart.destroy()
      this.initChart()
    },
    async getInstanceName () {
      var uuidParams = new FormData()
      uuidParams.append('uuid', this.$route.params.id)
      await api2.uuid2InstanceName(uuidParams).then(response => {
        if (response.data && response.data.status && response.data.status === 'error') {
          this.instanceName = 'noInstanceName'
        } else if (response.data && response.data.status && response.data.status === 'ok') {
          this.instanceName = response.data.instancename
        }
      }).catch((error) => {
        this.instanceName = 'noInstanceName'
        console.log(error)
        console.log('Instance Name of the VM could not be fetched!')
      })
    },
    initChart () {
      var startTime = 0
      if (this.startTimeRangeIndicator === undefined) {
        startTime = -1 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '1h') {
        startTime = -1 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '24h') {
        startTime = -24 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '3d') {
        startTime = -3 * 24 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '7d') {
        startTime = -7 * 24 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '14d') {
        startTime = -14 * 24 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '30d') {
        startTime = -30 * 24 * 60 * 60 * 1000
      }
      var cpu = document.getElementById('CpuGraph')
      var mem = document.getElementById('MemGraph')
      var disk = document.getElementById('DiskGraph')
      var disk2 = document.getElementById('DiskGraph2')
      var net = document.getElementById('NetGraph')
      // eslint-disable-next-line
      this.cpuChart = new Chart(cpu, {
        type: 'line',
        plugins: [ChartDatasourcePrometheusPlugin],
        options: {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) => {
                return 'CPU Usage: ' + data.datasets[0].data[tooltipItems.index].y + ' %'
              }
            }
          },
          title: {
            display: true,
            text: 'CPU usage in %'
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: 100
                }
              }
            ]
          },
          plugins: {
            'datasource-prometheus': {
              prometheus: {
                endpoint: 'https://cloud.mitteldeutsche-it.de',
                baseURL: '/metrics/api/v1'
              },
              query: 'round(100 * sum(irate(libvirt_domain_info_cpu_time_seconds_total{domain="' + this.instanceName + '"}[5m])) by (domain) / sum(libvirt_domain_info_virtual_cpus{domain="' + this.instanceName + '"}) by (domain) )',
              timeRange: {
                type: 'relative',
                start: startTime,
                end: 0
              },
              fill: true,
              fillGaps: true,
              stepped: true,
              tension: 0
            }
          }
        }
      })
      // eslint-disable-next-line
      this.memChart = new Chart(mem, {
        type: 'line',
        plugins: [ChartDatasourcePrometheusPlugin],
        options: {
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) => {
                var myval = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y / 1024 / 1024 / 1024
                return myval.toFixed(2) + ' GB'
              }
            }
          },
          legend: {
            display: false
          },
          title: {
            display: true,
            text: 'Memory usage'
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (label) {
                    var num = label / 1024 / 1024 / 1024
                    return num.toFixed(2) + 'GB'
                  },
                  beginAtZero: true
                }
              }
            ]
          },
          plugins: {
            'datasource-prometheus': {
              prometheus: {
                endpoint: 'https://cloud.mitteldeutsche-it.de',
                baseURL: '/metrics/api/v1'
              },
              query: ['sum(libvirt_domain_info_maximum_memory_bytes{domain=~"' + this.instanceName + '"} - libvirt_domain_memory_stats_usable_bytes{domain=~"' + this.instanceName + '"}) by (domain)', 'sum(libvirt_domain_info_maximum_memory_bytes{domain=~"' + this.instanceName + '"}) by (domain)'],
              timeRange: {
                type: 'relative',
                start: startTime,
                end: 0
              },
              fill: true,
              fillGaps: true,
              stepped: true,
              tension: 0
            }
          }
        }
      })
      // eslint-disable-next-line
      this.diskChart = new Chart(disk, {
        type: 'line',
        plugins: [ChartDatasourcePrometheusPlugin],
        options: {
          legend: {
            display: true
          },
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) => {
                var mystr = data.datasets[tooltipItems.datasetIndex].label.replace('{', '').replace('}', '').replace(/"/g, '').split('=')
                var newlabel = mystr[0] + ' (' + mystr[1] + '): '
                var myval = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y / 1024 / 1024 / 1024
                return newlabel + myval.toFixed(2) + ' GB'
              }
            }
          },
          title: {
            display: true,
            text: 'Disk usage'
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: function (label) {
                  var num = label / 1024 / 1024 / 1024
                  return num.toFixed(2) + ' GB'
                },
                beginAtZero: true
              }
            }]
          },
          plugins: {
            'datasource-prometheus': {
              prometheus: {
                endpoint: 'https://cloud.mitteldeutsche-it.de',
                baseURL: '/metrics/api/v1'
              },
              query: ['sum(label_replace(libvirt_domain_block_stats_capacity_bytes{domain=~"' + this.instanceName + '"}, "Max", "$1", "target_device", "(.*)")) by (Max)', 'sum(label_replace(libvirt_domain_block_stats_allocation {domain=~"' + this.instanceName + '"}, "Used", "$1", "target_device", "(.*)")) by (Used)'],
              timeRange: {
                type: 'relative',
                start: startTime,
                end: 0
              },
              fill: true,
              fillGaps: true,
              stepped: true,
              tension: 0
            }
          }
        }
      })
      // disk utilization
      this.diskChart2 = new Chart(disk2, {
        type: 'line',
        plugins: [ChartDatasourcePrometheusPlugin],
        options: {
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) => {
                var mystr = data.datasets[tooltipItems.datasetIndex].label.replace('{', '').replace('}', '').replace(/"/g, '').split('=')
                var newlabel = mystr[0] + ' (' + mystr[1] + '): '
                var myval = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y / 1024
                var mylabel = newlabel + myval.toFixed(2) + ' kB'
                return mylabel
              }
            }
          },
          title: {
            display: true,
            text: 'Disk Read/Write utilization'
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: function (label) {
                  var num = label / 1024
                  return num.toFixed(2) + ' kb'
                }
              }
            }]
          },
          plugins: {
            'datasource-prometheus': {
              prometheus: {
                endpoint: 'https://cloud.mitteldeutsche-it.de',
                baseURL: '/metrics/api/v1'
              },
              query: ['sum(label_replace(rate(libvirt_domain_block_stats_read_bytes_total{domain=~"' + this.instanceName + '"}[5m]), "Read", "$1", "target_device", "(.*)")) by (Read)', 'sum(label_replace(rate(libvirt_domain_block_stats_write_bytes_total{domain=~"' + this.instanceName + '"}[5m]), "Write", "$1", "target_device", "(.*)")) by (Write)'],
              timeRange: {
                type: 'relative',
                start: startTime,
                end: 0
              },
              fill: true,
              fillGaps: true,
              stepped: true,
              tension: 0
            }
          }
        }
      })
      // eslint-disable-next-line
      this.netChart = new Chart(net, {
        type: 'line',
        plugins: [ChartDatasourcePrometheusPlugin],
        options: {
          tooltips: {
            callbacks: {
              label: (tooltipItems, data) => {
                var mystr = data.datasets[tooltipItems.datasetIndex].label.replace('{', '').replace('}', '').replace(/"/g, '').split('=')
                var newlabel = mystr[1] + ' (' + mystr[0] + '): '
                var myval = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y / 1024
                var mylabel = newlabel + myval.toFixed(2) + ' kB'
                return mylabel
              }
            }
          },
          title: {
            display: true,
            text: 'Network usage TX/RX in kB'
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: function (label) {
                  var num = label / 1024
                  return num.toFixed(2) + ' kb'
                }
              },
              scaleLabel: {
                display: true,
                labelString: 'Network TX/RX in kbyte'
              }
            }]
          },
          plugins: {
            'datasource-prometheus': {
              prometheus: {
                endpoint: 'https://cloud.mitteldeutsche-it.de',
                baseURL: '/metrics/api/v1'
              },
              query: ['sum(label_replace(rate(libvirt_domain_interface_stats_transmit_bytes_total{domain=~"' + this.instanceName + '"}[5m]), "TX", "$1", "target_device", "(.*)")) by (TX)', 'sum(label_replace(rate(libvirt_domain_interface_stats_receive_bytes_total{domain=~"' + this.instanceName + '"}[5m]), "RX", "$1", "target_device", "(.*)")) by (RX)'],
              timeRange: {
                type: 'relative',
                start: startTime,
                end: 0
              },
              fill: true,
              fillGaps: true,
              stepped: true,
              tension: 0
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.title {
  margin-bottom: 5px;
  font-weight: bold;
}

.activatePbsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12em;
  margin-top: 20px;
}

.shieldIcon {
  width:1.5rem;
  height:1.7rem;
  margin-left: 10px;
}
</style>
