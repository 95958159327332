// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-spin :spinning="componentLoading">
    <a-table
      size="middle"
      :loading="loading"
      :columns="isOrderUpdatable() ? columns : columns.filter(x => x.dataIndex !== 'order')"
      :dataSource="items"
      :rowKey="(record, idx) => record.id || record.name || record.usageType || idx + '-' + Math.random()"
      :pagination="false"
      :rowSelection=" enableGroupAction() || $route.name === 'event' ? {selectedRowKeys: selectedRowKeys, onChange: onSelectChange} : null"
      :rowClassName="getRowClassName"
      style="overflow-y: auto"
    >
    <template
      v-if="$route.path.startsWith('/cyberCloudEndpoint')"
      #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
    >
      <div style="padding: 8px">
        <a-input
          ref="searchInput"
          :placeholder="`Search ${column.dataIndex}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block;"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <a-button
          type="primary"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          <template #icon><SearchOutlined /></template>
          Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
          Reset
        </a-button>
      </div>
    </template>
    <template #filterDropdown v-else>
      <div style="padding: 8px" class="filter-dropdown">
        <a-menu>
          <a-menu-item v-for="(column, idx) in columnKeys" :key="idx" @click="updateSelectedColumns(column)">
            <a-checkbox :id="idx.toString()" :checked="selectedColumns.includes(getColumnKey(column))"/>
            {{ $t('label.' + String(getColumnKey(column)).toLowerCase()) }}
          </a-menu-item>
        </a-menu>
      </div>
    </template>
    <template #footer>
      <span v-if="hasSelected">
        {{ `Selected ${selectedRowKeys.length} items` }}
      </span>
    </template>

    <!--
    <div #expandedRowRender="{ resource }">
      <info-card :resource="resource style="margin-left: 0px; width: 50%">
        <div #actions style="padding-top: 12px">
          <a-tooltip
            v-for="(action, actionIndex) in $route.meta.actions"
            :key="actionIndex"
            placement="bottom">
            <template #title>
              {{ $t(action.label) }}
            </template>
            <a-button
              v-if="action.api in $store.getters.apis && action.dataView &&
                ('show' in action ? action.show(resource, $store.getters.userInfo) : true)"
              :icon="action.icon"
              :type="action.icon === 'delete' ? 'danger' : (action.icon === 'plus' ? 'primary' : 'default')"
              shape="circle"
              style="margin-right: 5px; margin-top: 12px"
              @click="$parent.execAction(action)"
            >
            </a-button>
          </a-tooltip>
        </div>
      </info-card>
    </div>
    -->

    <template #name="{text, record}">
      <!-- WP Implementation -->
      <span v-if="$route.path.startsWith('/cyberCloudEndpoint')">
          <router-link v-if="record.resourceType === 'vm'" :to="{ path: 'vm/' + record.uuid}">{{ text }}</router-link>
          <span v-else>{{ record.name }}</span>
        </span>
        <!-- /WP Implementation -->
      <span v-if="['vm'].includes($route.path.split('/')[1])" style="margin-right: 5px">
        <span v-if="record.icon && record.icon.base64image">
          <resource-icon :image="record.icon.base64image" size="1x"/>
        </span>
        <os-logo v-else :osId="record.ostypeid" :osName="record.osdisplayname" size="lg" />
      </span>
      <span style="min-width: 120px" v-if="!$route.path.startsWith('/cyberCloudEndpoint')">
        <QuickView
          style="margin-left: 5px"
          :actions="actions"
          :resource="record"
          :enabled="quickViewEnabled() && actions.length > 0 && columns && columns[0].dataIndex === 'name' "
          @exec-action="$parent.execAction"/>
        <span v-if="$route.path.startsWith('/project')" style="margin-right: 5px">
          <tooltip-button type="dashed" size="small" icon="LoginOutlined" @onClick="changeProject(record)" />
        </span>
        <span v-if="$showIcon() && !['vm'].includes($route.path.split('/')[1])" style="margin-right: 5px">
          <resource-icon v-if="$showIcon() && record.icon && record.icon.base64image" :image="record.icon.base64image" size="1x"/>
          <os-logo v-else-if="record.ostypename" :osName="record.ostypename" size="1x" />
          <render-icon v-else-if="typeof $route.meta.icon ==='string'" style="font-size: 16px;" :icon="$route.meta.icon"/>
          <render-icon v-else style="font-size: 16px;" :svgIcon="$route.meta.icon" />
        </span>
        <span v-else :style="{ 'margin-right': record.ostypename ? '5px' : '0' }">
          <os-logo v-if="record.ostypename" :osName="record.ostypename" size="1x" />
        </span>

        <span v-if="record.hasannotations">
          <span v-if="record.id">
            <router-link :to="{ path: $route.path + '/' + record.id }">{{ text }}</router-link>
            <router-link :to="{ path: $route.path + '/' + record.id, query: { tab: 'comments' } }"><message-filled style="padding-left: 10px" size="small"/></router-link>
          </span>
          <router-link v-else :to="{ path: $route.path + '/' + record.name }" >{{ text }}</router-link>
        </span>
        <span v-else-if="$route.path.startsWith('/globalsetting')">{{ text }}</span>
        <span v-else-if="$route.path.startsWith('/alert')">
          <router-link :to="{ path: $route.path + '/' + record.id }" v-if="record.id">{{ $t(text.toLowerCase()) }}</router-link>
          <router-link :to="{ path: $route.path + '/' + record.name }" v-else>{{ $t(text.toLowerCase()) }}</router-link>
        </span>
        <span v-else>
          <router-link :to="{ path: $route.path + '/' + record.id }" v-if="record.id">{{ text }}</router-link>
          <router-link :to="{ path: $route.path + '/' + record.name }" v-else>{{ text }}</router-link>
        </span>
      </span>
    </template>
    <template #templatetype="{ text, record }">
      <router-link :to="{ path: $route.path + '/' + record.templatetype }">{{ text }}</router-link>
    </template>
    <template #type="{ text }">
      <span v-if="['USER.LOGIN', 'USER.LOGOUT', 'ROUTER.HEALTH.CHECKS', 'FIREWALL.CLOSE', 'ALERT.SERVICE.DOMAINROUTER'].includes(text)">{{ $t(text.toLowerCase()) }}</span>
      <span v-else>{{ text }}</span>
    </template>
    <template #displayname="{text, record}">
      <QuickView
        style="margin-left: 5px"
        :actions="actions"
        :resource="record"
        :enabled="quickViewEnabled() && actions.length > 0 && columns && columns[0].dataIndex === 'displayname' "
        @exec-action="$parent.execAction"/>
      <!-- WP Implementation ResellerAdmins need to pass additional account and daomain id parameter to path to be able to acess vmSnapshots of subaccounts-->
      <router-link v-if="$store.getters.userInfo.rolename === 'ResellerAdmin'" :to="{ path: $route.path + '/' + record.id + '/' + record.account + '/' + record.domainid}">{{ text }}</router-link>
      <router-link v-else :to="{ path: $route.path + '/' + record.id }">{{ text }}</router-link>
              <!-- WP Implementation -->
    </template>
    <template #username="{text, record}">
      <span v-if="$showIcon() && !['vm'].includes($route.path.split('/')[1])" style="margin-right: 5px">
        <resource-icon v-if="$showIcon() && record.icon && record.icon.base64image" :image="record.icon.base64image" size="1x"/>
        <user-outlined v-else style="font-size: 16px;" />
      </span>
      <router-link :to="{ path: $route.path + '/' + record.id }" v-if="['/accountuser', '/vpnuser'].includes($route.path)">{{ text }}</router-link>
      <router-link :to="{ path: '/accountuser', query: { username: record.username, domainid: record.domainid } }" v-else-if="$store.getters.userInfo.roletype !== 'User'">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
    </template>
    <template #entityid="{ record }" href="javascript:;">
      <router-link :to="{ path: generateCommentsPath(record), query: { tab: 'comments' } }">{{ record.entityname }}</router-link>
    </template>
    <template #entitytype="{ record }" href="javascript:;">
      {{ generateHumanReadableEntityType(record) }}
    </template>
    <template #adminsonly="{ record }" v-if="['Admin'].includes($store.getters.userInfo.roletype)" href="javascript:;">
      <a-checkbox :checked="record.adminsonly" :value="record.id" v-if="record.userid === $store.getters.userInfo.id" @change="e => updateAdminsOnly(e)" />
      <a-checkbox :checked="record.adminsonly" disabled v-else />
    </template>
    <template #ipaddress="{ text, record }" href="javascript:;">
      <router-link v-if="['/publicip', '/privategw'].includes($route.path)" :to="{ path: $route.path + '/' + record.id }">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
      <span v-if="record.issourcenat">
        &nbsp;
        <a-tag>source-nat</a-tag>
      </span>
      <span v-else-if="record.associatednetworkname">
        &nbsp;
        <a-tag>destination-nat</a-tag>
      </span>
    </template>
    <template #ip6address="{ text, record }" href="javascript:;">
      <span>{{ ipV6Address(text, record) }}</span>
    </template>
    <template #publicip="{ text, record }">
      <router-link :to="{ path: $route.path + '/' + record.id }">{{ text }}</router-link>
    </template>
    <template #traffictype="{ text }" href="javascript:;">
      {{ text }}
    </template>
    <template #vmname="{ text, record }">
      <router-link :to="{ path: '/vm/' + record.virtualmachineid }">{{ text }}</router-link>
    </template>
    <template #virtualmachinename="{ text, record }">
      <router-link :to="{ path: '/vm/' + record.virtualmachineid }">{{ text }}</router-link>
    </template>
    <template #hypervisor="{ text, record }">
      <span v-if="$route.name === 'hypervisorcapability'">
        <router-link :to="{ path: $route.path + '/' + record.id }">{{ text }}</router-link>
      </span>
      <span v-else>{{ text }}</span>
    </template>
    <template #state="{ text, record }">
      <status v-if="$route.path.startsWith('/host')" :text="getHostState(record)" displayText />
      <status v-else :text="text ? text : ''" displayText :styles="{ 'min-width': '80px' }" />
    </template>
    <template #allocationstate="{ text }">
      <status :text="text ? text : ''" displayText />
    </template>
    <template #resourcestate="{ text }">
      <status :text="text ? text : ''" displayText />
    </template>
    <template #powerstate="{ text }">
      <status :text="text ? text : ''" displayText />
    </template>
    <template #agentstate="{ text }">
      <status :text="text ? text : ''" displayText />
    </template>
    <template #vlan="{ text, record }">
      <a href="javascript:;">
        <router-link v-if="$route.path === '/guestvlans'" :to="{ path: '/guestvlans/' + record.id }">{{ text }}</router-link>
      </a>
    </template>
    <template #guestnetworkname="{ text, record }">
      <router-link :to="{ path: '/guestnetwork/' + record.guestnetworkid }">{{ text }}</router-link>
    </template>
    <template #associatednetworkname="{ text, record }">
      <router-link :to="{ path: '/guestnetwork/' + record.associatednetworkid }">{{ text }}</router-link>
    </template>
    <template #vpcname="{ text, record }">
      <router-link :to="{ path: '/vpc/' + record.vpcid }">{{ text }}</router-link>
    </template>
    <template #hostname="{ text, record }">
      <router-link v-if="record.hostid" :to="{ path: '/host/' + record.hostid }">{{ text }}</router-link>
      <router-link v-else-if="record.hostname" :to="{ path: $route.path + '/' + record.id }">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
    </template>
    <template #storage="{ text, record }">
      <router-link v-if="record.storageid" :to="{ path: '/storagepool/' + record.storageid }">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
    </template>

    <template
      v-for="(value, name) in thresholdMapping"
      :key="name"
      #[name]="{ text, record }"
      href="javascript:;">
      <span>
        <span v-if="record[value.disable]" class="alert-disable-threshold">
          {{ text }}
        </span>
        <span v-else-if="record[value.notification]" class="alert-notification-threshold">
          {{ text }}
        </span>
        <span style="padding: 10%;" v-else>
          {{ text }}
        </span>
      </span>
    </template>

    <template #level="{ text, record }">
      <router-link :to="{ path: '/event/' + record.id }">{{ text }}</router-link>
    </template>

    <template #clustername="{ text, record }">
      <router-link :to="{ path: '/cluster/' + record.clusterid }">{{ text }}</router-link>
    </template>
    <template #podname="{ text, record }">
      <router-link :to="{ path: '/pod/' + record.podid }">{{ text }}</router-link>
    </template>
    <template #account="{ text, record }">
      <template v-if="record.owner">
        <template v-for="(item, idx) in record.owner" :key="idx">
          <span style="margin-right:5px">
            <span v-if="$store.getters.userInfo.roletype !== 'User'">
              <router-link v-if="'user' in item" :to="{ path: '/accountuser', query: { username: item.user, domainid: record.domainid }}">{{ item.account + '(' + item.user + ')' }}</router-link>
              <router-link v-else :to="{ path: '/account', query: { name: item.account, domainid: record.domainid, dataView: true } }">{{ item.account }}</router-link>
            </span>
            <span v-else>{{ item.user ? item.account + '(' + item.user + ')' : item.account }}</span>
          </span>
        </template>
      </template>
      <template v-if="text && !text.startsWith('PrjAcct-')">
        <router-link
          v-if="'quota' in record && $router.resolve(`${$route.path}/${record.account}`).matched[0].redirect !== '/exception/404'"
          :to="{ path: `${$route.path}/${record.account}`, query: { account: record.account, domainid: record.domainid, quota: true } }">{{ text }}</router-link>
        <router-link :to="{ path: '/account/' + record.accountid }" v-else-if="record.accountid">{{ text }}</router-link>
        <router-link :to="{ path: '/account', query: { name: record.account, domainid: record.domainid, dataView: true } }" v-else-if="$store.getters.userInfo.roletype !== 'User'">{{ text }}</router-link>
        <span v-else>{{ text }}</span>
      </template>
    </template>
    <template #resource="{ record }">
      <resource-label :resourceType="record.resourcetype" :resourceId="record.resourceid" :resourceName="record.resourcename" />
    </template>
    <template #domain="{ text, record }">
      <router-link v-if="record.domainid && !record.domainid.toString().includes(',') && $store.getters.userInfo.roletype !== 'User'" :to="{ path: '/domain/' + record.domainid, query: { tab: 'details' } }">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
    </template>
    <template #domainpath="{ text, record }">
      <router-link v-if="record.domainid && !record.domainid.includes(',') && $router.resolve('/domain/' + record.domainid).matched[0].redirect !== '/exception/404'" :to="{ path: '/domain/' + record.domainid, query: { tab: 'details' } }">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
    </template>
    <template #zone="{ text, record }">
      <router-link v-if="record.zoneid && !record.zoneid.includes(',') && $router.resolve('/zone/' + record.zoneid).matched[0].redirect !== '/exception/404'" :to="{ path: '/zone/' + record.zoneid }">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
    </template>
    <template #zonename="{ text, record }">
      <router-link v-if="$router.resolve('/zone/' + record.zoneid).matched[0].redirect !== '/exception/404'" :to="{ path: '/zone/' + record.zoneid }">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
    </template>
    <!-- WP Implementation -->
    <template #wpIpManager="{ record }">
        <div class="actions">
          <tooltip-button
            tooltipPlacement="bottom"
            :tooltip="$t('label.change.account')"
            icon="user-add-outlined"
            type="default"
            @click="handleOpenAddAccountModal(record)"
            :disabled="!('dedicatePublicIpRange' in $store.getters.apis) || record.state !== 'Free'  || subAccounts.length === 0"/>
          <tooltip-button
            tooltipPlacement="bottom"
            :tooltip="$t('label.release.account')"
            icon="user-delete-outlined"
            type="primary"
            danger
            @click="handleRemoveAccount(record)"
            :disabled="!('releasePublicIpRange' in $store.getters.apis) || record.state !== 'Free' || (record.state === 'Free' && accountName === record.accountName)" />
          <!-- <tooltip-button
            tooltipPlacement="bottom"
            :tooltip="$t('label.update.ip.range')"
            icon="edit"
            type="danger"
            @click="() => handleUpdateIpRangeModal(record)"
            :disabled="!('updateVlanIpRange' in $store.getters.apis)" />
          <tooltip-button
            tooltipPlacement="bottom"
            :tooltip="$t('label.remove.ip.range')"
            icon="delete"
            type="danger"
            @click="handleDeleteIpRange(record.id)"
            :disabled="!('deleteVlanIpRange' in $store.getters.apis)" /> -->
        </div>
      </template>
      <!-- /WP Implementation -->
    <template #rolename="{ text, record }">
      <router-link v-if="record.roleid && $router.resolve('/role/' + record.roleid).matched[0].redirect !== '/exception/404'" :to="{ path: '/role/' + record.roleid }">{{ text }}</router-link>
      <span v-else>{{ text }}</span>
    </template>
    <template #templateversion="{ record }">
      <span>  {{ record.version }} </span>
    </template>
    <template #softwareversion="{ record }">
      <span>  {{ record.softwareversion ? record.softwareversion : 'N/A' }} </span>
    </template>
    <template #access="{ record }">
      <status :text="record.readonly ? 'ReadOnly' : 'ReadWrite'" displayText />
    </template>
    <template #requiresupgrade="{ record }">
      <status :text="record.requiresupgrade ? 'warning' : ''" />
      {{ record.requiresupgrade ? 'Yes' : 'No' }}
    </template>
    <template #loadbalancerrule="{ record }">
      <span>  {{ record.loadbalancerrule }} </span>
    </template>
    <template #autoscalingenabled="{ record }">
      <status :text="record.autoscalingenabled ? 'Enabled' : 'Disabled'" />
      {{ record.autoscalingenabled ? 'Enabled' : 'Disabled' }}
    </template>
    <template #current="{record}">
      <status :text="record.current ? record.current.toString() : 'false'" />
    </template>
    <template #created="{ text }">
      {{ $toLocaleDate(text) }}
    </template>
    <template #sent="{ text }">
      {{ $toLocaleDate(text) }}
    </template>
    <template #order="{ text, record }">
      <div class="shift-btns">
        <a-tooltip :name="text" placement="top">
          <template #title>{{ $t('label.move.to.top') }}</template>
          <a-button
            shape="round"
            type="default"
            @click="moveItemTop(record)"
            class="shift-btn">
            <DoubleLeftOutlined class="shift-btn shift-btn--rotated" />
          </a-button>
        </a-tooltip>
        <a-tooltip placement="top">
          <template #title>{{ $t('label.move.to.bottom') }}</template>
          <a-button
            shape="round"
            type="default"
            @click="moveItemBottom(record)"
            class="shift-btn">
            <DoubleRightOutlined class="shift-btn shift-btn--rotated" />
          </a-button>
        </a-tooltip>
        <a-tooltip placement="top">
          <template #title>{{ $t('label.move.up.row') }}</template>
          <a-button type="default" shape="round" @click="moveItemUp(record)" class="shift-btn">
            <CaretUpOutlined class="shift-btn" />
          </a-button>
        </a-tooltip>
        <a-tooltip placement="top">
          <template #title>{{ $t('label.move.down.row') }}</template>
          <a-button type="default" shape="round" @click="moveItemDown(record)" class="shift-btn">
            <CaretDownOutlined class="shift-btn" />
          </a-button>
        </a-tooltip>
      </div>
    </template>

    <template #value="{ text, record }">
      <a-input
        v-if="editableValueKey === record.key"
        v-focus="true"
        :defaultValue="record.value"
        :disabled="!('updateConfiguration' in $store.getters.apis)"
        v-model:value="editableValue"
        @keydown.esc="editableValueKey = null"
        @pressEnter="saveValue(record)">
      </a-input>
      <div v-else style="width: 200px; word-break: break-all">
        {{ text }}
      </div>
    </template>
    <template #actions="{ record }">
      <tooltip-button
        :tooltip="$t('label.edit')"
        :disabled="!('updateConfiguration' in $store.getters.apis)"
        v-if="editableValueKey !== record.key"
        icon="edit-outlined"
        type="default"
        @onClick="editValue(record)" />
      <tooltip-button
        :tooltip="$t('label.cancel')"
        @onClick="editableValueKey = null"
        v-if="editableValueKey === record.key"
        iconType="CloseCircleTwoTone"
        type="default"
        iconTwoToneColor="#f5222d" />
      <tooltip-button
        :tooltip="$t('label.ok')"
        :disabled="!('updateConfiguration' in $store.getters.apis)"
        @onClick="saveValue(record)"
        v-if="editableValueKey === record.key"
        iconType="CheckCircleTwoTone"
        type="default"
        iconTwoToneColor="#52c41a" />
      <tooltip-button
        :tooltip="$t('label.reset.config.value')"
        @onClick="resetConfig(record)"
        v-if="editableValueKey !== record.key"
        icon="reload-outlined"
        type="default"
        :disabled="!('updateConfiguration' in $store.getters.apis)" />
    </template>
    <template #tariffActions="{ record }">
      <tooltip-button
        :tooltip="$t('label.edit')"
        type="default"
        v-if="editableValueKey !== record.key"
        :disabled="!('quotaTariffUpdate' in $store.getters.apis)"
        icon="edit-outlined"
        @onClick="editTariffValue(record)" />
      <slot></slot>
    </template>
     <!-- WP Implementation -->
      <!-- Column definitions for cyber cloud endpoint Table -->
      <template #status="{ text }" v-if="$route.path.startsWith('/cyberCloudEndpoint')">
        <status :text="text" displayText />
      </template>
      <template #deleted_at="{ record }" v-if="$route.path.startsWith('/cyberCloudEndpoint')">
        <tooltip-button
          v-if="record.resourceType === 'endpoint'"
          tooltipPlacement="top"
          :tooltip="$t('label.delete')"
          danger
          type="primary"
          shape="circle"
          icon="delete-outlined"
          @onClick="deleteAcronisEndpoint(record.uuid)"/>
          <span style="margin-left: 5px;"></span>
          <tooltip-button
          v-if="record.resourceType === 'endpoint'"
          tooltipPlacement="top"
          :tooltip="record.maintenance === true ? (record.status === 'installed' ? $t('label.deactivate.css.maintanence') : $t('label.maintenance.deactivated')) : (record.status === 'installed' ? $t('label.activate.css.maintanence') : $t('label.maintenance.deactivated'))"
          :type="record.maintenance === true ? 'primary' : 'default'"
          shape="circle"
          :disabled="record.status === 'installed' ? false : true"
          :danger="record.maintenance === true ? true : false"
          icon="tool-outlined"
          @onClick="setAcronisMaintanence(record.uuid, record.maintenance === true ? false : true)"/>
      </template>
      <!-- /WP Implementation -->
    </a-table>
    <!-- Modals for IP Management -->
    <a-modal
      v-model:visible="addAccountModal"
      :title="$t('label.add.account')"
      :maskClosable="false"
      destroyOnClose
      :loading="domainsLoading"
      v-ctrl-enter="handleAddAccount">
      <template #footer>
        <a-button key="back" @click="addAccountModal = false">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="domainsLoading" @click="handleAddAccount">OK</a-button>
      </template>
      <a-form
        v-ctrl-enter="handleAddAccount"
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="handleAddAccount"
        layout="vertical"
      >
        <div>
          <a-form-item ref="selectedaccount" name="selectedaccount" :label="$t('label.select.account')">
            <a-select
              v-model:value="form.selectedaccount"
              @change="changeAccounts"
              :placeholder="$t('label.select.account')">
              <a-select-option :value="accountName">
                <span>
                  {{ accountName }}
                </span>
              </a-select-option>
              <a-select-option v-for="account in subAccounts" :key="account.accountName">
                <span>
                  {{ account.accountName }}
                </span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
      </a-form>
    </a-modal>

    <a-modal
      v-model:visible="addIpRangeModal"
      :title="$t('label.add.ip.range')"
      :maskClosable="false"
      :footer="null"
      @cancel="addIpRangeModal = false"
      v-ctrl-enter="handleAddIpRange"
      :loading="domainsLoading">
      <a-form
        v-ctrl-enter="handleAddIpRange"
        :ref="formRef2"
        :model="form2"
        :rules="rules2"
        @finish="handleAddIpRange"
        layout="vertical"
      >
        <a-form-item ref="podid" name="podid" :label="$t('label.podid')" class="form__item" v-if="basicGuestNetwork">
          <a-select
            autoFocus
            v-model:value="form2.podid"
            showSearch
            optionFilterProp="children"
            :filterOption="(input, option) => {
              return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }" >
            <a-select-option v-for="pod in pods" :key="pod.id" :value="pod.id">{{ pod.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="gateway" name="gateway" :label="$t('label.gateway')" class="form__item">
          <a-input
          v-model:value="form2.gateway"
            autoFocus>
          </a-input>
        </a-form-item>
        <a-form-item ref="netmask" name="netmask" :label="$t('label.netmask')" class="form__item">
          <a-input v-model:value="form2.netmask">
          </a-input>
        </a-form-item>
        <a-form-item ref="vlan" name="vlan" :label="$t('label.vlan')" class="form__item" v-if="!basicGuestNetwork">
          <a-input
          v-model:value="form2.vlan">
          </a-input>
        </a-form-item>
        <a-form-item ref="startip" name="startip" :label="$t('label.startip')" class="form__item">
          <a-input
          v-model:value="form2.startip">
          </a-input>
        </a-form-item>
        <a-form-item ref="endip" name="endip" :label="$t('label.endip')" class="form__item">
          <a-input
          v-model:value="form2.endip">
          </a-input>
        </a-form-item>
        <div class="form__item" v-if="!basicGuestNetwork">
          <div style="color: black;">{{ $t('label.set.reservation') }}</div>
          <a-switch @change="handleShowAccountFields"></a-switch>
        </div>
        <div v-if="showAccountFields && !basicGuestNetwork" style="margin-top: 20px;">
          <div v-html="$t('label.set.reservation.desc')"></div>
          <a-form-item ref="forsystemvms" name="forsystemvms" :label="$t('label.system.vms')" class="form__item">
            <a-switch v-model:value="form2.forsystemvms"></a-switch>
          </a-form-item>
          <a-spin :spinning="domainsLoading">
            <a-form-item ref="account" name="account" :label="$t('label.account')" class="form__item">
              <a-input v-model:value="form2.account"></a-input>
            </a-form-item>
            <a-form-item ref="domain" name="domain" :label="$t('label.domain')" class="form__item">
              <a-select
                v-model:value="form2.domain"
                showSearch
                optionFilterProp="children"
                :filterOption="(input, option) => {
                  return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }" >
                <a-select-option
                  v-for="domain in domains"
                  :key="domain.id"
                  :value="domain.id">{{ domain.path || domain.name || domain.description }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-spin>
        </div>

        <div :span="24" class="action-button">
          <a-button @click="addIpRangeModal = false">{{ $t('label.cancel') }}</a-button>
          <a-button type="primary" ref="submit" @click="handleAddIpRange">{{ $t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-modal>
    <a-modal
      v-model:visible="updateIpRangeModal"
      :title="$t('label.update.ip.range')"
      v-if="selectedIp"
      :maskClosable="false"
      :footer="null"
      v-ctrl-enter="handleUpdateIpRange"
      :loading="domainsLoading"
      @cancel="updateIpRangeModal = false">
      <a-form
        v-ctrl-enter="handleUpdateIpRange"
        :ref="formRef3"
        :model="form3"
        :rules="rules3"
        @finish="handleUpdateIpRange"
        layout="vertical"
      >
        <a-form-item ref="startip" name="startip" :label="$t('label.startip')" class="form__item">
          <a-input
            autoFocus
            v-model:value="form3.startip">
          </a-input>
        </a-form-item>
        <a-form-item ref="endip" name="endip" :label="$t('label.endip')" class="form__item">
          <a-input
          v-model:value="form3.endip">
          </a-input>
        </a-form-item>
        <a-form-item ref="gateway" name="gateway" :label="$t('label.gateway')" class="form__item">
          <a-input
          v-model:value="form3.gateway">
          </a-input>
        </a-form-item>
        <a-form-item ref="netmask" name="netmask" :label="$t('label.netmask')" class="form__item">
          <a-input
          v-model:value="form3.netmask">
          </a-input>
        </a-form-item>
        <a-form-item ref="forsystemvms" name="forsystemvms" :label="$t('label.system.vms')" class="form__item">
          <a-switch v-model:value="form3.forsystemvms"></a-switch>
        </a-form-item>

        <div :span="24" class="action-button">
          <a-button @click="updateIpRangeModal = false">{{ $t('label.cancel') }}</a-button>
          <a-button type="primary" ref="submit" @click="handleUpdateIpRange">{{ $t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-modal>
    <!-- Success Modal -->
    <a-modal
      :title="$t('label.delete.cybercloud.endpoint.success')"
      v-model:visible="showDeleteEndpointModal"
      :closable="false"
      centered>
      <p>{{ $t('label.delete.cybercloud.endpoint.success') }}</p>
      <template #footer>
        <a-button
          type="primary"
          @click="closeSuccessModals()">
          {{ 'Ok' }}
        </a-button>
      </template>
    </a-modal>
    <a-modal
      :title="$t('label.activate.css.maintanence.success')"
      v-model:visible="showActivateMaintenanceModal"
      :closable="false"
      centered>
      <p>{{ $t('label.activate.css.maintanence.success') }}</p>
      <template #footer>
        <a-button
          type="primary"
          @click="closeSuccessModals()">
          {{ 'Ok' }}
        </a-button>
      </template>
    </a-modal>
    <a-modal
      :title="$t('label.deactivate.css.maintanence.success')"
      v-model:visible="showDeactivateMaintenanceModal"
      :closable="false"
      centered>
      <p>{{ $t('label.deactivate.css.maintanence.success') }}</p>
      <template #footer>
        <a-button
          type="primary"
          @click="closeSuccessModals()">
          {{ 'Ok' }}
        </a-button>
      </template>
    </a-modal>
  </a-spin>
</template>

<script>
import { api } from '@/api'
// WP Implementation
import api2 from '@/wpApi/api2'
// /WP Implementation
import Console from '@/components/widgets/Console'
import OsLogo from '@/components/widgets/OsLogo'
import Status from '@/components/widgets/Status'
import InfoCard from '@/components/view/InfoCard'
import QuickView from '@/components/view/QuickView'
import TooltipButton from '@/components/widgets/TooltipButton'
import ResourceIcon from '@/components/view/ResourceIcon'
import ResourceLabel from '@/components/widgets/ResourceLabel'
import { reactive, ref, toRaw } from 'vue'

export default {
  name: 'ListView',
  components: {
    Console,
    OsLogo,
    Status,
    InfoCard,
    QuickView,
    TooltipButton,
    ResourceIcon,
    ResourceLabel
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    columnKeys: {
      type: Array,
      default: () => []
    },
    selectedColumns: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  inject: ['parentFetchData', 'parentToggleLoading'],
  data () {
    return {
      // WP Implementation
      addAccountModal: false,
      addIpRangeModal: false,
      updateIpRangeModal: false,
      domainsLoading: false,
      subAccounts: [],
      showDeleteEndpointModal: false,
      showActivateMaintenanceModal: false,
      showDeactivateMaintenanceModal: false,
      accountName: this.$store.getters.userInfo.account,
      domainUuid: this.$store.getters.userInfo.domainid,
      basicGuestNetwork: false,
      showAccountFields: false,
      selectedIp: [],
      selectedAcc: null,
      searchText: '',
      searchedColumn: '',
      searchInput: '',
      componentLoading: false,
      // /WP Implementation
      selectedRowKeys: [],
      editableValueKey: null,
      editableValue: '',
      resourceIcon: '',
      thresholdMapping: {
        cpuused: {
          notification: 'cputhreshold',
          disable: 'cpudisablethreshold'
        },
        cpuallocated: {
          notification: 'cpuallocatedthreshold',
          disable: 'cpuallocateddisablethreshold'
        },
        memoryused: {
          notification: 'memorythreshold',
          disable: 'memorydisablethreshold'
        },
        memoryallocated: {
          notification: 'memoryallocatedthreshold',
          disable: 'memoryallocateddisablethreshold'
        },
        cpuusedghz: {
          notification: 'cputhreshold',
          disable: 'cpudisablethreshold'
        },
        cpuallocatedghz: {
          notification: 'cpuallocatedthreshold',
          disable: 'cpuallocateddisablethreshold'
        },
        memoryusedgb: {
          notification: 'memorythreshold',
          disable: 'memorydisablethreshold'
        },
        memoryallocatedgb: {
          notification: 'memoryallocatedthreshold',
          disable: 'memoryallocateddisablethreshold'
        },
        disksizeusedgb: {
          notification: 'storageusagethreshold',
          disable: 'storageusagedisablethreshold'
        },
        disksizeallocatedgb: {
          notification: 'storageallocatedthreshold',
          disable: 'storageallocateddisablethreshold'
        }
      }
    }
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    if (this.$route.path.startsWith('/publicip') && (this.$store.getters.userInfo.rolename === 'ResellerAdmin' || this.$store.getters.userInfo.roletype === 'Admin')) {
      this.fetchResellerCustomerAccounts()
    }
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.formRef2 = ref()
      this.formRef3 = ref()
      this.form = reactive({
        selectedaccount: this.accountName
      })
      this.rules = reactive({
        selectedaccount: [{ required: true, message: this.$t('label.required') }]
      })
      this.form2 = reactive({
        podid: undefined,
        gateway: undefined,
        netmask: undefined,
        vlan: undefined,
        startip: undefined,
        endip: undefined,
        forsystemvms: false,
        account: undefined,
        domain: undefined
      })
      this.rules2 = reactive({
        podid: [{ required: true, message: this.$t('label.required') }],
        gateway: [{ required: true, message: this.$t('label.required') }],
        netmask: [{ required: true, message: this.$t('label.required') }],
        startip: [{ required: true, message: this.$t('label.required') }],
        endip: [{ required: true, message: this.$t('label.required') }]
      })
      this.form3 = reactive({
        startip: this.selectedIp.startip || undefined,
        endip: this.selectedIp.endip || undefined,
        gateway: this.selectedIp.gateway || undefined,
        netmask: this.selectedIp.netmask || undefined,
        forsystemvms: this.selectedIp.forsystemvms || undefined

      })
      this.rules3 = reactive({
        startip: [{ required: true, message: this.$t('label.required') }],
        endip: [{ required: true, message: this.$t('label.required') }],
        gateway: [{ required: true, message: this.$t('label.required') }],
        netmask: [{ required: true, message: this.$t('label.required') }]
      })
    },
    quickViewEnabled () {
      return new RegExp(['/vm', '/kubernetes', '/ssh', '/vmgroup', '/affinitygroup',
        '/volume', '/snapshot', '/vmsnapshot', '/backup',
        '/guestnetwork', '/vpc', '/vpncustomergateway',
        '/template', '/iso',
        '/project', '/account',
        '/zone', '/pod', '/cluster', '/host', '/storagepool', '/imagestore', '/systemvm', '/router', '/ilbvm', '/annotation',
        '/computeoffering', '/systemoffering', '/diskoffering', '/backupoffering', '/networkoffering', '/vpcoffering'].join('|'))
        .test(this.$route.path)
    },
    enableGroupAction () {
      return ['vm', 'alert', 'vmgroup', 'ssh', 'affinitygroup', 'volume', 'snapshot',
        'vmsnapshot', 'guestnetwork', 'vpc', 'publicip', 'vpnuser', 'vpncustomergateway',
        'project', 'account', 'systemvm', 'router', 'computeoffering', 'systemoffering',
        'diskoffering', 'backupoffering', 'networkoffering', 'vpcoffering', 'ilbvm', 'kubernetes', 'comment'
      ].includes(this.$route.name)
    },
    fetchColumns () {
      if (this.isOrderUpdatable()) {
        return this.columns
      }
      return this.columns.filter(x => x.dataIndex !== 'order')
    },
    getRowClassName (record, index) {
      if (index % 2 === 0) {
        return 'light-row'
      }
      return 'dark-row'
    },
    setSelection (selection) {
      this.selectedRowKeys = selection
      this.$emit('selection-change', this.selectedRowKeys)
    },
    resetSelection () {
      this.setSelection([])
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.setSelection(selectedRowKeys)
    },
    changeProject (project) {
      this.$store.dispatch('SetProject', project)
      this.$store.dispatch('ToggleTheme', project.id === undefined ? 'light' : 'dark')
      this.$message.success(this.$t('message.switch.to') + ' ' + project.name)
      this.$router.push({ name: 'dashboard' })
    },
    saveValue (record) {
      api('updateConfiguration', {
        name: record.name,
        value: this.editableValue
      }).then(json => {
        this.editableValueKey = null
        this.$store.dispatch('RefreshFeatures')
        this.$message.success(`${this.$t('message.setting.updated')} ${record.name}`)
        if (json.updateconfigurationresponse &&
          json.updateconfigurationresponse.configuration &&
          !json.updateconfigurationresponse.configuration.isdynamic &&
          ['Admin'].includes(this.$store.getters.userInfo.roletype)) {
          this.$notification.warning({
            message: this.$t('label.status'),
            description: this.$t('message.restart.mgmt.server')
          })
        }
      }).catch(error => {
        console.error(error)
        this.$message.error(this.$t('message.error.save.setting'))
      }).finally(() => {
        this.$emit('refresh')
      })
    },
    resetConfig (item) {
      api('resetConfiguration', {
        name: item.name
      }).then(() => {
        const message = `${this.$t('label.setting')} ${item.name} ${this.$t('label.reset.config.value')}`
        this.$message.success(message)
      }).catch(error => {
        console.error(error)
        this.$message.error(this.$t('message.error.reset.config'))
        this.$notification.error({
          message: this.$t('label.error'),
          description: this.$t('message.error.reset.config')
        })
      }).finally(() => {
        this.$emit('refresh')
      })
    },
    editValue (record) {
      this.editableValueKey = record.key
      this.editableValue = record.value
    },
    getUpdateApi () {
      let apiString = ''
      switch (this.$route.name) {
        case 'template':
          apiString = 'updateTemplate'
          break
        case 'iso':
          apiString = 'updateIso'
          break
        case 'zone':
          apiString = 'updateZone'
          break
        case 'computeoffering':
        case 'systemoffering':
          apiString = 'updateServiceOffering'
          break
        case 'diskoffering':
          apiString = 'updateDiskOffering'
          break
        case 'networkoffering':
          apiString = 'updateNetworkOffering'
          break
        case 'vpcoffering':
          apiString = 'updateVPCOffering'
          break
        default:
          apiString = 'updateTemplate'
      }
      return apiString
    },
    isOrderUpdatable () {
      return this.getUpdateApi() in this.$store.getters.apis
    },
    handleUpdateOrder (id, index) {
      this.parentToggleLoading()
      const apiString = this.getUpdateApi()

      return new Promise((resolve, reject) => {
        api(apiString, {
          id,
          sortKey: index
        }).then((response) => {
          resolve(response)
        }).catch((reason) => {
          reject(reason)
        })
      })
    },
    updateOrder (data) {
      const promises = []
      data.forEach((item, index) => {
        promises.push(this.handleUpdateOrder(item.id, index + 1))
      })
      Promise.all(promises).catch((reason) => {
        console.log(reason)
      }).finally(() => {
        this.parentToggleLoading()
        this.parentFetchData()
      })
    },
    moveItemUp (record) {
      const data = this.items
      const index = data.findIndex(item => item.id === record.id)
      if (index === 0) return
      data.splice(index - 1, 0, data.splice(index, 1)[0])
      this.updateOrder(data)
    },
    moveItemDown (record) {
      const data = this.items
      const index = data.findIndex(item => item.id === record.id)
      if (index === data.length - 1) return
      data.splice(index + 1, 0, data.splice(index, 1)[0])
      this.updateOrder(data)
    },
    moveItemTop (record) {
      const data = this.items
      const index = data.findIndex(item => item.id === record.id)
      if (index === 0) return
      data.unshift(data.splice(index, 1)[0])
      this.updateOrder(data)
    },
    moveItemBottom (record) {
      const data = this.items
      const index = data.findIndex(item => item.id === record.id)
      if (index === data.length - 1) return
      data.push(data.splice(index, 1)[0])
      this.updateOrder(data)
    },
    editTariffValue (record) {
      this.$emit('edit-tariff-action', true, record)
    },
    ipV6Address (text, record) {
      if (!record || !record.nic || record.nic.length === 0) {
        return ''
      }

      return record.nic.filter(e => { return e.ip6address }).map(e => { return e.ip6address }).join(', ') || text
    },
    generateCommentsPath (record) {
      if (this.entityTypeToPath(record.entitytype) === 'ssh') {
        return '/' + this.entityTypeToPath(record.entitytype) + '/' + record.entityname
      }
      return '/' + this.entityTypeToPath(record.entitytype) + '/' + record.entityid
    },
    generateHumanReadableEntityType (record) {
      switch (record.entitytype) {
        case 'VM' : return 'Virtual Machine'
        case 'HOST' : return 'Host'
        case 'VOLUME' : return 'Volume'
        case 'SNAPSHOT' : return 'Snapshot'
        case 'VM_SNAPSHOT' : return 'VM Snapshot'
        case 'INSTANCE_GROUP' : return 'Instance Group'
        case 'NETWORK' : return 'Network'
        case 'VPC' : return 'VPC'
        case 'PUBLIC_IP_ADDRESS' : return 'Public IP Address'
        case 'VPN_CUSTOMER_GATEWAY' : return 'VPC Customer Gateway'
        case 'TEMPLATE' : return 'Template'
        case 'ISO' : return 'ISO'
        case 'SSH_KEYPAIR' : return 'SSH Key Pair'
        case 'DOMAIN' : return 'Domain'
        case 'SERVICE_OFFERING' : return 'Service Offfering'
        case 'DISK_OFFERING' : return 'Disk Offering'
        case 'NETWORK_OFFERING' : return 'Network Offering'
        case 'POD' : return 'Pod'
        case 'ZONE' : return 'Zone'
        case 'CLUSTER' : return 'Cluster'
        case 'PRIMARY_STORAGE' : return 'Primary Storage'
        case 'SECONDARY_STORAGE' : return 'Secondary Storage'
        case 'VR' : return 'Virtual Router'
        case 'SYSTEM_VM' : return 'System VM'
        case 'KUBERNETES_CLUSTER': return 'Kubernetes Cluster'
        default: return record.entitytype.toLowerCase().replace('_', '')
      }
    },
    entityTypeToPath (entitytype) {
      switch (entitytype) {
        case 'VM' : return 'vm'
        case 'HOST' : return 'host'
        case 'VOLUME' : return 'volume'
        case 'SNAPSHOT' : return 'snapshot'
        case 'VM_SNAPSHOT' : return 'vmsnapshot'
        case 'INSTANCE_GROUP' : return 'vmgroup'
        case 'NETWORK' : return 'guestnetwork'
        case 'VPC' : return 'vpc'
        case 'PUBLIC_IP_ADDRESS' : return 'publicip'
        case 'VPN_CUSTOMER_GATEWAY' : return 'vpncustomergateway'
        case 'TEMPLATE' : return 'template'
        case 'ISO' : return 'iso'
        case 'SSH_KEYPAIR' : return 'ssh'
        case 'DOMAIN' : return 'domain'
        case 'SERVICE_OFFERING' : return 'computeoffering'
        case 'DISK_OFFERING' : return 'diskoffering'
        case 'NETWORK_OFFERING' : return 'networkoffering'
        case 'POD' : return 'pod'
        case 'ZONE' : return 'zone'
        case 'CLUSTER' : return 'cluster'
        case 'PRIMARY_STORAGE' : return 'storagepool'
        case 'SECONDARY_STORAGE' : return 'imagestore'
        case 'VR' : return 'router'
        case 'SYSTEM_VM' : return 'systemvm'
        case 'KUBERNETES_CLUSTER': return 'kubernetes'
        default: return entitytype.toLowerCase().replace('_', '')
      }
    },
    updateAdminsOnly (e) {
      api('updateAnnotationVisibility', {
        id: e.target.value,
        adminsonly: e.target.checked
      }).finally(() => {
        const data = this.items
        const index = data.findIndex(item => item.id === e.target.value)
        const elem = data[index]
        elem.adminsonly = e.target.checked
      })
    },
    getHostState (host) {
      if (host && host.hypervisor === 'KVM' && host.state === 'Up' && host.details && host.details.secured !== 'true') {
        return 'Unsecure'
      }
      return host.state
    },
    getColumnKey (name) {
      if (typeof name === 'object') {
        name = Object.keys(name).includes('customTitle') ? name.customTitle : name.field
      }
      return name
    },
    async setAcronisMaintanence (endpointUuid, enableDisable) {
      var maintanenceParams = new FormData()
      maintanenceParams.append('cloud_account_id', this.$store.getters.userInfo.accountid)
      maintanenceParams.append('uuid', endpointUuid)
      maintanenceParams.append('maintenance', enableDisable)
      await api2.setCssMaintenance(maintanenceParams).then(response => {
        if (response.data === 'OK') {
          console.log('Maintenance successfully' + (enableDisable === true ? ' activated' : ' deactivated'))
          if (enableDisable === true) {
            this.showActivateMaintenanceModal = true
          } else {
            this.showDeactivateMaintenanceModal = true
          }
        }
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async deleteAcronisEndpoint (endpointUuid) {
      var deleteEndpointParams = new FormData()
      deleteEndpointParams.append('cloud_account_id', this.$store.getters.userInfo.accountid)
      deleteEndpointParams.append('uuid', endpointUuid)
      await api2.deleteAcronisEndpoint(deleteEndpointParams).then(response => {
        if (response.data === 'OK') {
          console.log('Endpoint Successfully deleted')
          this.showDeleteEndpointModal = true
        }
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    closeSuccessModals () {
      this.showDeleteEndpointModal = false
      this.showActivateMaintenanceModal = false
      this.showDeactivateMaintenanceModal = false
      this.$emit('refresh')
    },
    // Function handling IP management in the IP Adress listView
    fetchData () {
      this.$emit('refresh')
    },
    async fetchResellerCustomerAccounts () {
      this.domainsLoading = true
      await api2.listAllSubaccounts(this.$store.getters.userInfo.accountid).then(response => {
        this.subAccounts = response.data
        if (response.data === undefined) {
          this.subAccounts = []
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.domainsLoading = false
      })
    },
    async handleAddAccount (e) {
      if (this.domainsLoading) return
      this.domainsLoading = true

      if (this.addIpRangeModal === true) {
        this.addAccountModal = false
        return
      }
      await this.releaseAndDedicateIpRange(this.selectedAcc, this.selectedIp)
    },
    async handleRemoveAccount (ip) {
      this.componentLoading = true
      await this.fetchResellerCustomerAccounts()
      if (this.subAccounts.length !== 0 && this.accountName !== ip.accountName) {
        await this.releaseAndDedicateIpRange(this.subAccounts.filter(x => x.accountName === ip.accountName)[0], ip, true)
      } else {
        api('releasePublicIpRange', { id: ip.vlaniprangeuuid }).catch(error => {
          this.$notifyError(error)
        }).finally(() => {
          this.fetchData()
          this.componentLoading = false
        })
      }
    },
    async handleOpenAddAccountModal (ip) {
      this.domainsLoading = true
      this.fetchResellerCustomerAccounts()
      if (!this.addIpRangeModal) {
        this.selectedIp = ip
      }
      this.selectedAcc = await this.getSubAccountByAccountName(this.subAccounts, this.form.selectedaccount)
      this.domainsLoading = false
      this.addAccountModal = true
    },
    handleShowAccountFields () {
      if (this.showAccountFields === false) {
        this.showAccountFields = true
        return
      }
      this.showAccountFields = false
    },
    handleOpenAddIpRangeModal () {
      this.addIpRangeModal = true
    },
    handleUpdateIpRangeModal (item) {
      this.selectedIp = item
      this.updateIpRangeModal = true
    },
    handleDeleteIpRange (id) {
      this.componentLoading = true
      api('deleteVlanIpRange', { id }).then(() => {
        this.$notification.success({
          message: 'Removed IP Range'
        })
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.componentLoading = false
        this.fetchData()
      })
    },
    handleAddIpRange (e) {
      if (this.componentLoading) return
      this.formRef2.value.validate().then(async () => {
        const values = toRaw(this.form2)

        this.componentLoading = true
        this.addIpRangeModal = false
        var params = {
          gateway: values.gateway,
          netmask: values.netmask,
          startip: values.startip,
          endip: values.endip
        }
        if (!this.basicGuestNetwork) {
          params.zoneId = this.resource.zoneid
          params.vlan = values.vlan
          params.forsystemvms = values.forsystemvms
          params.account = values.forsystemvms ? null : values.account
          params.domainid = values.forsystemvms ? null : values.domain
          params.forvirtualnetwork = true
        } else {
          params.forvirtualnetwork = false
          params.podid = values.podid
          params.networkid = this.network.id
        }
        api('createVlanIpRange', params).then(() => {
          this.$notification.success({
            message: this.$t('message.success.add.iprange')
          })
        }).catch(error => {
          this.$notification.error({
            message: `${this.$t('label.error')} ${error.response.status}`,
            description: error.response.data.createvlaniprangeresponse
              ? error.response.data.createvlaniprangeresponse.errortext : error.response.data.errorresponse.errortext,
            duration: 0
          })
        }).finally(() => {
          this.componentLoading = false
          this.fetchData()
        })
      })
    },
    handleUpdateIpRange (e) {
      if (this.componentLoading) return
      this.formRef3.value.validate().then(async () => {
        const values = toRaw(this.form3)

        this.componentLoading = true
        this.updateIpRangeModal = false
        var params = {
          id: this.selectedIp.id,
          gateway: values.gateway,
          netmask: values.netmask,
          startip: values.startip,
          endip: values.endip,
          forsystemvms: values.forsystemvms
        }
        api('updateVlanIpRange', params).then(() => {
          this.$notification.success({
            message: this.$t('message.success.update.iprange')
          })
        }).catch(error => {
          this.$notification.error({
            message: `${this.$t('label.error')} ${error.response.status}`,
            description: error.response.data.updatevlaniprangeresponse
              ? error.response.data.updatevlaniprangeresponse.errortext : error.response.data.errorresponse.errortext,
            duration: 0
          })
        }).finally(() => {
          this.componentLoading = false
          this.fetchData()
        })
      })
    },
    async releaseAndDedicateIpRange (account, ip, toResellerAdmin) {
      if (ip.accountName !== 'system') {
        await api('releasePublicIpRange', {
          id: ip.vlaniprangeuuid
        }).catch(error => {
          this.$notifyError(error)
        })
      }
      var domainId
      var accountName
      if (toResellerAdmin) {
        domainId = this.domainUuid
        accountName = this.accountName
      } else {
        domainId = account === undefined ? this.domainUuid : account.domainId
        accountName = account === undefined ? this.accountName : account.accountName
      }
      await api('dedicatePublicIpRange', {
        id: ip.vlaniprangeuuid,
        zoneid: ip.zoneid,
        domainid: domainId,
        account: accountName
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.componentLoading = false
        this.addAccountModal = false
        this.fetchData()
      })
    },
    async changeAccounts (value) {
      this.domainsLoading = true
      this.selectedAcc = await this.getSubAccountByAccountName(this.subAccounts, value)
      this.domainsLoading = false
    },
    handleSearch (selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    updateSelectedColumns (name) {
      this.$emit('update-selected-columns', name)
    },
    getSubAccountByAccountName (subAccounts, selectedAccountName) {
      return subAccounts.filter(x => x.accountName === selectedAccountName)[0]
    }
  }
}
</script>

<style>
:deep(.ant-table-thead) {
  background-color: #f9f9f9;
}

:deep(.ant-table-small) > .ant-table-content > .ant-table-body {
  margin: 0;
}

:deep(.ant-table-tbody)>tr>td, :deep(.ant-table-thead)>tr>th {
  overflow-wrap: anywhere;
}

.filter-dropdown .ant-menu-vertical {
  border: none;
}

.filter-dropdown .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
</style>

<style scoped lang="scss">
  .actions {
    display: flex;
    justify-content: space-between;
    width: 70px;
  }
  .shift-btns {
    display: flex;
  }
  .shift-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 12px;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &--rotated {
      font-size: 10px;
      transform: rotate(90deg);
    }

  }

  .alert-notification-threshold {
    background-color: rgba(255, 231, 175, 0.75);
    color: #e87900;
    padding: 10%;
  }

  .alert-disable-threshold {
    background-color: rgba(255, 190, 190, 0.75);
    color: #f50000;
    padding: 10%;
  }
</style>
