import axios from 'axios'
// import store from '@/store'
// import notification from 'ant-design-vue/es/notification'

const AXIOS = axios.create({
  baseURL: `/wpapi`
  /* timeout: 60000 */
})

/* AXIOS.interceptors.response.use((res) => {
  return res
}, (error) => {
  console.log('Interceptor detected Error: ', error.response)
  if (error.response.status === 404 || error.response.request.status === 404) {
    notification.error({
      message: 'Sitzung abgelaufen',
      description: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
      duration: 6
    })
    store.dispatch('Logout').then(() => {
      window.location.href = 'https://login.mitteldeutsche-it.de'
    })
  }
  return Promise.reject(error)
}) */

export default {
  getDailyQuotaStatement (accountUuid, domainUuid) {
    return AXIOS.get('/dailyquota/getTotalQuota/' + accountUuid + '/' + domainUuid)
  },
  /* actualQuotaSummary (accountUuid) {
    return AXIOS.get('/statement/' + accountUuid)
  }, */
  /* actualQuotaSummaryDiscounted (accountUuid, domainUuid) {
    return AXIOS.get('/discount/statement/' + accountUuid + '/' + domainUuid)
  }, */
  discountListApi (accountUuid) {
    return AXIOS.get('/discount/listDiscount/' + accountUuid)
  },
  createDiscount (accountUuid) {
    return AXIOS.get('/discount/createDiscount/' + accountUuid + '/0/0')
  },
  getDiskofferingAndTemplateName (accountUuid) {
    return AXIOS.get('/discount/getTemplateOS/' + accountUuid)
  },
  checkSourceTemplate (templateID) {
    return AXIOS.get('softwareLicences/checkSourceTemplate/' + templateID)
  },
  createSoftwareLicences (params) {
    return AXIOS.post('/softwareLicences/createLicences', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  createTagEntryForVm (params) {
    return AXIOS.post('/softwareLicences/createVmLicensesCounter', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  createTagEntryForVmByAdmin (params) {
    return AXIOS.post('/softwareLicences/createVmLicensesByAdmin', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getSoftwareLicenceInfo (accountUuid) {
    return AXIOS.get('/softwareLicences/getAllLicences/' + accountUuid)
  },
  getSoftwareLicenceByVmOld (vmUuid) {
    return AXIOS.get('/softwareLicences/getLicenceInfoByVm/' + vmUuid)
  },
  getSoftwareLicenceByVm (vmUuid, tagId) {
    return AXIOS.get('softwareLicences/getLicenseInfoByVmUuidAndTagId/' + vmUuid + '/' + tagId)
  },
  checkForReservedIps (accountUuid) {
    return AXIOS.get('/ipaddress/getIpAddress/' + accountUuid)
  },
  getAllTags () {
    return AXIOS.get('/tag/getAllTagText')
  },
  getAllTagTypes () {
    return AXIOS.get('/tag/listTagType')
  },
  getAllTagsForVM (vmUuid) {
    return AXIOS.get('/tag/getAllDataTagForVMUUID/' + vmUuid)
  },
  getAllTagTextsForVM (vmUuid) {
    return AXIOS.get('/tag/getTagTextForVmUUId/' + vmUuid)
  },
  createRessourceTag (params) {
    return AXIOS.post('tag/createResourceTag', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  createNewLicenceTag (params) {
    return AXIOS.post('tag/createTagText', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  updateLicenceTagPrice (params) {
    return AXIOS.post('tag/updateLicenseTagData', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getTagsByTemplateUuid (templateUuId) {
    return AXIOS.get('tag/getTagResourceByTemplateUuId/' + templateUuId)
  },
  getTagfromVmUuid (VmUuId, accountUuId) {
    return AXIOS.get('tag/getTagFromVmUuid/' + VmUuId + '/' + accountUuId)
  },
  deleteVmTagFromVmDbAsAdmin (params) {
    return AXIOS.post('tag/removeTagVmInstancesByAdmin', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteVmTagFromVmDb (params) {
    return AXIOS.post('tag/removeTagForVMInstances', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteLicenseTag (params) {
    return AXIOS.post('/tag/removeTagTextByAdmin', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  editVmLicenseTagInVmDb (params) {
    return AXIOS.post('tag/updateTagTexAndCounterForVMInstances', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  editVmLicenseTagInVmDbAsAdmin (params) {
    return AXIOS.post('tag/updateVmTagTexByAdmin', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAllTagDataFromAccountVms (accountUUID) {
    return AXIOS.get('tag/getAllDataTagForVmByAccountUUID/' + accountUUID)
  },
  createVmSnapshot (params) {
    return AXIOS.post('snapshot/createSnapshotDataFromInstance', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  createVolumeSnapshot (params) {
    return AXIOS.post('snapshot/createSnapshotDataFromRootVolume', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  fetchSnapshotLicenseTags (snapshotuuid) {
    return AXIOS.get('snapshot/getAllSnapshotByUUID/' + snapshotuuid)
  },
  fetchTemplateLicenseTags (templateUuId) {
    return AXIOS.get('tag/getTagResourceByTemplateUuId/' + templateUuId)
  },
  vmBillingApiCreateDestroyVm (accountUuid) {
    return AXIOS.get('discount/getTemplateOS/' + accountUuid)
  },
  reinstallVmFromTemplate (templateuuid, vmuuid) {
    return AXIOS.get('snapshot/reinstallFromTemplate/' + templateuuid + '/' + vmuuid)
  },
  revertVmToSnapshot (snapshotuuid) {
    return AXIOS.get('snapshot/revertFromSnapshot/' + snapshotuuid)
  },
  addLicenseTagToTemplate (params) {
    return AXIOS.post('tag/addTagForTemplate', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteLicenseTagFromTemplate (params) {
    return AXIOS.post('tag/removeTagForTemplate', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getMonthlySummaryBillingList (accountID, year) {
    return AXIOS.get('/getMonthlyQuota/' + accountID + '/' + year)
  },
  saveMonthlySummaryBillingList (accountID, domainID) {
    return AXIOS.get('/saveMonthlyQuota/' + accountID + '/' + domainID)
  },
  fetchBillingChartData (params) {
    return AXIOS.post('/dailyquota/getChartQuotas', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  createAccountWpData (params) {
    return AXIOS.post('/address/create', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getWpAccountData (accountID) {
    return AXIOS.get('/address/getCustomer/' + accountID)
  },
  updateAccountWpData (params) {
    return AXIOS.post('/address/update', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  createInvoice (accountID, month, year) {
    return AXIOS.get('/detailedInvoice/getData/' + accountID + '/' + month + '/' + year, { responseType: 'blob' })
  },
  setNgfStatus (params) {
    return AXIOS.post('/firewall/setStatus', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getNgfStatus (accountID, vpcID) {
    return AXIOS.get('/firewall/getStatus/' + accountID + '/' + vpcID)
  },
  setAgbStatus (params) {
    return AXIOS.post('/agb/save/', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAgbStatus (accountName, domainName) {
    return AXIOS.get('/agb/get/' + accountName + '/' + domainName)
  },
  setInvoiceStatusAccount (params) {
    return AXIOS.post('/automatic/save', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getResellerSubAccounts (accountID) {
    return AXIOS.get('/listSubAccounts/' + accountID)
  },
  getInvoiceStatusAccounts (accountID) {
    return AXIOS.get('/automatic/checkdata/' + accountID)
  },
  getAcronisCss (params) {
    return AXIOS.post('/get/acronisCSS', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  // Outdated
  /* getAcronisRbs (params) {
    return AXIOS.post('/get/acronisRBS', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }, */
  getAcronisRbs (accountID) {
    return AXIOS.get('/getTotalAcronisRbs/' + accountID)
  },
  setAcronisMaintenance (params) {
    return AXIOS.post('/serviceCCM', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAcronisMaintenance (params) {
    return AXIOS.post('/getCCM', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  authorizeUser (params) {
    return AXIOS.post('/login', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  monthlyQuotaDashboard (params) {
    return AXIOS.post('/monthlyQuotaDashboard', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  setMinimumSales (params) {
    return AXIOS.post('/setMinimum', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getMinimumSales (accountUuid) {
    return AXIOS.get('/getMinimumSales/' + accountUuid)
  },
  refreshSession () {
    return AXIOS.post('/refreshLoginSession', {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
  /* getOsListWithTags (osList) {
    return AXIOS.get('tag/getOsData/' + osList)
  } */
  /* checkForFreeOrReservedIps (params) {
    return AXIOS.post('/ipaddress/getIpAddress', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  } */
}
