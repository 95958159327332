import { isAdmin } from '@/role'
export default {
  name: 'distributors',
  title: 'label.distributor',
  icon: 'tag-outlined',
  show: () => { return isAdmin() },
  children: [
    {
      name: 'distributorAccounts',
      title: 'label.distributor.accounts',
      icon: 'team-outlined',
      component: () => import('@/views/plugins/Distributors.vue')
    },
    {
      name: 'distributorArticles',
      title: 'label.distributor.articles',
      icon: 'shopping-outlined',
      component: () => import('@/views/plugins/DistributorArticles.vue')
    }
  ]
}
