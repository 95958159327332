<template>
  <div>
    <h2>Site-to-Site VPN</h2>
    <!-- Site to Site VPN Table -->
    <a-table
      id="vpnTable"
      class="table"
      size="small"
      :columns="vpnListCols"
      :dataSource="vpnCustomerGateways"
      :pagination="false"
      :loading="loading"
      :rowKey="item => item.id"
    >
      <template #name="{ text, record }">
        <a href="javascript:;">
          <router-link :to="{ path: '/vpncustomergateway/' + record.id }">
            {{ text }}
          </router-link>
        </a>
      </template>
      <template #publicip="{ record }">
        <div v-if="vpnConnections.length > 0">
          <a
            v-for="connectionInput in vpnConnections.filter(x => x.s2scustomergatewayid === record.id)"
            :key="connectionInput.id">
            <router-link
              v-if="connectionInput.id"
              :to="{ path: '/s2svpnconn/' + connectionInput.id }">
              {{ connectionInput.publicip || '---' }}
            </router-link>
          </a>
        </div>
      </template>
      <template #state="{ record }">
        <status
          v-for="connectionInput in vpnConnections.filter(x => x.s2scustomergatewayid === record.id)"
          :key="connectionInput.id "
          :text="connectionInput.state"
          displayText />
      </template>
      <template #activate="{ record }">
        <a-button
          style="margin-right: 2px;"
          :disabled="!('createVpnConnection' in $store.getters.apis)"
          @click="handleCreateVpnConnection (record.id)">
          {{ $t('label.connect') }}
        </a-button>
        <a-button
          type="primary"
          danger
          @click="handleDeleteVpnConnection (record.id)">
          {{ $t('label.disconnect') }}
        </a-button>
      </template>
      <template #VPNCustomerGatewaySettings="{ record }">
        <ActionButton
          v-if="dataView"
          :isTableButton="true"
          :dataView="dataView"
          :actions="actions"
          :selectedRowKeys="selectedRowKeys"
          :resource="record"
          :vpnConnectionsAll="vpnConnectionsAll"
          @exec-action="(action) => execAction(action, action.groupAction && !dataView)"/>
      </template>
    </a-table>
    <br/>
    <!-- Client to Site VPN Section -->
    <h2>Client-to-Site VPN</h2>
    <a-card>
      <VpnDetails
        v-for="publicIP in publicIps.filter(ip => ip.issourcenat && ip.vpcid === this.resource.id)"
        :key="publicIP.id"
        :resource="publicIP"
        :loading="loading"
        :actions="actions"/>
    </a-card>
    <!-- MODAL -->
    <div v-show="showAction">
      <a-modal
        v-model:visible="showAction"
        :closable="true"
        :maskClosable="false"
        style="top: 20px;"
        @ok="handleSubmit"
        @cancel="closeAction"
        :confirmLoading="actionLoading"
        centered
      >
      <template #title>
          <span v-if="currentAction.label">{{ $t(currentAction.label) }}</span>
          <a
            v-if="currentAction.docHelp || $route.meta.docHelp"
            style="margin-left: 5px"
            :href="$config.docBase + '/' + (currentAction.docHelp || $route.meta.docHelp)"
            target="_blank">
            <question-circle-outlined />
          </a>
        </template>
        <a-spin :spinning="actionLoading">
          <span v-if="currentAction.message">
            <a-alert type="warning">
                <template #message>
                  <span v-html="$t(currentAction.message)" />
                </template>
              </a-alert>
            <br v-if="currentAction.paramFields.length > 0"/>
          </span>
          <a-form
            :ref="formRef"
            :model="form"
            :rules="rules"
            @finish="handleSubmit"
            layout="vertical">
            <div v-for="(field, fieldIndex) in currentAction.paramFields" :key="fieldIndex">
              <a-form-item
                :name="field.name"
                :ref="field.name"
                :v-bind="field.name"
                v-if="!(currentAction.mapping && field.name in currentAction.mapping && currentAction.mapping[field.name].value)"
              >
                <template #label>
                  <tooltip-label :title="$t('label.' + field.name)" :tooltip="field.description"/>
                </template>

                <a-switch
                  v-if="field.type==='boolean'"
                  v-model:checked="form[field.name]"
                  :placeholder="field.description"
                  v-focus="fieldIndex === firstIndex"
                />
                <a-select
                  v-else-if="currentAction.mapping && field.name in currentAction.mapping && currentAction.mapping[field.name].options"
                  :loading="field.loading"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                  v-focus="fieldIndex === firstIndex"
                  showSearch
                  optionFilterProp="label"
                  :filterOption="(input, option) => {
                    return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                >
                  <a-select-option key="" >{{ }}</a-select-option>
                  <a-select-option v-for="(opt, optIndex) in currentAction.mapping[field.name].options" :key="optIndex">
                    {{ opt }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="field.name==='keypair' ||
                    (field.name==='account' && !['addAccountToProject', 'createAccount'].includes(currentAction.api))"
                  showSearch
                  optionFilterProp="label"
                  v-model:value="form[field.name]"
                  :loading="field.loading"
                  :placeholder="field.description"
                  :filterOption="(input, option) => {
                    return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                  v-focus="fieldIndex === firstIndex"
                >
                  <a-select-option key="">{{ }}</a-select-option>
                  <a-select-option v-for="(opt, optIndex) in field.opts" :key="optIndex">
                    {{ opt.name || opt.description || opt.traffictype || opt.publicip }}
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="field.type==='uuid'"
                  showSearch
                  optionFilterProp="label"
                  v-model:value="form[field.name]"
                  :loading="field.loading"
                  :placeholder="field.description"
                  :filterOption="(input, option) => {
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                  v-focus="fieldIndex === firstIndex"
                >
                  <a-select-option key="" label="">{{ }}</a-select-option>
                  <a-select-option v-for="opt in field.opts" :key="opt.id" :label="opt.name || opt.description || opt.traffictype || opt.publicip">
                    <div>
                      <span v-if="(field.name.startsWith('template') || field.name.startsWith('iso'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <os-logo v-else :osId="opt.ostypeid" :osName="opt.ostypename" size="lg" style="margin-left: -1px" />
                      </span>
                      <span v-if="(field.name.startsWith('zone'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <global-outlined v-else style="margin-right: 5px" />
                      </span>
                      <span v-if="(field.name.startsWith('project'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <project-outlined v-else style="margin-right: 5px" />
                      </span>
                      <span v-if="(field.name.startsWith('account') || field.name.startsWith('user'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <user-outlined v-else style="margin-right: 5px"/>
                      </span>
                      <span v-if="(field.name.startsWith('network'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <apartment-outlined v-else style="margin-right: 5px"/>
                      </span>
                      <span v-if="(field.name.startsWith('domain'))">
                        <span v-if="opt.icon">
                          <resource-icon :image="opt.icon.base64image" size="1x" style="margin-right: 5px"/>
                        </span>
                        <block-outlined v-else style="margin-right: 5px"/>
                      </span>
                      {{ opt.name || opt.description || opt.traffictype || opt.publicip }}
                    </div>
                  </a-select-option>
                </a-select>
                <a-select
                  v-else-if="field.type==='list'"
                  :loading="field.loading"
                  mode="multiple"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                  v-focus="fieldIndex === firstIndex"
                  showSearch
                  optionFilterProp="label"
                  :filterOption="(input, option) => {
                    return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }"
                >
                  <a-select-option v-for="(opt, optIndex) in field.opts" :key="optIndex">
                    {{ opt.name && opt.type ? opt.name + ' (' + opt.type + ')' : opt.name || opt.description }}
                  </a-select-option>
                </a-select>
                <a-input-number
                  v-else-if="field.type==='long'"
                  v-focus="fieldIndex === firstIndex"
                  style="width: 100%;"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                />
                <a-input-password
                  v-else-if="field.name==='password' || field.name==='currentpassword' || field.name==='confirmpassword'"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                  @blur="($event) => handleConfirmBlur($event, field.name)"
                  v-focus="fieldIndex === firstIndex"
                />
                <a-textarea
                  v-else-if="field.name==='certificate' || field.name==='privatekey' || field.name==='certchain'"
                  rows="2"
                  v-model:value="form[field.name]"
                  :placeholder="field.description"
                  v-focus="fieldIndex === firstIndex"
                />
                <!-- WP Implementation Change input field for arg "ipsecpsk"-->
                  <a-input
                    v-else-if="field.name==='ipsecpsk' && $route.path.startsWith('/vpn')"
                    v-focus="fieldIndex === firstIndex"
                    v-model:value="form[field.name]"
                    :placeholder="field.description"
                    disabled />
                <!-- WP Implementation -->
                  <a-input
                    v-else
                    v-focus="fieldIndex === firstIndex"
                    v-model:value="form[field.name]"
                    :placeholder="field.description" />
              </a-form-item>
            </div>
          </a-form>
        </a-spin>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { api } from '@/api'
import Status from '@/components/widgets/Status'
import { mixinDevice } from '@/utils/mixin.js'
import store from '@/store'
import VpnDetails from '@/views/network/VpnDetails.vue'
import { ref, reactive, toRaw } from 'vue'
import TooltipLabel from '@/components/widgets/TooltipLabel'
import ResourceIcon from '@/components/view/ResourceIcon'
import OsLogo from '@/components/widgets/OsLogo'
import eventBus from '@/config/eventBus'
import ActionButton from '@/components/view/ActionButton'

/**
 * Component for site-to-site and client-to-site VPN configurations.
 * It is getting called when clicking the VPN Settings Button in the VpcSettings.vue component.
 */
export default {
  name: 'Vpn',
  components: {
    Status,
    ActionButton,
    VpnDetails,
    TooltipLabel,
    ResourceIcon,
    OsLogo
  },
  mixins: [mixinDevice],
  props: {
    resource: {
      type: Object,
      default: () => {}
    },
    actions: {
      type: Array,
      default () {
        return []
      }
    },
    selectedRowKeys: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      loading: false,
      dataSource: [],
      publicIps: [],
      vpnCustomerGateways: [],
      vpnGateways: [],
      vpnConnections: [],
      vpnConnectionsAll: [],
      apiName: '',
      actionLoading: false,
      currentAction: {},
      showAction: false,
      formModel: {},
      dataView: true,
      vpnListCols: [
        {
          title: this.$t('label.vpncustomergatewayid'),
          dataIndex: 'name',
          slots: { customRender: 'name' }
        },
        {
          title: this.$t('label.ip'),
          dataIndex: 'publicip',
          slots: { customRender: 'publicip' }
        },
        {
          title: this.$t('label.vpn.connection'),
          dataIndex: 'state',
          slots: { customRender: 'state' }
        },
        {
          title: this.$t('label.gateway'),
          dataIndex: 'gateway'
        },
        {
          title: this.$t('label.cidrlist'),
          dataIndex: 'cidrlist'
        },
        {
          title: this.$t('label.manage.vpn.connections'),
          dataIndex: 'activate',
          slots: { customRender: 'activate' }
        },
        {
          title: <span>{this.$t('label.vpncustomergatewayid') + ' ' + this.$t('label.settings') + ' '}<a-tooltip title={this.$t('label.vpncustomergateway.settings.tooltip')}><InfoCircleOutlined style="color: rgba(0,0,0,.45)"/></a-tooltip></span>,
          dataIndex: 'VPNCustomerGatewaySettings',
          slots: { customRender: 'VPNCustomerGatewaySettings' }
        }
      ]
    }
  },
  watch: {
    resource: function (newItem, oldItem) {
      if (newItem !== oldItem && this.resource.vpcofferingid) {
        this.fetchData()
      }
    }
  },
  beforeUnmount () {
    eventBus.off('vm-refresh-data')
    eventBus.off('async-job-complete')
    eventBus.off('exec-action')
  },
  created () {
    this.formRef = ref()
    this.form = reactive({})
    this.rules = reactive({})
    eventBus.on('vm-refresh-data', () => {
      if (this.$route.path === '/vm' || this.$route.path.includes('/vm/')) {
        this.fetchData()
      }
    })
    eventBus.on('refresh-icon', () => {
      if (this.$showIcon()) {
        this.fetchData()
      }
    })
    eventBus.on('async-job-complete', (action) => {
      if (this.$route.path.includes('/vm/')) {
        if (action && 'api' in action && ['destroyVirtualMachine'].includes(action.api)) {
          return
        }
      }

      if ((this.$route.path.includes('/publicip/') && ['firewall', 'portforwarding', 'loadbalancing'].includes(this.$route.query.tab)) ||
        (this.$route.path.includes('/guestnetwork/') && (this.$route.query.tab === 'egress.rules' || this.$route.query.tab === 'public.ip.addresses'))) {
        return
      }

      if (this.$route.path.includes('/template/') || this.$route.path.includes('/iso/')) {
        return
      }
      this.fetchData()
    })
    eventBus.on('update-bulk-job-status', (args) => {
      var { items, action } = args
      for (const item of items) {
        this.$store.getters.headerNotices.map(function (j) {
          if (j.jobid === item.jobid) {
            j.bulkAction = action
          }
        })
      }
    })

    eventBus.on('update-resource-state', (args) => {
      var {
        selectedItems,
        resource,
        state,
        jobid
      } = args
      if (selectedItems.length === 0) {
        return
      }
      var tempResource = []
      this.selectedItems = selectedItems
      if (selectedItems && resource) {
        if (resource.includes(',')) {
          resource = resource.split(',')
          tempResource = resource
        } else {
          tempResource.push(resource)
        }
        for (var r = 0; r < tempResource.length; r++) {
          var objIndex = 0
          objIndex = selectedItems.findIndex(obj => (obj.id === tempResource[r] || obj.username === tempResource[r] || obj.name === tempResource[r]))
          if (state && objIndex !== -1) {
            this.selectedItems[objIndex].status = state
          }
          if (jobid && objIndex !== -1) {
            this.selectedItems[objIndex].jobid = jobid
          }
        }
      }
    })
  },
  mounted () {
    eventBus.on('exec-action', (args) => {
      const { action, isGroupAction } = args
      this.execAction(action, isGroupAction)
    })
    this.fetchAllVpnConnections()
    this.fetchData()
  },
  methods: {
    /**
     * Fetches all needed Information in various functions: fetchVpnCustomerGateways(), fetchVpnConnections(), fetchPublicIPs(), fetchVpnGateways().
     * @public
     */
    fetchData () {
      this.loading = true
      this.fetchVpnCustomerGateways()
      this.fetchVpnConnections()
      this.fetchPublicIPs()
      this.fetchVpnGateways()
    },
    /**
     * Fetch all Public Ip's connected with the current VPC via the "listPublicIpAddresses" API.
     * @public
     */
    fetchPublicIPs () {
      this.loading = true
      api('listPublicIpAddresses', {
        account: this.resource.account,
        domainid: this.resource.domainid
      })
        .then(json => {
          this.publicIps = json.listpublicipaddressesresponse.publicipaddress
        })
        .catch(error => {
          this.$notifyError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * Fetch all VpnCustomerGateways connected with the current VPC via the "listVpnCustomerGateways" API.
     * @public
     */
    fetchVpnCustomerGateways () {
      this.loading = true
      api('listVpnCustomerGateways', {
        account: this.resource.account,
        domainid: this.resource.domainid,
        page: this.page,
        pagesize: this.pageSize
      })
        .then(json => {
          this.vpnCustomerGateways = json.listvpncustomergatewaysresponse.vpncustomergateway
        })
        .catch(error => {
          this.$notifyError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * Fetch all VpnConnections connected with the current VPC via the "listVpnConnections" API.
     * @public
     */
    fetchVpnConnections () {
      this.loading = true
      api('listVpnConnections', {
        account: this.resource.account,
        domainid: this.resource.domainid
      })
        .then(json => {
          this.vpnConnections = json.listvpnconnectionsresponse.vpnconnection || []
        })
        .catch(error => {
          this.$notifyError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * Fetch all VpnGateways connected with the current VPC via the "listVpnGateways" API.
     * @public
     */
    fetchVpnGateways () {
      this.loading = true
      api('listVpnGateways', {
        account: this.resource.account,
        domainid: this.resource.domainid
      }).then(json => {
        this.vpnGateways = json.listvpngatewaysresponse.vpngateway ? json.listvpngatewaysresponse.vpngateway : []
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * Fetch all existing VpnConnections that the user can see via the "listVpnConnections" API.
     * (this is NOT coupled to any VPC)
     * @public
     */
    fetchAllVpnConnections () {
      this.loading = true
      api('listVpnConnections', {
        listAll: true
      })
        .then(json => {
          this.vpnConnectionsAll = json.listvpnconnectionsresponse.vpnconnection
        })
        .catch(error => {
          this.$notifyError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * Fetch all existing VpnConnections that the user can see via the "listVpnConnections" Api.
     * (this is NOT coupled to any VPC)
     * @param {String} vpnGatewayInput ID of the VPNCustomerGateway where a VPN connection should be established to.
     * @public
     */
    handleCreateVpnConnection (vpnGatewayInput) {
      this.loading = true
      api('createVpnConnection', {
        s2svpngatewayid: this.vpnGateways[0].id,
        s2scustomergatewayid: vpnGatewayInput,
        account: this.resource.account,
        domainid: this.resource.domainid
      }).then(response => {
        this.$store.dispatch('AddAsyncJob', {
          title: this.$t('label.vpn.connection'),
          jobid: response.createvpnconnectionresponse.jobid,
          status: 'progress'
        })
        this.$pollJob({
          jobId: response.createvpnconnectionresponse.jobid,
          successMethod: () => {
            this.fetchVpnConnections()
            this.loading = false
          },
          errorMessage: this.$t('message.add.vpn.connection.failed'),
          errorMethod: () => {
            this.fetchVpnConnections()
            this.loading = false
          },
          loadingMessage: this.$t('message.add.vpn.connection.processing'),
          catchMessage: this.$t('error.fetching.async.job.result'),
          catchMethod: () => {
            this.fetchVpnConnections()
            this.loading = false
          }
        })
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.fetchVpnConnections()
        this.fetchAllVpnConnections()
        this.loading = false
      })
    },
    handleDeleteVpnConnection (deleteThisVpnConnection) {
      this.loading = true
      if (!this.vpnConnections || this.vpnConnections.length === 0) {
        alert(this.$t('label.vpn.connection.notfound'))
        this.loading = false
      } else {
        this.loading = true
        for (let j = 0; j < this.vpnConnections.length; j++) {
          if (this.vpnConnections[j].s2scustomergatewayid === deleteThisVpnConnection) {
            const vpnConnect = this.vpnConnections[j]
            api('deleteVpnConnection', { id: vpnConnect.id }).then(response => {
              this.$store.dispatch('AddAsyncJob', {
                title: this.$t('label.vpn.connection'),
                jobid: response.deletevpnconnectionresponse.jobid,
                status: 'progress'
              })
              this.$pollJob({
                jobId: response.deletevpnconnectionresponse.jobid,
                successMethod: () => {
                  this.fetchVpnConnections()
                  this.fetchAllVpnConnections()
                  this.loading = false
                },
                errorMessage: this.$t('message.delete.vpn.connection'),
                errorMethod: () => {
                  this.fetchVpnConnections()
                  this.loading = false
                }
              })
            }).catch(error => {
              this.$notifyError(error)
            }).finally(() => {
              this.fetchVpnConnections()
              this.fetchAllVpnConnections()
              this.loading = false
            })
            return
          }
        } alert(this.$t('label.vpncustomergateway.noactiveconnection'))
        this.loading = false
      }
    },
    closeAction () {
      this.actionLoading = false
      this.showAction = false
      this.currentAction = {}
    },
    onRowSelectionChange (selection) {
      // eslint-disable-next-line vue/no-mutating-props
      this.selectedRowKeys = selection
    },
    execAction (action, isGroupAction) {
      const self = this
      this.formRef = ref()
      this.form = reactive({})
      this.rules = reactive({})
      if (action.component && action.api && !action.popup) {
        const query = {}
        this.$router.push({ name: action.api, query })
        return
      }
      this.currentAction = action
      this.currentAction.params = store.getters.apis[this.currentAction.api].params
      this.$emit('change-resource', this.resource)
      var paramFields = this.currentAction.params
      paramFields.sort(function (a, b) {
        if (a.name === 'name' && b.name !== 'name') { return -1 }
        if (a.name !== 'name' && b.name === 'name') { return -1 }
        if (a.name === 'id') { return -1 }
        if (a.name < b.name) { return -1 }
        if (a.name > b.name) { return 1 }
        return 0
      })
      this.currentAction.paramFields = []
      if ('message' in action) {
        var message = action.message
        if (typeof action.message === 'function') {
          message = action.message(action.resource)
        }
        action.message = message
      }
      if ('args' in action) {
        var args = action.args
        if (typeof action.args === 'function') {
          args = action.args(action.resource, this.$store.getters, isGroupAction)
        }
        if (args.length > 0) {
          this.currentAction.paramFields = args.map(function (arg) {
            if (arg === 'confirmpassword') {
              return {
                type: 'password',
                name: 'confirmpassword',
                required: true,
                description: self.$t('label.confirmpassword.description')
              }
            }
            return paramFields.filter(function (param) {
              return param.name.toLowerCase() === arg.toLowerCase()
            })[0]
          })
        }
      }
      this.getFirstIndexFocus()
      this.showAction = true
      const listIconForFillValues = ['copy-outlined', 'CopyOutlined', 'edit-outlined', 'EditOutlined', 'share-alt-outlined', 'ShareAltOutlined']
      for (const param of this.currentAction.paramFields) {
        if (param.type === 'list' && ['tags', 'hosttags', 'storagetags', 'files'].includes(param.name)) {
          param.type = 'string'
        }
        this.setRules(param)
        if (param.type === 'uuid' || param.type === 'list' || param.name === 'account' || (this.currentAction.mapping && param.name in this.currentAction.mapping)) {
          this.listUuidOpts(param)
        }
      }
      this.actionLoading = false
      if (action.dataView && listIconForFillValues.includes(action.icon)) {
        this.fillEditFormFieldValues(action.resource)
      }
    },
    getFirstIndexFocus () {
      this.firstIndex = 0
      for (let fieldIndex = 0; fieldIndex < this.currentAction.paramFields.length; fieldIndex++) {
        const field = this.currentAction.paramFields[fieldIndex]
        if (!(this.currentAction.mapping && field.name in this.currentAction.mapping && this.currentAction.mapping[field.name].value)) {
          this.firstIndex = fieldIndex
          break
        }
      }
    },
    listUuidOpts (param) {
      if (this.currentAction.mapping && param.name in this.currentAction.mapping && !this.currentAction.mapping[param.name].api) {
        return
      }
      var paramName = param.name
      var extractedParamName = paramName.replace('ids', '').replace('id', '').toLowerCase()
      var params = { listall: true }
      const possibleName = 'list' + extractedParamName + 's'
      var showIcon = false
      if (this.$showIcon(extractedParamName)) {
        showIcon = true
      }
      var possibleApi
      if (this.currentAction.mapping && param.name in this.currentAction.mapping && this.currentAction.mapping[param.name].api) {
        possibleApi = this.currentAction.mapping[param.name].api
        if (this.currentAction.mapping[param.name].params) {
          const customParams = this.currentAction.mapping[param.name].params(this.resource)
          if (customParams) {
            params = { ...params, ...customParams }
          }
        }
      } else if (paramName === 'id') {
        possibleApi = this.apiName
      } else {
        for (const api in store.getters.apis) {
          if (api.toLowerCase().startsWith(possibleName)) {
            possibleApi = api
            break
          }
        }
      }
      if (!possibleApi) {
        return
      }
      param.loading = true
      param.opts = []
      if (possibleApi === 'listTemplates') {
        params.templatefilter = 'executable'
      } else if (possibleApi === 'listIsos') {
        params.isofilter = 'executable'
      } else if (possibleApi === 'listHosts') {
        params.type = 'routing'
      } else if (possibleApi === 'listNetworkOfferings' && this.resource) {
        if (this.resource.type) {
          params.guestiptype = this.resource.type
        }
        if (!this.resource.vpcid) {
          params.forvpc = false
        }
      }
      if (showIcon) {
        params.showicon = true
      }
      api(possibleApi, params).then(json => {
        param.loading = false
        for (const obj in json) {
          if (obj.includes('response')) {
            for (const res in json[obj]) {
              if (res === 'count') {
                continue
              }
              param.opts = json[obj][res]
              if (this.currentAction.mapping && this.currentAction.mapping[param.name] && this.currentAction.mapping[param.name].filter) {
                const filter = this.currentAction.mapping[param.name].filter
                param.opts = json[obj][res].filter(filter)
              }
              if (['listTemplates', 'listIsos'].includes(possibleApi)) {
                param.opts = [...new Map(param.opts.map(x => [x.id, x])).values()]
              }
              break
            }
            break
          }
        }
      }).catch(function (error) {
        console.log(error)
        param.loading = false
      })
    },
    setRules (field) {
      let rule = {}

      if (!field || Object.keys(field).length === 0) {
        return
      }

      if (!this.rules[field.name]) {
        this.rules[field.name] = []
      }

      switch (true) {
        case (field.type === 'boolean'):
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          this.rules[field.name].push(rule)
          break
        case (this.currentAction.mapping && field.name in this.currentAction.mapping && 'options' in this.currentAction.mapping[field.name]):
          rule.required = field.required
          rule.message = this.$t('message.error.select')
          this.rules[field.name].push(rule)
          break
        case (field.name === 'keypair' || (field.name === 'account' && !['addAccountToProject', 'createAccount'].includes(this.currentAction.api))):
          rule.required = field.required
          rule.message = this.$t('message.error.select')
          this.rules[field.name].push(rule)
          break
        case (field.type === 'uuid'):
          rule.required = field.required
          rule.message = this.$t('message.error.select')
          this.rules[field.name].push(rule)
          break
        case (field.type === 'list'):
          rule.type = 'array'
          rule.required = field.required
          rule.message = this.$t('message.error.select')
          this.rules[field.name].push(rule)
          break
        case (field.type === 'long'):
          rule.type = 'number'
          rule.required = field.required
          rule.message = this.$t('message.validate.number')
          this.rules[field.name].push(rule)
          break
        case (field.name === 'password' || field.name === 'currentpassword' || field.name === 'confirmpassword'):
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          this.rules[field.name].push(rule)

          rule = {}
          rule.validator = this.validateTwoPassword
          this.rules[field.name].push(rule)
          break
        case (field.name === 'certificate' || field.name === 'privatekey' || field.name === 'certchain'):
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          this.rules[field.name].push(rule)
          break
        case (field.name === 'newname'):
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          rule.validator = this.validateAccountName
          this.rules[field.name].push(rule)
          break
        default:
          rule.required = field.required
          rule.message = this.$t('message.error.required.input')
          this.rules[field.name].push(rule)
          break
      }

      rule = {}
    },
    pollActionCompletion (jobId, action, resourceName, resource, showLoading = true) {
      if (this.shouldNavigateBack(action)) {
        action.isFetchData = false
      }
      return new Promise((resolve) => {
        this.$pollJob({
          jobId,
          title: this.$t(action.label),
          description: resourceName,
          name: resourceName,
          successMethod: async result => {
            if (this.selectedItems.length > 0) {
              eventBus.emit('update-resource-state', { selectedItems: this.selectedItems, resource, state: 'success' })
            }
            if (action.response) {
              const description = action.response(result.jobresult)
              if (description) {
                this.$notification.info({
                  message: this.$t(action.label),
                  description: (<span v-html={description}></span>),
                  duration: 0
                })
              }
            }
            if ('successMethod' in action) {
              action.successMethod(this, result)
            }
            resolve(true)
          },
          errorMethod: () => {
            if (this.selectedItems.length > 0) {
              eventBus.emit('update-resource-state', { selectedItems: this.selectedItems, resource, state: 'failed' })
            }
            resolve(true)
          },
          loadingMessage: `${this.$t(action.label)} - ${resourceName}`,
          showLoading: showLoading,
          catchMessage: this.$t('error.fetching.async.job.result'),
          action,
          bulkAction: `${this.selectedItems.length > 0}` && this.showGroupActionModal,
          resourceId: resource
        })
      })
    },
    fillEditFormFieldValues (vpnResource) {
      this.currentAction.paramFields.map(field => {
        let fieldValue = null
        let fieldName = null
        if (field.type === 'list' || field.name === 'account') {
          fieldName = field.name.replace('ids', 'name').replace('id', 'name')
        } else {
          fieldName = field.name
        }
        fieldValue = vpnResource[fieldName] ? vpnResource[fieldName] : null
        if (fieldValue) {
          this.form[field.name] = fieldValue
        }
      })
    },
    handleSubmit (e) {
      if (!this.dataView && this.currentAction.groupAction && this.selectedRowKeys.length > 0) {
        this.formRef.value.validate().then(() => {
          const values = toRaw(this.form)
          this.actionLoading = true
          const itemsNameMap = {}
          this.items.map(x => {
            itemsNameMap[x.id] = x.name || x.displaytext || x.id
          })
          const paramsList = this.currentAction.groupMap(this.selectedRowKeys, values)
          for (const params of paramsList) {
            var resourceName = itemsNameMap[params.id]
            // Using a method for this since it's an async call and don't want wrong prarms to be passed
            this.callGroupApi(params, resourceName)
          }
          this.$message.info({
            content: this.$t(this.currentAction.label),
            key: this.currentAction.label,
            duration: 3
          })
          setTimeout(() => {
            this.actionLoading = false
            this.closeAction()
            this.fetchData()
          }, 500)
        })
      } else {
        this.execSubmit(e)
      }
    },
    callGroupApi (params, resourceName) {
      const action = this.currentAction
      api(action.api, params).then(json => {
        this.handleResponse(json, resourceName, action, false)
      }).catch(error => {
        if ([401].includes(error.response.status)) {
          return
        }
        this.$notifyError(error)
      })
    },
    execSubmit (e) {
      e.preventDefault()
      this.formRef.value.validate().then(async () => {
        const values = toRaw(this.form)
        const params = {}
        const action = this.currentAction
        if ('id' in this.resource && action.params.map(i => { return i.name }).includes('id')) {
          params.id = action.resource.id
          // WP Implementation
        }
        for (const key in values) {
          const input = values[key]
          for (const param of action.params) {
            if (param.name !== key) {
              continue
            }
            if (input === undefined || input === null ||
              (input === '' && !['updateStoragePool', 'updateHost', 'updatePhysicalNetwork', 'updateDiskOffering', 'updateNetworkOffering', 'updateServiceOffering'].includes(action.api))) {
              if (param.type === 'boolean') {
                params[key] = false
              }
              break
            }
            if (input === '' && !['tags', 'hosttags', 'storagetags'].includes(key)) {
              break
            }
            if (action.mapping && key in action.mapping && action.mapping[key].options) {
              params[key] = action.mapping[key].options[input]
            } else if (param.type === 'list') {
              params[key] = input.map(e => { return param.opts[e].id }).reduce((str, name) => { return str + ',' + name })
            } else if (param.name === 'account' || param.name === 'keypair') {
              if (['addAccountToProject', 'createAccount'].includes(action.api)) {
                params[key] = input
              } else {
                params[key] = param.opts[input].name
              }
            } else {
              params[key] = input
            }
            break
          }
        }

        for (const key in action.defaultArgs) {
          if (!params[key]) {
            params[key] = action.defaultArgs[key]
          }
        }

        if (!this.projectView || !['uploadSslCert'].includes(action.api)) {
          if (action.mapping) {
            for (const key in action.mapping) {
              if (!action.mapping[key].value) {
                continue
              }
              params[key] = action.mapping[key].value(this.resource, params)
            }
          }
        }
        this.actionLoading = true
        let args = null
        if (action.post) {
          args = [action.api, {}, 'POST', params]
        } else {
          args = [action.api, params]
        }
        // WP Implementation
        await api(...args).then(response => {
          // /WP Implementation
          if (!response) {
            this.fetchData()
            this.closeAction()
            return
          }
          this.closeAction()
        }).catch(error => {
          if ([401].includes(error.response.status)) {
            return
          }

          console.log(error)
          this.$notifyError(error)
        }).finally(f => {
          this.fetchData()
          this.actionLoading = false
        })
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>
