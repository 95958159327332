// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-spin :spinning="false">
    <span class='row-action-button' style="justify-content: flex-end;" v-if="forQuickView">
      <a-dropdown
        v-if="!$route.path.startsWith('/vpn/') && numberOfActions > 1"
      >
        <a-button shape="circle" size="small" style="background-color: transparent; border-color: transparent">
          <template #icon><MoreOutlined /></template>
        </a-button>
        <template #overlay>
          <a-menu
            class='user-menu-wrapper'>
            <a-menu-item
            v-if="['vm', 'systemvm', 'router', 'ilbvm'].includes($route.meta.name) && 'listVirtualMachines' in $store.getters.apis"
            :key='0'
            :disabled="['Stopped', 'Error', 'Destroyed'].includes(resource.state)"
            @click="clickLink"
            >
            <Console :resource='resource' :size='size' :forQuick="true"/>
            <code-outlined style="margin-right: 9px;" />
            <span>{{ $t('label.view.console') }}</span>
            </a-menu-item>
            <template  v-for='(action, actionIndex) in actions'>
              <a-menu-item
                :key='actionIndex'
                :disabled="'disabled' in action ? action.disabled(resource, $store.getters) : false"
                @click='execAction(action)'
                v-if="action.api in $store.getters.apis &&
                  !action.showBadge && !$route.path.startsWith('/vpn/') && (
                    (!dataView && ((action.listView && ('show' in action ? action.show(resource, $store.getters) : true)) || (action.groupAction && selectedRowKeys.length > 0 && ('groupShow' in action ? action.groupShow(selectedItems, $store.getters) : true)))) ||
                    (dataView && action.dataView && ('show' in action ? action.show(resource, $store.getters) : true))
                  )"
              >
                <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
                  <font-awesome-icon v-else :icon='action.icon' />
                  {{ $t(action.label) }}
              </a-menu-item>
              <!-- Action Button Menu for createSaasManager API -->
               <!--  <a-menu-item
                  :key='actionIndex'
                  :disabled="saasManagerActive"
                  @click='execAction(action)'
                  v-if="$store.getters.userInfo.roletype === 'Admin' && ['ResellerAdmin'].includes(resource.rolename) && action.api === 'createSaasManager' ">
                  <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
                    <font-awesome-icon v-else :icon='action.icon' />
                    {{ $t(action.label) }}
                </a-menu-item> -->
            </template>

          </a-menu>
        </template>
      </a-dropdown>
        <a-tooltip class='row-action-button-console' arrowPointAtCenter placement='bottomRight' v-else-if="numberOfActions === 1 && !$route.path.startsWith('/vpn/')">
          <template #title>
            {{ $t(actions[0].label) }}
          </template>
          <span
            :disabled="'disabled' in actions[0] ? actions[0].disabled(resource, $store.getters) : false"
            @click='execAction(actions[0])'
            v-if="actions[0].api in $store.getters.apis &&
              !actions[0].showBadge && !$route.path.startsWith('/vpn/') && (
                (!dataView && ((actions[0].listView && ('show' in actions[0] ? actions[0].show(resource, $store.getters) : true)) || (actions[0].groupAction && selectedRowKeys.length > 0 && ('groupShow' in actions[0] ? action.groupShow(selectedItems, $store.getters) : true)))) ||
                (dataView && actions[0].dataView && ('show' in actions[0] ? actions[0].show(resource, $store.getters) : true))
              )"
          >
            <render-icon v-if="(typeof actions[0].icon === 'string')" :icon='actions[0].icon' />
            <font-awesome-icon v-else :icon='actions[0].icon' />
          </span>
      </a-tooltip>
      <template v-else-if="$route.path.startsWith('/vpn/')">
        <a-tooltip
        v-for='(action, actionIndex) in actions'
        :key='actionIndex'
        arrowPointAtCenter
        placement='bottomRight'>
        <template #title>

          {{ $t(action.label) }}
        </template>
        <a-badge
          class='button-action-badge'
          :overflowCount='9'
          :count='actionBadge[action.api] ? actionBadge[action.api].badgeNum : 0'
          v-if="action.api in $store.getters.apis &&
            action.showBadge && (
              (!dataView && ((action.listView && ('show' in action ? action.show(resource, $store.getters) : true)) || (action.groupAction && selectedRowKeys.length > 0 && ('groupShow' in action ? action.groupShow(selectedItems, $store.getters) : true)))) ||
              (dataView && action.dataView && ('show' in action ? action.show(resource, $store.getters) : true))
            )"
          :disabled="'disabled' in action ? action.disabled(resource, $store.getters) : false">
          <a-button
            :type="(primaryIconList.includes(action.icon) ? 'primary' : 'default')"
            :shape="!dataView && ['PlusOutlined', 'plus-outlined'].includes(action.icon) ? 'round' : 'circle'"
            :danger='dangerIconList.includes(action.icon)'
            style='margin-left: 5px'
            :size='size'
            @click='execAction(action)'>
            <span v-if="!dataView && ['PlusOutlined', 'plus-outlined'].includes(action.icon)">
              {{ $t(action.label) }}
            </span>
            <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
            <font-awesome-icon v-else :icon='action.icon' />
          </a-button>
        </a-badge>
        <a-button
          v-if="action.api in $store.getters.apis &&
            !action.showBadge && !$route.path.startsWith('/vpn/') && (
              (!dataView && ((action.listView && ('show' in action ? action.show(resource, $store.getters) : true)) || (action.groupAction && selectedRowKeys.length > 0 && ('groupShow' in action ? action.groupShow(selectedItems, $store.getters) : true)))) ||
              (dataView && action.dataView && ('show' in action ? action.show(resource, $store.getters) : true))
            )"
          :disabled="'disabled' in action ? action.disabled(resource, $store.getters) : false"
          :type="(primaryIconList.includes(action.icon) ? 'primary' : 'default')"
          :danger='dangerIconList.includes(action.icon)'
          :shape="!dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon) ? 'round' : 'circle'"
          style='margin-left: 5px'
          :size='size'
          @click='execAction(action)'>
          <span
            v-if="!dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon)">
            {{ $t(action.label) }}
          </span>
          <!-- WP Implementation -->
          <span
            v-else-if="dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon) && action.label === 'label.add.vpn.customer.gateway'">
            {{ $t(action.label) }}
          </span>
          <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
          <font-awesome-icon v-else :icon='action.icon' />
        </a-button>
        <!-- This case is only for the "vpn" path! -->
        <a-button
          v-else-if="action.api in $store.getters.apis &&
            !action.showBadge && $route.path.startsWith('/vpn/') && (
              (dataView && !isTableButton && ((action.listView && ('show' in action ? action.show(resource, $store.getters) : true)) || (action.groupAction && selectedRowKeys.length > 0 && ('groupShow' in action ? action.show(resource, $store.getters) : true)))) ||
              (dataView && action.dataView && isTableButton &&('show' in action ? action.show(resource, $store.getters) : true))
            )"
          :disabled='isDisabled'
          :type="(primaryIconList.includes(action.icon) ? 'primary' : 'default')"
          :danger='dangerIconList.includes(action.icon)'
          :shape="dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon) ? 'round' : 'circle'"
          style='margin-left: 5px'
          :size='size'
          @click='execAction(action)'>
          <span
            v-if="!dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon)">
            {{ $t(action.label) }}
          </span>
          <span
            v-else-if="dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon) && action.label === 'label.add.vpn.customer.gateway'">
            {{ $t(action.label) }}
          </span>
          <!-- /WP Implementation -->
          <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
          <font-awesome-icon v-else :icon='action.icon' />
        </a-button>
      </a-tooltip>
      </template>

      <a-tooltip v-if="$route.path.startsWith('/vm/') && resource && resource.id && dataView" class='row-action-button-switch-status' arrowPointAtCenter placement='bottomRight'>
        <template #title>
          {{ 'VM Starten/Stoppen' }}
        </template>
        <a-switch v-model:checked="vmRunning" @click="toggleVmStatus"/>
      </a-tooltip>
    </span>
    <!-- New ActionButton layout for the actionButtons in the header -->
    <span class='row-action-button' style="justify-content: flex-end;" v-else>
      <a-tooltip class='row-action-button-console' arrowPointAtCenter placement='bottomRight' v-if='resource && resource.id && dataView'>
        <template #title>
          {{ $t('label.view.console') }}
        </template>
        <console :resource='resource' :size='size' />
      </a-tooltip>
      <a-dropdown
        v-if="!$route.path.startsWith('/vpn/') && numberOfActions > 1"
      >
        <span class='row-action-button-dropdown action'>
          ...
        </span>
          <template #overlay>
            <a-menu
              class='user-menu-wrapper'>
              <template  v-for='(action, actionIndex) in actions'>
                <a-menu-item
                  :key='actionIndex'
                  :disabled="'disabled' in action ? action.disabled(resource, $store.getters) : false"
                  @click='execAction(action)'
                  v-if="action.api in $store.getters.apis &&
                    !action.showBadge && !$route.path.startsWith('/vpn/') && (
                      (!dataView && ((action.listView && ('show' in action ? action.show(resource, $store.getters) : true)) || (action.groupAction && selectedRowKeys.length > 0 && ('groupShow' in action ? action.groupShow(selectedItems, $store.getters) : true)))) ||
                      (dataView && action.dataView && ('show' in action ? action.show(resource, $store.getters) : true))
                    )"
                >
                  <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
                    <font-awesome-icon v-else :icon='action.icon' />
                    {{ $t(action.label) }}
                </a-menu-item>
                <a-menu-item
                :key='actionIndex'
                  :disabled="resource.state === 'Running' ? true : false"
                  @click='execAction(action)'
                  v-if="action.api === 'allocateVgpu' && getVgpuInfoResponse === false">
                <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
                    <font-awesome-icon v-else :icon='action.icon' />
                    {{ $t(action.label) }}
                </a-menu-item>
                <a-menu-item
                  :key='actionIndex'
                  :disabled="resource.state === 'Running' ? true : false"
                  @click='execAction(action)'
                  v-if="action.api === 'delocateVgpu' && getVgpuInfoResponse !== false && this.getVgpuInfoResponse !== ''">
                <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
                    <font-awesome-icon v-else :icon='action.icon' />
                    {{ $t(action.label) }}
                </a-menu-item>
                <!-- Action Button Menu for createSaasManager API -->
                <a-menu-item
                  :key='actionIndex'
                  :disabled="saasManagerActive"
                  @click='execAction(action)'
                  v-if="$store.getters.userInfo.roletype === 'Admin' && ['ResellerAdmin'].includes(resource.rolename) && action.api === 'createSaasManager' ">
                  <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
                    <font-awesome-icon v-else :icon='action.icon' />
                    {{ $t(action.label) }}
                </a-menu-item>
              </template>

            </a-menu>
          </template>
      </a-dropdown>
        <a-tooltip class='row-action-button-console' arrowPointAtCenter placement='bottomRight' v-else-if="numberOfActions === 1 && !$route.path.startsWith('/vpn/')">
          <template #title>
            {{ $t(actions[0].label) }}
          </template>
          <span
            :disabled="'disabled' in actions[0] ? actions[0].disabled(resource, $store.getters) : false"
            @click='execAction(actions[0])'
            v-if="actions[0].api in $store.getters.apis &&
              !actions[0].showBadge && !$route.path.startsWith('/vpn/') && (
                (!dataView && ((actions[0].listView && ('show' in actions[0] ? actions[0].show(resource, $store.getters) : true)) || (actions[0].groupAction && selectedRowKeys.length > 0 && ('groupShow' in actions[0] ? action.groupShow(selectedItems, $store.getters) : true)))) ||
                (dataView && actions[0].dataView && ('show' in actions[0] ? actions[0].show(resource, $store.getters) : true))
              )"
          >
            <render-icon v-if="(typeof actions[0].icon === 'string')" :icon='actions[0].icon' />
            <font-awesome-icon v-else :icon='actions[0].icon' />
          </span>
      </a-tooltip>
      <template v-else-if="$route.path.startsWith('/vpn/')">
        <a-tooltip
        v-for='(action, actionIndex) in actions'
        :key='actionIndex'
        arrowPointAtCenter
        placement='bottomRight'>
        <template #title>

          {{ $t(action.label) }}
        </template>
        <a-badge
          class='button-action-badge'
          :overflowCount='9'
          :count='actionBadge[action.api] ? actionBadge[action.api].badgeNum : 0'
          v-if="action.api in $store.getters.apis &&
            action.showBadge && (
              (!dataView && ((action.listView && ('show' in action ? action.show(resource, $store.getters) : true)) || (action.groupAction && selectedRowKeys.length > 0 && ('groupShow' in action ? action.groupShow(selectedItems, $store.getters) : true)))) ||
              (dataView && action.dataView && ('show' in action ? action.show(resource, $store.getters) : true))
            )"
          :disabled="'disabled' in action ? action.disabled(resource, $store.getters) : false">
          <a-button
            :type="(primaryIconList.includes(action.icon) ? 'primary' : 'default')"
            :shape="!dataView && ['PlusOutlined', 'plus-outlined'].includes(action.icon) ? 'round' : 'circle'"
            :danger='dangerIconList.includes(action.icon)'
            style='margin-left: 5px'
            :size='size'
            @click='execAction(action)'>
            <span v-if="!dataView && ['PlusOutlined', 'plus-outlined'].includes(action.icon)">
              {{ $t(action.label) }}
            </span>
            <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
            <font-awesome-icon v-else :icon='action.icon' />
          </a-button>
        </a-badge>
        <a-button
          v-if="action.api in $store.getters.apis &&
            !action.showBadge && !$route.path.startsWith('/vpn/') && (
              (!dataView && ((action.listView && ('show' in action ? action.show(resource, $store.getters) : true)) || (action.groupAction && selectedRowKeys.length > 0 && ('groupShow' in action ? action.groupShow(selectedItems, $store.getters) : true)))) ||
              (dataView && action.dataView && ('show' in action ? action.show(resource, $store.getters) : true))
            )"
          :disabled="'disabled' in action ? action.disabled(resource, $store.getters) : false"
          :type="(primaryIconList.includes(action.icon) ? 'primary' : 'default')"
          :danger='dangerIconList.includes(action.icon)'
          :shape="!dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon) ? 'round' : 'circle'"
          style='margin-left: 5px'
          :size='size'
          @click='execAction(action)'>
          <span
            v-if="!dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon)">
            {{ $t(action.label) }}
          </span>
          <!-- WP Implementation -->
          <span
            v-else-if="dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon) && action.label === 'label.add.vpn.customer.gateway'">
            {{ $t(action.label) }}
          </span>
          <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
          <font-awesome-icon v-else :icon='action.icon' />
        </a-button>
        <!-- This case is only for the "vpn" path! -->
        <a-button
          v-else-if="action.api in $store.getters.apis &&
            !action.showBadge && $route.path.startsWith('/vpn/') && (
              (dataView && !isTableButton && ((action.listView && ('show' in action ? action.show(resource, $store.getters) : true)) || (action.groupAction && selectedRowKeys.length > 0 && ('groupShow' in action ? action.show(resource, $store.getters) : true)))) ||
              (dataView && action.dataView && isTableButton &&('show' in action ? action.show(resource, $store.getters) : true))
            )"
          :disabled='isDisabled'
          :type="(primaryIconList.includes(action.icon) ? 'primary' : 'default')"
          :danger='dangerIconList.includes(action.icon)'
          :shape="dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon) ? 'round' : 'circle'"
          style='margin-left: 5px'
          :size='size'
          @click='execAction(action)'>
          <span
            v-if="!dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon)">
            {{ $t(action.label) }}
          </span>
          <span
            v-else-if="dataView && ['PlusOutlined', 'plus-outlined', 'UserAddOutlined', 'user-add-outlined'].includes(action.icon) && action.label === 'label.add.vpn.customer.gateway'">
            {{ $t(action.label) }}
          </span>
          <!-- /WP Implementation -->
          <render-icon v-if="(typeof action.icon === 'string')" :icon='action.icon' />
          <font-awesome-icon v-else :icon='action.icon' />
        </a-button>
      </a-tooltip>
      </template>

      <a-tooltip v-if="$route.path.startsWith('/vm/') && resource && resource.id && dataView" class='row-action-button-switch-status' arrowPointAtCenter placement='bottomRight'>
        <template #title>
          {{ 'VM Starten/Stoppen' }}
        </template>
        <a-switch v-model:checked="vmRunning" @click="toggleVmStatus"/>
      </a-tooltip>
    </span>
  </a-spin>
</template>

<script>
import { api } from '@/api'
import api2 from '@/wpApi/api2'
import Console from '@/components/widgets/Console'

export default {
  name: 'ActionButton',
  components: {
    Console
  },
  // WP Implementation
  data () {
    return {
      actionBadge: {},
      isDisabled: false,
      saasManagerActive: false
    }
  },
  created () {
    this.checkDisabledButtons()
  },
  // WP Implementation
  mounted () {
    this.handleShowBadge()
  },
  props: {
    actions: {
      type: Array,
      default () {
        return []
      }
    },
    resource: {
      type: Object,
      default () {
        return {}
      }
    },
    dataView: {
      type: Boolean,
      default: false
    },
    selectedRowKeys: {
      type: Array,
      default () {
        return []
      }
    },
    selectedItems: {
      type: Array,
      default () {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    vpnConnectionsAll: {
      type: Array,
      default () {
        return []
      }
    },
    isTableButton: {
      type: Boolean,
      default: false
    },
    forQuickView: {
      type: Boolean,
      required: false,
      default: false
    },
    getVgpuInfoResponse: {
      type: [Boolean, Object],
      required: false,
      default: false
    }
  },
  watch: {
    resource: {
      deep: true,
      handler (newItem, oldItem) {
        if (!newItem || !newItem.id) {
          return
        }
        this.handleShowBadge()
        if (this.$store.getters.userInfo.roletype === 'Admin' && this.$route.path.startsWith('/account/') && ['ResellerAdmin'].includes(this.resource.rolename)) {
          this.checkForSaasManager()
        }
      }
    },
    vpnConnectionsAll (newItem, oldItem) {
      this.checkDisabledButtons()
    }
  },
  computed: {
    primaryIconList () {
      return ['PlusOutlined', 'plus-outlined', 'DeleteOutlined', 'delete-outlined', 'UsergroupDeleteOutlined', 'usergroup-delete-outlined']
    },
    dangerIconList () {
      return ['DeleteOutlined', 'delete-outlined', 'UsergroupDeleteOutlined', 'usergroup-delete-outlined']
    },
    vmRunning () {
      if ('state' in this.resource) {
        return this.resource.state === 'Running'
      }
      return false
    },
    numberOfActions () {
      var nrOfActions = 0
      for (var action of this.actions) {
        if (action.api in this.$store.getters.apis &&
          !action.showBadge && !this.$route.path.startsWith('/vpn/') && (
          (!this.dataView && ((action.listView && ('show' in action ? action.show(this.resource, this.$store.getters) : true)) || (action.groupAction && this.selectedRowKeys.length > 0 && ('groupShow' in action ? action.groupShow(this.selectedItems, this.$store.getters) : true)))) ||
          (this.dataView && action.dataView && ('show' in action ? action.show(this.resource, this.$store.getters) : true))
        )) {
          nrOfActions += 1
        }
      }
      return nrOfActions
    }
  },
  methods: {
    clickLink () {
      document.getElementById('consoleLink' + this.resource.id).click()
    },
    async checkForSaasManager () {
      await api2.checkSaasManager(this.resource.id).then(response => {
        if (response.data === null) {
          this.saasManagerActive = false
        } else { this.saasManagerActive = true }
      }).catch(error => {
        console.log(error)
        this.saasManagerActive = true
      })
    },
    toggleVmStatus (check) {
      this.actions.forEach(function (value) {
        if (value.api === 'stopVirtualMachine' && check === false) {
          this.execAction(value)
        } else if (value.api === 'startVirtualMachine' && check === true) {
          this.execAction(value)
        }
      }.bind(this))
    },
    // WP Implementation
    // check if there is a active VPN connection (disabled "edit VPNCustomerGateay Button") or not (active "edit VPNCustomerGateay Button")
    checkDisabledButtons () {
      const myArray = this.vpnConnectionsAll
      if (myArray.length > 0) {
        for (var conn in myArray) {
          if (myArray[conn].s2scustomergatewayid === this.resource.id) {
            this.isDisabled = true
            return
          }
        }
        this.isDisabled = false
      } else {
        this.isDisabled = false
      }
    },
    // /WP Implementation
    execAction (action) {
      action.resource = this.resource
      if (action.docHelp) {
        action.docHelp = this.$applyDocHelpMappings(action.docHelp)
      }
      this.$emit('exec-action', action)
    },
    handleShowBadge () {
      // this.vmRunning = this.resource.state === 'Running'
      this.actionBadge = {}
      const arrAsync = []
      const actionBadge = this.actions.filter(action => action.showBadge === true)

      if (actionBadge && actionBadge.length > 0) {
        const dataLength = actionBadge.length

        for (let i = 0; i < dataLength; i++) {
          const action = actionBadge[i]

          arrAsync.push(new Promise((resolve, reject) => {
            api(action.api, action.param).then(json => {
              let responseJsonName
              const response = {}

              response.api = action.api
              response.count = 0

              for (const key in json) {
                if (key.includes('response')) {
                  responseJsonName = key
                  break
                }
              }

              if (json[responseJsonName] && json[responseJsonName].count && json[responseJsonName].count > 0) {
                response.count = json[responseJsonName].count
              }

              resolve(response)
            }).catch(error => {
              reject(error)
            })
          }))
        }

        Promise.all(arrAsync).then(response => {
          for (let j = 0; j < response.length; j++) {
            this.actionBadge[response[j].api] = {}
            this.actionBadge[response[j].api].badgeNum = response[j].count
          }
        }).catch(() => {
        })
      }
    }
  }
}
</script>

<style scoped>
.button-action-badge {
  margin-left: 5px;
}

:deep(.button-action-badge) .ant-badge-count {
  right: 10px;
  z-index: 8;
}
</style>
