<template>
  <a-spin :spinning="loading">
    <a-modal
    :visible="showModal"
    :title="$t('label.container.deployment.delete')"
    :maskClosable="true"
    :confirmLoading="loading"
    @cancel="closeAction"
    @ok="deleteK8sDeployment()"
    >
    <a-spin :spinning="loading">
        <p>{{ deleteDeploymentName }}</p>
        <div>{{ $t('message.delete.deployment') }}</div>
    </a-spin>
    </a-modal>
  </a-spin>
</template>

<script>
import api2 from '@/wpApi/api2'

export default {
  name: 'CreateContainerDeployment',
  components: {
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false
    },
    deleteDeploymentId: {
      type: String,
      required: true
    },
    deleteDeploymentName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showEditContainerModal: false,
      loading: false
    }
  },
  methods: {
    closeAction () {
      this.$emit('close-action')
      this.$emit('fetch-data')
    },
    async deleteK8sDeployment () {
      this.loading = true
      var params = new FormData()
      params.append('deploymentId', this.deleteDeploymentId)
      await api2.deleteK8sDeployment(params).then(response => {
        this.showDeleteContainerModal = false
        this.containerId = undefined
        this.containerName = undefined
        this.loading = false
        this.$notification.success({
          message: this.$t('message.success.delete.container')
        })
        this.closeAction()
      }).catch(error => {
        console.log(error)
        this.showDeleteContainerModal = false
        this.loading = false
        this.closeAction()
        this.containerId = undefined
        this.containerName = undefined
        this.$notification.error({
          message: this.$t('message.error.delete.container')
        })
        this.closeAction()
      })
    }
  }
}
</script>
