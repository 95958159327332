<template>
    <a-spin :spinning="loading">
      <div>
        <a-modal
        :visible="showModal"
        :title="$t('label.container.deployment.edit')"
        :maskClosable="false"
        :confirmLoading="loading"
        :loading="loading"
        @ok="handleEditContainerSubmit"
        @cancel="closeAction"
      >
        <!-- Edit Container Deployment -->
        <a-form
          v-ctrl-enter="handleEditContainerSubmit"
          :ref="formRefContainerEdit"
          :model="formContainerEdit"
          :rules="rulesContainerEdit"
          @finish.prevent="handleEditContainerSubmit"
          :loading="loading"
          layout="vertical"
        >
          <a-form-item ref="name" name="name" :label="$t('label.name')">
            <a-input
              :placeholder="$t('label.name.container')"
              v-model:value="formContainerEdit.name">
            </a-input>
          </a-form-item>
          <a-form-item ref="ipaddress" name="ipaddress" :label="$t('label.ipaddress')">
            <a-select
              v-model:value="formContainerEdit.ipaddress"
              :placeholder="$t('label.create.ipaddress.description')">
              <a-select-option v-for="item in freeDeploymentIps" :key="item.address" :value="item.address">
                {{ item.address }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="deployment.licenses[0] !== undefined" ref="licenses" name="licenses" :label="$t('label.licences') + ' (' + (deployment.licenses[0] !== undefined ? deployment.licenses[0].licenseName : '') +')'">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
              style="width:80%"
              :min="this.minLicenses"
              :max="this.maxLicenses"
              v-model:value="formContainerEdit.licenses"
              @change="($event) => updateComputeLicenses($event)"/>
                <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.licenses"
                @change="($event) => updateComputeLicenses($event)"/>
            </div>
          </a-form-item>
          <a-form-item ref="replikaEditValue" name="replikaEditValue" :label="$t('label.replika')">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
                style="width:80%"
                :min="this.minReplika"
                :max="this.maxReplika"
                v-model:value="formContainerEdit.replikaEditValue"
                @change="($event) => updateComputeReplikaEdit($event)"
              />
              <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.replikaEditValue"
                @change="($event) => updateComputeReplikaEdit($event)"
              />
            </div>
          </a-form-item>
          <a-form-item ref="cpuNumberEditValue" name="cpuNumberEditValue" :label="$t('label.cpunumber')">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
                style="width:80%"
                :min="this.minCpu"
                :max="this.maxCpu"
                v-model:value="formContainerEdit.cpuNumberEditValue"
                @change="($event) => updateComputeCpuNumberEdit($event)"
              />
              <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.cpuNumberEditValue"
                @change="($event) => updateComputeCpuNumberEdit($event)"
              />
            </div>
          </a-form-item>
          <a-form-item ref="memoryEditValue" name="memoryEditValue" :label="$t('label.memory.gb')">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
                style="width:80%"
                :min="deployment.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory"
                :max="this.maxMemory"
                v-model:value="formContainerEdit.memoryEditValue"
                @change="($event) => updateComputeMemoryEdit($event)"
              />
              <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.memoryEditValue"
                @change="($event) => updateComputeMemoryEdit($event)"
              />
            </div>
          </a-form-item>
          <a-form-item ref="diskEditValue" name="diskEditValue" :label="$t('label.disk.gb')">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <a-slider
                style="width:80%"
                :min="this.minDisk"
                :max="this.maxDisk"
                v-model:value="formContainerEdit.diskEditValue"
                @change="($event) => updateComputeDiskEdit($event)"
              />
              <a-input-number
                style="width:15%;"
                v-model:value="formContainerEdit.diskEditValue"
                @change="($event) => updateComputeDiskEdit($event)"
              />
            </div>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
    </a-spin>
</template>

<script>

// import { api } from '@/api'
import api2 from '@/wpApi/api2'
import { reactive, ref, toRaw } from 'vue'

export default {
  name: 'EditContainerDeployment',
  components: {
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false
    },
    deployment: {
      type: Object,
      required: true,
      default: () => ({})
    },
    gateway: {
      required: true
    },
    accountUuid: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      freeDeploymentIps: [],
      loading: false,
      pageSize: 20,
      items: [],
      minReplika: 1,
      maxReplika: 5,
      minCpu: 1,
      maxCpu: 20,
      minMemory: 1,
      maxMemory: 64,
      minDisk: 1,
      maxDisk: 1000,
      minLicenses: 1,
      maxLicenses: 200
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    initForm () {
      this.formRefContainerEdit = ref()
      this.formContainerEdit = reactive({
        name: undefined,
        ipaddress: undefined,
        replikaEditValue: 1,
        cpuNumberEditValue: 1,
        licenses: undefined,
        memoryEditValue: 1,
        diskEditValue: 1
      })
      this.rulesContainerEdit = reactive({
        name: [{ required: true, message: this.$t('label.required') }],
        ipaddress: [{ required: true, message: this.$t('label.required') }],
        replikaEditValue: [{ required: true, message: this.$t('label.required') }],
        cpuNumberEditValue: [{ required: true, message: this.$t('label.required') }],
        memoryEditValue: [{ required: true, message: this.$t('label.required') }],
        diskEditValue: [{ required: true, message: this.$t('label.required') }],
        licenses: [{ required: true, message: this.$t('label.required') }]
      })
    },
    async fetchData () {
      await this.fetchDeployment()
      await this.listFreeIps(this.deployment.networkid)
    },
    async fetchDeployment () {
      this.formContainerEdit.ipaddress = this.deployment.ipaddress
      this.formContainerEdit.name = this.deployment.name
      this.formContainerEdit.cpuNumberEditValue = parseInt(this.deployment.cpuLimit)
      this.formContainerEdit.replikaEditValue = parseInt(this.deployment.replicacount)
      this.formContainerEdit.memoryEditValue = parseInt(this.deployment.memLimit)
      this.formContainerEdit.diskEditValue = parseInt(this.deployment.disksize)
      this.formContainerEdit.licenses = parseInt((this.deployment.licenses[0] !== undefined ? this.deployment.licenses[0].count : 1))
    },
    async listFreeIps (networkId) {
      var params = new FormData()
      params.append('networkuuid', networkId)
      await api2.listFreeIps(params).then(response => {
        this.freeDeploymentIps = response.data.available
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    closeAction () {
      this.$emit('close-action')
    },
    updateComputeReplikaEdit (value) {
      if (value === '' || value < this.minReplika) {
        this.formContainerEdit.replikaEditValue = this.minReplika
      } else if (value > this.maxReplika) {
        this.formContainerEdit.replikaEditValue = this.maxReplika
      }
    },
    updateComputeCpuNumberEdit (value) {
      if (value === '' || value < this.minCpu) {
        this.formContainerEdit.cpuNumberEditValue = this.minCpu
      } else if (value > this.maxCpu) {
        this.formContainerEdit.cpuNumberEditValue = this.maxCpu
      }
    },
    updateComputeMemoryEdit (value) {
      if (value === '' || value < (this.deployment.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory)) {
        this.formContainerEdit.memoryEditValue = this.deployment.deploymentpath === 'public/mssql:2022-latest' ? 2 : this.minMemory
      } else if (value > this.maxMemory) {
        this.formContainerEdit.memoryEditValue = this.maxMemory
      }
    },
    updateComputeDiskEdit (value) {
      if (value === '' || value < this.minDisk) {
        this.formContainerEdit.diskEditValue = this.minDisk
      } else if (value > this.maxDisk) {
        this.formContainerEdit.diskEditValue = this.maxDisk
      }
    },
    updateComputeLicenses (value) {
      if (value === '' || value < this.minLicenses) {
        this.formContainerEdit.licenses = this.minLicenses
      } else if (value > this.maxLicenses) {
        this.formContainerEdit.licenses = this.maxLicenses
      }
    },
    setLoading (bool) {
      this.$emit('set-loading', bool)
    },
    handleEditContainerSubmit () {
      this.formRefContainerEdit.value.validate().then(async () => {
        this.loading = true
        this.setLoading(true)
        const values = toRaw(this.formContainerEdit)
        var editContainerParams = new FormData()
        editContainerParams.append('accountId', this.accountUuid)
        editContainerParams.append('deploymentId', this.deployment.deploymentid)
        editContainerParams.append('name', values.name)
        editContainerParams.append('replicaCount', values.replikaEditValue)
        editContainerParams.append('cpuLimit', values.cpuNumberEditValue)
        editContainerParams.append('memLimit', values.memoryEditValue)
        editContainerParams.append('diskSize', values.diskEditValue)
        editContainerParams.append('networkId', this.deployment.networkid)
        editContainerParams.append('ipAddress', values.ipaddress)
        editContainerParams.append('gateway', this.gateway)
        editContainerParams.append('count', this.deployment.licenses[0] !== undefined ? values.licenses : 'null')
        editContainerParams.append('tagId', this.deployment.licenses[0] !== undefined ? this.deployment.licenses[0].licenseId : 'null')
        await api2.updateK8sDeployment(editContainerParams).then(response => {
          this.closeAction()
          this.loading = false
          this.setLoading(false)
          this.$notification.success({
            message: this.$t('message.success.update.container')
          })
          this.$emit('fetch-data')
        }).catch(error => {
          console.log(error)
          this.closeAction()
          this.$notification.error({
            message: this.$t('message.error.update.container')
          })
          this.setLoading(false)
          this.$emit('fetch-data')
        })
      })
    }
  }
}
</script>
