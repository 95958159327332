// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-spin :spinning="loading">
    <div>
      <!-- Modal for the NGF Api -->
      <a-modal
        v-model:visible="showNgfModal"
        :title="ngfBoolean ? $t('label.ngf.on.title') : $t('label.ngf.off.title')"
        :closable="true"
        @cancel="closeNgfModal"
        @ok="submitNgfModal"
        centered>
        <render-icon icon="question-circle-outlined" :style="{fontSize: '24px', color: '#FFA131'}"></render-icon>
        <p>{{ ngfBoolean ? $t('label.ngf.on.content') : $t('label.ngf.off.content') }}</p>
      </a-modal>
      <!-- Modal for the PROXY Api -->
      <a-modal
        v-model:visible="showProxyModal"
        :title="proxyBoolean ? $t('label.proxy.on.title') : $t('label.proxy.off.title')"
        :closable="true"
        :footer="null"
        @cancel="closeProxyModal"
        centered
        destroyOnClose>
        <p>{{ proxyBoolean ? $t('label.proxy.on.content') : $t('label.proxy.off.content') }}</p>
        <a-form
          v-if="proxyBoolean"
          v-ctrl-enter="submitProxyModal"
          :ref="formRef"
          :model="form"
          :rules="rules"
          @finish="submitProxyModal"
          layout="vertical"
        >
          <a-form-item ref="domain" name="domain" :label="$t('label.domain')">
            <a-input
              v-model:value="form.domain"
              autoFocus>
            </a-input>
          </a-form-item>
          <a-form-item ref="proxynetwork" name="proxynetwork" :label="$t('label.network')">
            <a-select
              v-model:value="form.proxynetwork">
              <a-select-option value="172.30.28.0/22">172.30.28.0/22</a-select-option>
              <a-select-option value="10.254.228.0/22">10.254.228.0/22</a-select-option>
              <a-select-option value="192.168.252.0/22">192.168.252.0/22</a-select-option>
            </a-select>
          </a-form-item>
          <div style="text-align: right;">
            <a-button :loading="loading" style="margin-right: 4px" @click="closeProxyModal">{{ this.$t('label.cancel') }}</a-button>
            <a-button :loading="loading" type="primary" @click="submitProxyModal">{{ this.$t('label.ok') }}</a-button>
          </div>
        </a-form>
        <div v-else style="text-align: right;">
          <a-button :loading="loading" style="margin-right: 4px" @click="closeProxyModal">{{ this.$t('label.cancel') }}</a-button>
          <a-button :loading="loading" type="primary" @click="submitProxyModal">{{ this.$t('label.ok') }}</a-button>
        </div>
      </a-modal>
      <!-- Modal for the StartVM Button Api -->
      <a-modal
        v-model:visible="showStartVMModal"
        :closable="true"
        :footer="null"
        @cancel="closeAction"
        centered>
        <startVirtualMachine
          :resource="vmInputresource"
          :fromVpcSettings="true"
          @close-action="closeAction"
          @reloadVpcSettings="fetchData"
        />
      </a-modal>
      <!-- Modal for the DestroyVM Button Api -->
      <a-modal
        v-model:visible="showDestroyVMModal"
        :closable="true"
        :footer="null"
        @cancel="closeAction"
        centered>
        <destroyVM
          :resource="vmInputresource"
          @close-action="closeAction"
        />
      </a-modal>
      <!-- Modal for the AttachIso Button Api -->
      <a-modal
        v-model:visible="showAttachIsoModal"
        :closable="true"
        :footer="null"
        @cancel="closeAction"
        width="20em;"
        centered>
        <attach-iso
          :resource="vmInputresource"
          @close-action="closeAction"
        />
      </a-modal>
      <!-- Confirmation Modal -->
      <a-modal
        style="top: 20px;"
        centered
        :title="$t('label.succeeded') + '!'"
        :closable="false"
        :maskClosable="false"
        v-model:visible="confirmationModal"
      >
        <template #icon>
          <CheckOutlined style="color: green; font-size: 24px;"/>
        </template>
        <p style="margin-top: 20px;"> {{ confirmationMessage }}</p>
        <template #footer style="text-align: right;">
          <a-button id="confirmationOkayButton" type="primary" @click="confirmationModal=false">OK</a-button>
        </template>
      </a-modal>
      <!-- create Kubernetes Deployment Modal -->
      <CreateContainerDeployment
        v-if="showCreateContainerModal"
        :showModal="showCreateContainerModal"
        :createContainerNetwork="deployNetwork"
        :gateway="deployNetworkGateway"
        :accountInfo="{ accUuid: vpcOwnerAccUuid, accName: vpcOwnerAccName }"
        :fromContainerList="false"
        @fetch-data="fetchData"
        @close-action="closeAction"
        @set-loading="setVpcLoading"
        @force-reload="forceReload"/>
    <!-- Edit Container Deployment -->
      <EditContainerDeployment
        v-if="showEditContainerModal"
        :showModal="showEditContainerModal"
        :deployment="updateDeployment"
        :gateway="updateGateway"
        :accountUuid="vpcOwnerAccUuid"
        @close-action="closeAction"
        @fetch-data="fetchData"
        @set-loading="setVpcLoading"/>
    <!-- Delete Container Modal -->
      <DeleteContainerDeployment
        v-if="showDeleteContainerModal"
        :showModal="showDeleteContainerModal"
        :deleteDeploymentId="containerId"
        :deleteDeploymentName="containerName"
        @close-action="closeAction"
        @fetch-data="fetchData"/>
    </div>
    <!-- SVG for the lines connecting networks and router -->
    <!-- <svg class="testSVG" id="mySVG" viewBox="0 50 1 800" preserveAspectRatio="xMinYMin meet">
    </svg> -->
    <!-- <svg class="polyline" viewBox="0 0 50 100" preserveAspectRatio="xMinYMid meet">
      <polyline :points="polylinepoints" style="stroke:#FFA131;stroke-width:1; fill:none" />
    </svg> -->
    <div class='mobile-vpc-message'>
      <span>{{'Bitte verwenden Sie eine höhere Auflösung um die VPC Ansicht darzustellen'}}</span>
    </div>
    <div class="generallContainer" id="rootContainer">
      <!-- Router -->
      <div class="routerContainer" id="routerContainerID">
        <!-- <div id="internetImageContainer" style="display: flex; width:100%; height:5em; justify-content: center; border: 1px solid black;">
        </div> -->
        <div
          id="routerBackgroundElementID"
          class="routerBackgroundElement"
          style="width: 70%;"
          v-for="router in routerData"
          :key="router.id"
          >
          <div v-if="router.vpcid === resource.id">
            <a-tooltip placement="top" :title="'Router CIDR'">
              <a-tag class="routerCIDR" color="blue">{{ resource.cidr }}</a-tag>
            </a-tooltip>
            <h4 class="routerIP">{{ router.publicip }}</h4>
            <div class="firewallStatus" style="position: absolute; right: 21%;width: auto;top: 62%; height: auto;">
              <a-tooltip placement="top">
                <template #title>
                  <span v-if="ngfBoolean">{{ $t('label.ngf.activated') }}</span>
                  <span v-else>{{ $t('label.ngf.deactivated') }}</span>
                </template>
              <img v-if="ngfBoolean" src="../../assets/icons-cloud/firewall-on.png" style='height: 40px' />
              <img v-else src="../../assets/icons-cloud/firewall-off.png" style='height: 40px' />
              </a-tooltip>
            </div>
            <div class="ngfPlaceholder">

              <div>
                <div class="ngfAndProxyContainer">
                  <div class="proxyContainer">
                    <div>
                      <span class="ngfLabel" v-if="proxyBoolean">{{ $t('label.proxy.off') }}</span>
                      <span class="ngfLabel" v-else>{{ $t('label.proxy.on') }}</span>
                      <a-tooltip placement="top">
                        <template #title>
                          {{ $t('label.proxy.tooltip') }}
                        </template>
                        <InfoCircleOutlined style="margin-left: 3px;"/>
                      </a-tooltip>
                    </div>
                    <a-switch class="mySwitch" v-model:checked="proxyBoolean" @click="showProxyModal = true" :size="windowWidth < 1600 ? 'small' : 'default'"/>
                  </div>
                  <div class="ngfContainer">
                    <div>
                      <span class="ngfLabel" v-if="ngfBoolean">{{ $t('label.ngf.off') }}</span>
                      <span class="ngfLabel" v-else>{{ $t('label.ngf.on') }}</span>
                      <a-tooltip placement="top">
                        <template #title>
                          {{ $t('label.ngf.tooltip') }}
                        </template>
                        <InfoCircleOutlined style="margin-left: 3px;"/>
                      </a-tooltip>
                    </div>
                    <a-switch class="mySwitch" v-model:checked="ngfBoolean" @click="showNgfModal = true" :size="windowWidth < 1600 ? 'small' : 'default'"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="routerButtons">
              <div class="singleRouterButtonContainer">
                <router-link class="router-button" :to="{ path: '/vpn/' + resource.id}">
                  <span class="router-button-icon"></span>
                  <span class="router-button-text">{{ $t('label.vpn.settings') }}</span>
                </router-link>
              </div>
              <div class="singleRouterButtonContainer">
                <router-link class="router-button firewall" :to="{ path: '/firewall/' + resource.id}">
                  <span class="router-button-icon"></span>
                  <span class="router-button-text">{{ $t('label.firewall') + ' ' + $t('label.settings') }}</span>
                </router-link>
              </div>
              <div class="singleRouterButtonContainer">
                <router-link v-if="proxyBoolean" class="router-button proxy" :to="{ path: '/proxySettings/' + resource.id + '/' + proxyId + '/' + resource.account}">
                  <span class="router-button-icon"></span>
                  <span class="router-button-text">{{ $t('label.proxy.settings') }}</span>
                </router-link>
              </div>
            </div>
            <div class="router-state running" style="width: 15%" v-if="router.state === 'Running'">
              <div class="router-state-text">{{ router.state || resource.status }}</div>
            </div>
            <div class="router-state stopped" style="width: 15%" v-else-if="router.state === 'Stopped'">
              <div class="router-state-text">{{ router.state || resource.status }}</div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="width: 500px; height: 200px; border: 1px solid black; z-index: 500; background-color: green;">
        <VueSlickCarousel v-bind="carouselParams" ref="carousel">
          <template #prevArrow="arrowOption">
            <div class="custom-arrow" style="display: flex; justify-content: center;">
              <a-button @click="nextInCarousel()">Prev
              {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </a-button>
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-arrow" style="float:right;">
              <a-button @click="nextInCarousel()">Next</a-button>
            </div>
          </template>
          <div><h3>1</h3></div>
          <div><h3>2</h3></div>
          <div><h3>3</h3></div>
          <div><h3>4</h3></div>
          <div><h3>5</h3></div>
          <div><h3>6</h3></div>
          <div><h3>7</h3></div>
          <div><h3>8</h3></div>
          <div><h3>9</h3></div>
          <div><h3>10</h3></div>
          <div><h3>11</h3></div>
          <div><h3>12</h3></div>
        </VueSlickCarousel>
      </div> -->
      <!-- Network -->
      <div class="networkContainer" id="netCon">
        <div
          :id="network.id"
          class="networkElementBackgroundCloud"
          :resource="resource"
          :networkData="networkData"
          :loading="loading"
          v-for="network in networkData.filter(network => network.vpcid === resource.id)"
          :key="network.id"
          >
          <div class='line-left' v-if='filterVmData(network).length > 3'></div>
          <div v-else class='line-left-short'></div>
          <div class='network-element-inner' v-if="network.vpcid === resource.id">
          <!-- Upper cloud image of the Network -->
            <div class="networkInfoAndDeployVMButton" :id="network.id">
              <div class="network-cloud" :id="network.id" style="width: 100%;">

                  <!-- Router link to each Network -->
                  <router-link :to="{ path: '/guestnetwork/' + network.id }" class="elementHeader" >
                    <div class="network-cloud-info">

                      <a-tooltip placement="top" >
                        <template #title>
                          <span>{{ network.name }}</span>
                        </template>
                        <h1 class="network-cloud-name">{{ network.name }}</h1>
                      </a-tooltip>
                      <div class='network-cloud-status'>
                        <status :text="network.state || resource.status"  />
                        <a-tooltip placement="right" :title="'Netzwerk CIDR'">
                          <a-tag class="network-cloud-status-CIDR" color="blue">{{ network.cidr }}</a-tag>
                        </a-tooltip>
                      </div>
                    </div>
                  </router-link>
                <div class="network-cloud-firewall" >
                  <div v-for="acllist in VpcAclLists" :key="acllist.id">
                    <div v-if="acllist.id === network.aclid">
                      <span>
                        <!-- Firewall settings Popover -->
                        <a-popover>
                          <template #content>
                            <span>
                              <span style="font-weight: bold;">{{ acllist.name }}</span>
                              <img style='height: 30px;margin-left: 10px' v-if="acllist.name === 'default_allow'" src="../../assets/icons-cloud/firewall-on.png" />
                              <img style='height: 30px;margin-left: 10px' v-else-if="acllist.name === 'default_deny'" src="../../assets/icons-cloud/firewall-off.png" />
                              <img style='height: 30px;margin-left: 10px' v-else src="../../assets/icons-cloud/firewall-custom.png" />
                              <hr style="color: #69afd8">
                              <span v-if="acllist.name === 'default_allow' || acllist.name === 'default_deny'">
                                <p>{{ $t('label.actual.profile') + ' ' }}<strong>{{ acllist.name }}</strong>{{ ' ' + $t('label.cannot.edit') }}</p>
                                <p>{{ $t('label.create.own.profile') }}</p>
                                <hr style="color: #69afd8">
                                <a-button :loading="loading" @click="createNetworkAclAndReplace(network.name, network.name, resource.id, network.id)">{{ $t('label.create.firewall.profile') }}</a-button>
                              </span>
                              <a-button v-if="acllist.name !== 'default_allow' && acllist.name !== 'default_deny'" @click="$router.push({ path: '/acllist/' + acllist.id})">{{ acllist.name + ' ' + $t('label.edit') }}</a-button>
                            </span>
                          </template>
                          <div class='network-cloud-firewall-icon'>
                            <img  class="notAllowed" v-if="acllist.name === 'default_allow'" src="../../assets/icons-cloud/firewall-on.png" />
                            <img class="notAllowed" v-else-if="acllist.name === 'default_deny'" src="../../assets/icons-cloud/firewall-off.png" />
                            <router-link v-else :to="{ path: '/acllist/' + network.aclid }">
                              <img src="../../assets/icons-cloud/firewall-custom.png" >
                            </router-link>
                          </div>
                        </a-popover>
                      </span>
                    </div>
                  </div>
                </div>
              <!-- <a-tooltip placement="top" :title="'Netzwerk Gateway'">
                  <a-tag color="blue" style="position: absolute; top: 65%; left: -2.9rem; z-index: 8;w">{{ network.gateway }}</a-tag>
                </a-tooltip> -->
              </div>
            </div>
            <!-- Router link to ACL lists + Shield Image of Firewall -->
            <!-- Contains the list of VMs for each Network -->
            <div :id="network.id" class="vmContainer"  v-if="vmData.length > 0">
              <Splide
              :has-track="false"
              :options="splideOptions"
              :extensions="splideExtensions"
              v-if="vmData.length > 0">
                 <!-- <template #nextArrow="arrowOption">
                  <div class="custom-arrow" style="position: relative; width: 10em; top: -18em; left: 100%; z-index: 8;">
                    <a-icon class="carouselNext" type="right-circle" :style="{fontSize: '3em'}" style="margin-right: 0.5rem;"></a-icon>
                    <span style="position: absolute; top: 95%; left: 7%;  font-size: 3em;">{{ arrowOption.currentSlide + 1 }}</span>
                  </div>
                </template>
                <template #prevArrow>
                  <div class="custom-arrow" style="position: absolute; top: 13.5em; left: 100%; z-index: 8;">
                    <a-icon class="carouselPrev" type="left-circle" :style="{fontSize: '3em'}"></a-icon>
                  </div>
                </template>
                <template #customPaging="page">
                  <div class="custom-dot">
                    {{ page }}
                  </div>
                </template> -->
                <!-- Virtual Machines -->
                <template #customPaging="page">
                  <div class="custom-dot">
                    {{ page }}
                  </div>
                </template>
                  <SplideTrack>
                    <SplideSlide v-for="vm in filterVmData(network)" :key="vm.id">
                      <div :class=" (vm.ostypeid || vm.ostypename) ? 'vm-element' : 'vm-element container-element'">
                        <!-- First Row of Content of each vmElement (Status, Name and "More" Icon) -->
                        <div class='vm-element-header'>
                          <div class='vm-element-header-left'>
                            <!-- limit the VM name to 10 characters -->
                            <a-tooltip placement="top">
                              <template #title>
                                <span>{{ 'Name: ' + vm.name }}</span><br/>
                                <span>{{ (vm.ostypeid || vm.ostypename) ? 'Typ: Virtual Machine' : 'Typ: Container' }}</span>
                              </template>
                              <span v-if="vm.ostypeid || vm.ostypename"><DesktopOutlined :style="{fontSize: '20px'}" /><span class="vm-element-header-title">{{ (vm.ostypeid || vm.ostypename) ? 'VM: '+ vm.name : 'CT: '+ vm.name }}</span></span>
                              <span v-else><img src="../../assets/icons/docker-logo-black.png" style="vertical-align: top; height: 20px; width: 25px;" /><span class="vm-element-header-title">{{ (vm.ostypeid || vm.ostypename) ? 'VM: '+ vm.name : 'CT: '+ vm.name }}</span></span>
                            </a-tooltip>
                            <!--VM Status -->
                            <div v-if="vm.state !== undefined">
                              <div class="vm-element-header-state running" v-if="vm.state === 'Running'">
                                <span class="vm-element-header-state-text">{{ vm.state || vm.status }}</span>
                              </div>
                              <div class="vm-element-header-state stopped" v-else-if="vm.state === 'Stopped' || vm.state === 'Error'">
                                <span class="vm-element-header-state-text">{{ vm.state || vm.status }}</span>
                              </div>
                            </div>
                            <!-- Container Status -->
                            <div v-else-if="vm.podsready">
                              <div class="vm-element-header-state stopped" v-if="vm.podsready.split('\u0022')[1] === '0' || vm.podsready.split('\u0022')[1]  === '' || vm.podsready.split('\u0022')[1]  === undefined">
                                <span class="vm-element-header-state-text">{{ '(' + 0 + '/' + vm.replicacount + 'Replikas)' }}</span>
                              </div>
                              <div class="vm-element-header-state stopped" v-else-if="vm.podsready.split('\u0022')[1] < vm.replicacount">
                                <span class="vm-element-header-state-text">{{ '(' + vm.podsready.split('\u0022')[1] + '/' + vm.replicacount + 'Replikas)' }}</span>
                              </div>
                              <div class="vm-element-header-state running" v-else>
                                <span class="vm-element-header-state-text">{{  'Running (' + vm.podsready.split('\u0022')[1] + '/' + vm.replicacount + 'Replikas)'  }}</span>
                              </div>
                            </div>
                          </div>
                          <div class='vm-element-header-right'>
                            <!-- Popover with Buttons for the More Icon in the right top Corn of Each VM -->
                            <a-popover placement="topRight">
                              <template #content>
                                <div v-if="vm.zoneid">
                                  <a-button v-if="vm.state==='Stopped'" @click="pressStartVMBtn(vm)">
                                    <template #icon>
                                      <CaretRightOutlined/>
                                    </template>
                                    {{ $t('label.action.start.instance') }}
                                  </a-button>
                                  <a-button v-if="vm.state==='Running'" @click="handleOpenStopVMModal(vm)">
                                    <template #icon>
                                      <PoweroffOutlined/>
                                    </template>
                                    {{ $t('label.action.stop.instance') }}
                                  </a-button>
                                  <a-button v-if="!vm.isoid" style="margin-left: 1rem;" @click="pressAttachIsoBtn(vm)">
                                    <template #icon>
                                      <PaperClipOutlined/>
                                    </template>
                                    {{ $t('label.action.attach.iso') }}
                                  </a-button>
                                  <a-button v-else style="margin-left: 1rem;" @click="handleOpenDetachIsoModal(vm)">
                                    <template #icon>
                                      <LinkOutlined/>
                                    </template>
                                    {{ $t('label.action.detach.iso') }}
                                  </a-button>
                                  <a-button v-if="proxyClientList.filter(x => x.vmid === vm.id)[0] === undefined || vm.hasTsPlus === false || !proxyBoolean" :disabled="vm.hasTsPlus === false || !proxyBoolean" @click="addProxyClientToVm(vm)" :loading="fetchProxyClientsLoading" style="margin-left: 1rem;">
                                    <template #icon>
                                      <RocketOutlined/>
                                    </template>
                                    {{$t('label.proxy.add.client') }}
                                  </a-button>
                                  <a-button v-else @click="redirectProxyLink(vm)" :loading="fetchProxyClientsLoading" style="margin-left: 1rem;">
                                    <template #icon>
                                      <LinkOutlined/>
                                    </template>
                                    {{ $t('label.proxy.open.client') }}
                                  </a-button>
                                  <a-tooltip placement="right" >
                                    <template title>
                                      <span>{{ $t('label.view.console') }}</span>
                                    </template>
                                    <a
                                      :href="'/client/console?cmd=access&vm=' + vm.id"
                                      target="_blank">
                                      <a-button style="margin-left: 1rem;" shape="circle" type="dashed" :disabled="['Stopped', 'Error', 'Destroyed'].includes(vm.state)" >
                                        <template #icon>
                                          <CodeOutlined />
                                        </template>
                                      </a-button>
                                    </a>
                                  </a-tooltip>
                                </div>
                                <div v-else>
                                  <a-button  @click="handleEditContainerModal(vm, network.gateway)">
                                    <template #icon>
                                      <SettingOutlined />
                                    </template>
                                    {{ $t('label.action.edit.instance') }}
                                  </a-button>
                                  <a-button  @click="handleDeleteContainerModal(vm.deploymentid, vm.name)">
                                    <template #icon>
                                      <DeleteOutlined />
                                    </template>
                                    {{ $t('label.action.destroy.instance') }}
                                  </a-button>
                                </div>
                              </template>
                              <!-- Popover appears when hovering over this Icon - VM Case -->
                              <div v-if="vm.zoneid" class="vm-element-header-more" @click="$router.push({ path: '/vm/' + vm.id })">
                                <img src="../../assets/icons-cloud/cog.png" >
                              </div>
                              <!-- Container Case -->
                              <div class="vm-element-header-more" v-else>
                                <img src="../../assets/icons-cloud/cog.png" @click="$router.push({ path: '/Deployments' })">
                              </div>
                            </a-popover>
                          </div>
                        </div>
                        <!-- VM Case -->
                        <div class='vm-element-os' v-if="vm.zoneid">
                          <router-link :to="{ path: '/vm/' + vm.id }">
                            <div class="vm-element-os-icon" v-if="!vm.templatename.includes('WindowsServer')">
                              <div class="vm-element-os-icon-logo">
                                <os-logo
                                  v-if="vm.ostypeid || vm.ostypename"
                                  :osId="vm.ostypeid"
                                  :osName="vm.ostypename"
                                  size="2x"
                                  :style="{ color: 'white'}"/>
                              </div>
                            </div>
                            <div class="vm-element-os-icon serverOff" v-else-if="vm.templatename.includes('WindowsServer') && vm.state === (vm.state === 'Stopped' ? 'Stopped' : 'Error')"></div>
                            <div class="vm-element-os-icon server" v-else-if="vm.templatename.includes('WindowsServer')"></div>
                            <div class="vm-element-os-icon off" v-else></div>
                          </router-link>
                          <div class="vm-element-os-name">
                            <p class='vm-element-os-name-text'>{{ 'OS: ' + vm.osdisplayname }}</p>
                            <p class='vm-element-os-ip'>{{ vm.nic[0].ipaddress }}</p>
                          </div>
                        </div>
                        <!-- Container Case -->
                        <div v-else class="vm-element-os">
                          <div class="vm-element-os-icon">
                            <div class="vm-element-os-icon-logo">
                              <img v-if="templateImageInfo.filter(x => x.file_name === vm.info.logo)[0] === undefined" src="../../assets/icons/docker-logo-black.png" style="height: 40px; width: 50px;"/>
                              <img v-else :src="'data:image/png;base64, ' + templateImageInfo.filter(x => x.file_name === vm.info.logo)[0].Content" style="height: 40px; width: 40px;"/>
                            </div>
                          </div>
                          <div class="vm-element-os-name">
                            <p class='vm-element-os-name-text'>{{ 'Template: ' + vm.deploymentpath.split("/")[1] }}</p>
                            <p class='vm-element-os-ip'>{{ vm.ipaddress }}</p>
                          </div>
                        </div>
                        <!-- VM Case third row (resource Info) -->
                        <div v-if="vm.zoneid" class='vm-element-info'>
                          <div class='vm-element-info-item'>
                            <img src="../../assets/icons-cloud/cpu.png" >
                            <div class='vm-element-info-item-text'>
                              {{ vm.cpunumber }}<br>
                              <span class='vm-element-info-item-text-small'>{{ 'CPU ' }}</span>
                            </div>
                          </div>
                          <div class='vm-element-info-item'>
                            <img src="../../assets/icons-cloud/ram.png" >
                            <div class='vm-element-info-item-text'>
                              {{ vm.memory < 1024 ? vm.memory + 'MB' : parseFloat((vm.memory / 1024).toFixed(2)) + ' GB' }}<br>
                              <span class='vm-element-info-item-text-small'>{{ 'Speicher ' }}</span>
                            </div>
                          </div>
                          <div class='vm-element-info-item'>
                            <img src="../../assets/icons-cloud/disk.png" >
                            <div class='vm-element-info-item-text'>
                              <Popover placement="bottomRight">
                                <template #content>
                                  <a-table
                                    :columns="volumeDetailsColumns"
                                    :rowKey="record => record.id"
                                    :data-source="volumesList.filter(x => x.virtualmachineid === vm.id)"
                                    :pagination="false"
                                    size="small">
                                  </a-table>
                                </template>
                                {{ vm.volumeSizeSummed !== undefined ? parseFloat(vm.volumeSizeSummed.toFixed(2)) + ' GB' : 0 + ' GB' }}<br>
                                <span class='vm-element-info-item-text-small'>{{ 'Disk Storage ' }}</span>
                              </Popover>
                            </div>
                          </div>
                        </div>
                        <!-- Container Case third row (resource Info) -->
                        <div class='vm-element-info' v-else>
                          <div class='vm-element-info-item'>
                            <img src="../../assets/icons-cloud/cpu.png" >
                            <div class='vm-element-info-item-text'>
                              {{ vm.cpuLimit }}<br>
                              <span class='vm-element-info-item-text-small'>{{ 'CPU ' }}</span>
                            </div>
                          </div>
                          <div class='vm-element-info-item'>
                            <img src="../../assets/icons-cloud/ram.png" >
                            <div class='vm-element-info-item-text'>
                              {{ vm.memLimit + ' GB' }}<br>
                              <span class='vm-element-info-item-text-small'>{{ 'Speicher ' }}</span>
                            </div>
                          </div>
                          <div class='vm-element-info-item'>
                            <img src="../../assets/icons-cloud/disk.png" >
                            <div class='vm-element-info-item-text'>
                              {{ vm.disksize !== undefined ? vm.disksize + ' GB' : 0 + ' GB' }}<br>
                              <span class='vm-element-info-item-text-small'>{{ 'Disk Storage ' }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SplideSlide>
                  </SplideTrack>
                </Splide>
            </div>
            <!-- ========================================================================================= -->
            <!-- lower cloud image of a network -->
            <div :id="network.id">
              <a-card class="deployButtons" :id="network.displaytext">
                <div style="display: flex; height: 100px;">
                  <div class="deployVmButton" @click="handleOpenCreateVmModal(network)"><DesktopOutlined :style="{fontSize: '40px', color: 'white'}" /><span style="color: white">Virtual Machine</span></div>
                  <div class="deployContainerButton" @click="this.handleOpenCreateContainerModal(network)"><img src="../../assets/icons/docker-logo-white.png" style='height: 40px' /><span style="color: white">Container</span></div>
                </div>
              </a-card>
            </div>
            <div class='deploy-vm-button' @click="deployButtonClick(network)">
              <div class='deploy-vm-button-container'>
                <i class='cloud-icon-plus-circle is--blue'></i> {{ $t('label.create') }}
              </div>
            </div>
          </div>
        </div>
        <div class="addNetworkButton">
          <a-button
            class='red-btn'
            style="width: auto;"
            :disabled="!('createNetwork' in $store.getters.apis)"
            @click="handleOpenAddNetworkModal">
            <template #icon>
              <PlusOutlined/>
            </template>{{ $t('label.add.network') }}
          </a-button>
          <!-- <svg class="networkButtonLine"></svg> -->
        </div>
      </div>
    </div>
    <!-- Create Network Modal -->
    <a-modal
      v-model:visible="showCreateNetworkModal"
      :title="$t('label.add.new.tier')"
      :maskClosable="false"
      @ok="handleAddNetworkSubmit">
      <a-spin :spinning="modalLoading">
        <a-form
          v-ctrl-enter="handleAddNetworkSubmit"
          :ref="formRef2"
          :model="form2"
          :rules="rules2"
          @finish.prevent="handleAddNetworkSubmit"
          layout="vertical"
        >
          <a-form-item ref="name" name="name" :label="$t('label.name')">
            <a-input
              :placeholder="$t('label.unique.name.tier')"
              v-model:value="form2.name">
            </a-input>
          </a-form-item>
          <a-form-item ref="networkOffering" name="networkOffering" :label="$t('label.networkofferingid')">
            <a-select
              v-model:value="form2.networkOffering"
              :placeholder="$t('label.create.tier.networkofferingid.description')">
              <a-select-option v-for="item in networkOfferings" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item ref="gateway" name="gateway" :label="$t('label.gateway')">
            <a-input
              :placeholder="$t('label.create.network.gateway.description')"
              v-model:value="form2.gateway">
            </a-input>
          </a-form-item>
          <a-form-item ref="netmask" name="netmask" :label="$t('label.netmask') + ' (format: 255.255.255.0)'">
            <a-input
              :placeholder="$t('label.create.network.netmask.description')"
              v-model:value="form2.netmask">
            </a-input>
          </a-form-item>
          <!-- <a-form-item :label="$t('label.externalid')">
            <a-input
              v-decorator="['externalId']"></a-input>
          </a-form-item> -->
          <a-form-item ref="acl" name="acl" :label="$t('label.aclid')">
            <a-select v-model:value="form2.acl">
              <a-select-option v-for="item in vpcAclListsFiltered" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
              <a-select-option :value="'createNew'"> {{ 'Create new ACL List for this Network' }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
    <!-- stopVmModal -->
    <a-modal
      v-model:visible="showStopVmModal"
      :maskClosable="false"
      :loading="loading"
      centered>
      <template #title>
        {{ $t('label.action.stop.instance') }}
        <a
          style="margin-left: 5px"
          :href="$config.docBase + '/' + ('adminguide/virtual_machines.html#stopping-and-starting-vms' || $route.meta.docHelp)"
          target="_blank">
          <render-icon icon="question-circle-outlined"></render-icon>
        </a>
      </template>
      <template #footer style="text-align: right;">
        <a-button :loading="loading" style="margin-right: 4px" @click="closeAction">{{ this.$t('label.cancel') }}</a-button>
        <a-button :loading="loading" type="primary" @click="handleStopVmSubmit">{{ this.$t('label.ok') }}</a-button>
      </template>
      <a-spin :spinning="modalLoading">
        <span>
          <a-alert type="warning">
            <template #message>{{ $t('message.action.stop.instance') }}</template>
          </a-alert>
          <br>
        </span>
        <a-form :form="form" :loading="loading" layout="vertical">
          <a-form-item>
            <template #label>
              {{ $t('label.forced') }}
              <a-tooltip :title="$t('label.force.description')">
                <render-icon icon="info-circle-outlined" style="color: rgba(0,0,0,.45)" />
              </a-tooltip>
            </template>
            <span>
              <a-switch v-model:checked="switchIsChecked"></a-switch>
            </span>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
    <!-- DetachIso Modal -->
    <a-modal
      v-model:visible="showDetachIsoModal"
      :maskClosable="false"
      :loading="loading"
      centered>
      <template #title>
        {{ $t('label.action.detach.iso') }}
        <a
          style="margin-left: 0.2rem;"
          :href="$config.docBase + '/' + ('adminguide/virtual_machines.html' || $route.meta.docHelp)"
          target="_blank">
          <render-icon icon="question-circle-outlined"></render-icon>
        </a>
      </template>
      <template #footer style="text-align: right;">
        <a-button :loading="loading" style="margin-right: 0.5rem;" @click="closeAction">{{ this.$t('label.cancel') }}</a-button>
        <a-button :loading="loading" type="primary" @click="handleDetachIsoSubmit">{{ this.$t('label.ok') }}</a-button>
      </template>
      <a-spin :spinning="modalLoading">
        <span>
          <a-alert type="warning">
            <template #message v-html="$t('message.detach.iso.confirm')" />
          </a-alert>
          <br>
        </span>
      </a-spin>
    </a-modal>
  </a-spin>
</template>

<script>
import { api } from '@/api'
import { mixinDevice } from '@/utils/mixin.js'
import Status from '@/components/widgets/Status'
import eventBus from '@/config/eventBus'
import $ from 'jquery'
import { reactive, ref, toRaw } from 'vue'
import OsLogo from '@/components/widgets/OsLogo'
import { Popover } from 'ant-design-vue'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import { Grid } from '@splidejs/splide-extension-grid'
import '@splidejs/vue-splide/dist/css/themes/splide-sea-green.min.css'
// import VueSlickCarousel from 'vue-slick-carousel'
// import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// import VpcStats from '@/components/view/VpcStats'
import startVirtualMachine from '@/views/compute/StartVirtualMachine.vue'
import EditContainerDeployment from '@/views/compute/EditContainerDeployment.vue'
import CreateContainerDeployment from '@/views/compute/CreateContainerDeployment.vue'
import DeleteContainerDeployment from '@/views/compute/DeleteContainerDeployment.vue'
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import DestroyVM from '../../views/compute/DestroyVM.vue'
import AttachIso from '../../views/compute/AttachIso.vue'
import wpapi from '@/wpApi/myApi'
import api2 from '@/wpApi/api2'
import { triggerWindowResizeEvent } from '@/utils/util'
/**
 * This Component serves as a sum up page for all important VPC settings (VPN, Firewall, VM Settings and Networks)
 * @vue-prop {Object} resource - resource of the page, in this case VPC
 * @vue-data {String} accountName - accountname of the logged in account
 * @vue-data {Boolean} ngfBoolean - is a ngf active or not?
 * @vue-data {Boolean} loading - manages the loading state of the component
 * @vue-computed {String} message
 */
export default {
  setup () {
    const clicked = ref(false)

    const hide = () => {
      clicked.value = false
    }
    const handleClickChange = visible => {
      clicked.value = true
    }
    return {
      clicked,
      hide,
      handleClickChange
    }
  },
  name: 'VpcSettings',
  components: {
    Status,
    OsLogo,
    startVirtualMachine,
    DestroyVM,
    AttachIso,
    Popover,
    Splide,
    SplideSlide,
    SplideTrack,
    Grid,
    eventBus,
    EditContainerDeployment,
    CreateContainerDeployment,
    DeleteContainerDeployment
  },
  mixins: [mixinDevice],
  props: {
    resource: {
      type: Object,
      default: () => {}
    },
    refreshCalled: {
      type: Number,
      required: false
    }
  },
  computed: {
    vpcAclListsFiltered () {
      return this.VpcAclLists.filter(item => item.name === 'default_allow' || item.name === 'default_deny')
    }
  },
  inject: ['parentFetchData'],
  data () {
    return {
      vpcOwnerAccUuid: String,
      vpcOwnerAccName: String,
      accountName: this.$store.getters.userInfo.account,
      windowWidth: triggerWindowResizeEvent.outerWidth,
      minReplika: 1,
      maxReplika: 5,
      minCpu: 1,
      maxCpu: 20,
      minMemory: 1,
      maxMemory: 64,
      minDisk: 1,
      maxDisk: 1000,
      containerId: undefined,
      containerName: undefined,
      ngfBoolean: false,
      proxyBoolean: false,
      proxyDomain: undefined,
      volumesList: [],
      proxyId: String,
      proxyClientList: [{
        vmid: '555a493b-61a4-4507-baa7-96bc3a8c34f3'
      }],
      fetchProxyClientsLoading: false,
      // polylinepoints: String,
      loading: false,
      splideKey: 0,
      showStartVMModal: false,
      showDestroyVMModal: false,
      showAttachIsoModal: false,
      showDetachIsoModal: false,
      showCreateWorkloadModal: false,
      showNgfModal: false,
      showProxyModal: false,
      regex: /^[a-zA-Z0-9.-]*$/,
      ipRegex: /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\/[0-9]{1,5}$/,
      k8sTemplates: [],
      k8sDeployments: [],
      freeDeploymentIps: [],
      deployNetwork: undefined,
      deployNetworkGateway: undefined,
      updateContainerDeploymentId: undefined,
      templateImageInfo: [],
      updateGateway: undefined,
      updateDeployment: {},
      confirmationModal: false,
      confirmationMessage: '',
      createNetworkPromisRejected: false,
      splideExtensions: { Grid },
      splideOptions: {
        perPage: 1,
        perMove: 1,
        width: '100%',
        grid: {
          rows: 2,
          cols: 3,
          gap: {
            row: '1rem',
            col: '1rem'
          }
        },
        breakpoints: {
          1000: {
            width: '100%',
            grid: {
              rows: 1,
              cols: 1,
              gap: {
                row: '1rem',
                col: '1rem'
              }
            }
          },
          1100: {
            width: '100%',
            grid: {
              rows: 1,
              cols: 1,
              gap: {
                row: '1rem'
              }
            }
          },
          1170: {
            grid: {
              rows: 1,
              cols: 1,
              gap: {
                row: '1rem',
                col: '0.2rem'
              }
            }
          },
          1250: {
            grid: {
              rows: 1,
              cols: 1,
              gap: {
                row: '1rem',
                col: '0.2rem'
              }
            }
          },
          1367: {
            grid: {
              rows: 1,
              cols: 2,
              gap: {
                row: '1rem',
                col: '0.2rem'
              }
            }
          },
          1490: {
            grid: {
              rows: 1,
              cols: 2,
              gap: {
                row: '1rem',
                col: '2rem'
              }
            }
          },
          1620: {
            grid: {
              rows: 1,
              cols: 2,
              gap: {
                row: '1rem',
                col: '1rem'
              }
            }
          },
          1650: {
            grid: {
              rows: 2,
              cols: 3,
              gap: {
                row: '1rem',
                col: '1rem'
              }
            }
          },
          1930: {
            grid: {
              rows: 2,
              cols: 3,
              gap: {
                row: '1rem',
                col: '1rem'
              }
            }
          },
          2100: {
            grid: {
              rows: 2,
              cols: 3,
              gap: {
                row: '1rem',
                col: '1rem'
              }
            }
          },
          2300: {
            grid: {
              rows: 2,
              cols: 3,
              gap: {
                row: '1rem',
                col: '1rem'
              }
            }
          }
        }
      },
      carouselParams: { responsive: [{ breakpoint: 4000, settings: { slidesToShow: 1, rows: 2, slidesPerRow: 4, speed: 400 } }, { breakpoint: 2380, settings: { slidesToShow: 1, rows: 2, slidesPerRow: 3, speed: 400 } }, { breakpoint: 1792, settings: { slidesToShow: 1, rows: 2, slidesPerRow: 2, speed: 400 } }, { breakpoint: 1215, settings: { slidesToShow: 1, rows: 2, slidesPerRow: 1, speed: 400 } }] },
      vmInputresource: [],
      showCreateNetworkModal: false,
      showStopVmModal: false,
      showCreateContainerModal: false,
      showEditContainerModal: false,
      showDeleteContainerModal: false,
      apiInputVm: '',
      modalLoading: false,
      switchIsChecked: false,
      networkOfferings: [],
      VpcAclLists: [],
      createdACL: [],
      createdNetworkID: String,
      networkAclId: [],
      dataSource: [],
      routerData: [],
      networkData: [],
      vmData: [],
      volumesForStats: [],
      publicIpAdresses: [],
      vpnConnectionsCols: [
        {
          title: this.$t('label.name'),
          dataIndex: 'name',
          slots: { customRender: 'name' }
        },
        {
          title: this.$t('label.ip'),
          dataIndex: 'publicip',
          slots: { customRender: 'publicip' }
        },
        {
          title: 'VPN Connections',
          dataIndex: 'state',
          slots: { customRender: 'state' }
        },
        {
          title: this.$t('label.gateway'),
          dataIndex: 'gateway'
        },
        {
          title: this.$t('label.cidrlist'),
          dataIndex: 'cidrlist'
        },
        {
          title: 'Activate/Deactivate',
          dataIndex: 'activate',
          slots: { customRender: 'activate' }
        }
      ],
      volumeDetailsColumns: [
        {
          title: this.$t('label.name'),
          dataIndex: 'name'
        },
        {
          title: this.$t('label.disksize'),
          dataIndex: 'size',
          align: 'center'
        },
        {
          title: this.$t('label.diskoffering'),
          dataIndex: 'diskofferingname',
          align: 'center'
        }
      ]
    }
  },
  watch: {
    refreshCalled (newData) {
      if (newData && this.resource.id) {
        this.fetchData(false)
      }
    }
  },
  async created () {
    this.initForm()
    // this.fetchData()
  },
  async mounted () {
    await this.fetchData()
    await this.fetchNgfStatus()
    eventBus.on('vm-refresh-data', () => {
      this.fetchData(true)
    })
    // window.addEventListener('resize', this.onResize)
  },
  unmounted () {
    window.removeEventListener('resize', this.myEventHandler)
  },
  methods: {
    filterVmData (network) {
      var networkList = []
      for (var vm of this.vmData) {
        if (vm.networkid === network.id) {
          networkList.push(vm)
        } else if (vm.networkid !== network.id && vm.nic.filter(nic => nic.networkid === network.id).length > 0) {
          networkList.push(vm)
        }
      }
      if (this.k8sDeployments.length > 0) {
        for (var deployment of this.k8sDeployments) {
          if (deployment.networkid === network.id) {
            networkList.push(deployment)
          }
        }
      }
      return networkList
    },
    async fetchData (fromEventbus) {
      // this.getImgFilesGit()
      if (fromEventbus) {
        if (Object.keys(this.resource).length === 0 || this.resource.id !== this.$route.params.id) {
          return
        }
      }
      this.loading = true
      await this.getK8sTemplateImages()
      await this.getVpcOwnerAccId()
      await this.fetchRouters()
      await this.fetchNetworks()
      await this.fetchVirtualMachines()
      await this.fetchPublicIps()
      await this.fetchVPCAclLists()
      await this.fetchProxyData()
      if (this.proxyBoolean) {
        await this.fetchProxyClients()
      }
      this.loading = false
      // this.onResize()
    },
    initForm () {
      this.formRef = ref()
      this.formRef2 = ref()
      this.formRefContainer = ref()
      this.form = reactive({
        domain: undefined,
        proxynetwork: undefined
      })
      this.rules = reactive({
        domain: [{ required: true, message: this.$t('label.required') }, { validator: this.validateProxyDomain }],
        proxynetwork: [{ required: true, message: this.$t('label.required') }]
      })
      this.form2 = reactive({
        name: undefined,
        networkOffering: undefined,
        gateway: undefined,
        netmask: undefined,
        acl: undefined
      })
      this.formContainer = reactive({
        name: undefined,
        containerTemplate: undefined,
        ipaddress: undefined,
        replikaInputValue: 1,
        cpuNumberInputValue: 1,
        memoryInputValue: 1,
        diskInputValue: 1
      })
      this.rules2 = reactive({
        name: [{ required: true, message: this.$t('label.required') }],
        networkOffering: [{ required: true, message: this.$t('label.required') }],
        gateway: [{ required: true, message: this.$t('label.required') }],
        netmask: [{ required: true, message: this.$t('label.required') }]
      })
      this.rulesContainer = reactive({
        name: [{ required: true, message: this.$t('label.required') }],
        containerTemplate: [{ required: true, message: this.$t('label.required') }],
        ipaddress: [{ required: true, message: this.$t('label.required') }]
      })
    },
    forceReload () {
      this.$emit('force-reload')
    },
    async getK8sTemplateImages () {
      await api2.getK8sTemplateImages().then(response => {
        this.templateImageInfo = response.data
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    validateProxyDomain (rule, value, callback) {
      const errorMessage = this.$t('message.validate.special.characters.proxyDomain')
      if (this.regex.test(value)) {
        callback()
        return
      }
      callback(errorMessage)
    },
    createConfirmationModal (msg) {
      this.confirmationMessage = msg
      this.confirmationModal = true
    },
    async fetchProxyData () {
      var getProxyparams = new FormData()
      getProxyparams.append('accountid', this.vpcOwnerAccUuid)
      getProxyparams.append('vpcid', this.$route.params.id)
      await api2.getProxySettings(getProxyparams).then(response => {
        response.data.proxyid ? this.proxyBoolean = true : this.proxyBoolean = false
        this.proxyId = response.data.proxyid
        this.proxyDomain = response.data.domain
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async fetchProxyClients () {
      var proxyClientParams = new FormData()
      proxyClientParams.append('accountid', this.vpcOwnerAccUuid)
      proxyClientParams.append('vpcid', this.$route.params.id)
      proxyClientParams.append('proxyid', this.proxyId)
      await api2.getProxyClients(proxyClientParams).then(response => {
        this.proxyClientList = response.data
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async getVpcOwnerAccId () {
      await api('listAccounts', { listall: true, name: this.resource.account }).then(response => {
        var responseAcc = response.listaccountsresponse.account.filter(account => account.name === this.resource.account)[0]
        this.vpcOwnerAccName = responseAcc.name !== undefined ? responseAcc.name : ''
        this.vpcOwnerAccUuid = responseAcc.id !== undefined ? responseAcc.id : ''
      }).catch(error => {
        console.log(error)
      })
    },
    async fetchNgfStatus () {
      await wpapi.getNgfStatus(this.resource.account, this.$route.params.id).then(response => {
        if (response.data.length > 0) {
          if (response.data.filter(x => x.vpcuuid === this.$route.params.id).length > 0) {
            this.ngfBoolean = response.data.filter(x => x.vpcuuid === this.$route.params.id)[0].status
          } else { this.ngfBoolean = false }
        } else { this.ngfBoolean = false }
      }).catch(error => {
        console.log(error)
      })
    },
    closeNgfModal () {
      this.fetchNgfStatus()
      this.showNgfModal = false
    },
    closeProxyModal () {
      this.fetchProxyData()
      this.showProxyModal = false
    },
    async submitNgfModal () {
      var params = new FormData()
      params.append('accountName', this.resource.account)
      params.append('status', this.ngfBoolean)
      params.append('vpcUUID', this.$route.params.id)
      await wpapi.setNgfStatus(params).then(response => {
      }).catch(error => {
        this.$notifyError(error)
      })
      this.showNgfModal = false
    },
    async submitProxyModal () {
      if (this.proxyBoolean) {
        this.formRef.value.validate().then(async () => {
          const values = toRaw(this.form)
          this.loading = true
          var createProxyParams = new FormData()
          createProxyParams.append('subnet', values.proxynetwork)
          createProxyParams.append('domain', values.domain.toLowerCase())
          createProxyParams.append('accountid', this.vpcOwnerAccUuid)
          createProxyParams.append('vpcid', this.$route.params.id)
          await api2.createProxy(createProxyParams).then(response => {
            console.log('Bitte erstellen Sie einen Eintrag für folgende IP-Adresse ', response.data.publicip)
          }).catch(error => {
            this.$notifyError(error)
            this.proxyBoolean = false
            this.showProxyModal = false
            this.loading = false
          })
          this.fetchProxyData()
          this.showProxyModal = false
          this.loading = false
        })
      } else {
        this.loading = true
        var deleteProxyParams = new FormData()
        deleteProxyParams.append('proxyid', this.proxyId)
        await api2.deleteProxy(deleteProxyParams).then(response => {
          if (response.data.status === 'ok') {
            this.showProxyModal = false
            this.loading = false
            this.fetchProxyData()
          } else { this.fetchProxyData() }
        }).catch(error => {
          this.$notifyError(error)
          this.fetchProxyData()
          this.showProxyModal = false
          this.loading = false
        })
      }
    },
    /**
     * Fetch all ACL lists connected with the current VPC via the "listNetworkACLLists" API.
     * @param {Boolean} isModal Boolean that determines if this function is called while a modal is active or not. [Default = false]
     */
    async fetchVPCAclLists (isModal = false) {
      if (isModal) {
        this.modalLoading = true
      }
      api('listNetworkACLLists', { vpcid: this.$route.params.id }).then(json => {
        this.VpcAclLists = json.listnetworkacllistsresponse.networkacllist || []
        if (isModal) {
          this.$nextTick(function () {
            this.form2.acl = this.VpcAclLists[0].id
          })
        }
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        if (isModal) {
          this.modalLoading = false
        }
      })
    },
    /**
     * Fetch all Public IP's connected with the current VPC via the "listPublicIpAddresses" API.
     * @public
     */
    async fetchPublicIps () {
      if (!this.publicIpAdresses || this.publicIpAdresses.length === 0) {
        this.publicIpAdresses = []
      }
      this.publicIpAdresses = await api('listPublicIpAddresses', { vpcid: this.$route.params.id })
        .then(json => {
          this.publicIpAdresses = json.listpublicipaddressesresponse.publicipaddress
        })
        .catch(error => {
          this.$notifyError(error)
        })
        .finally(() => {})
    },
    /**
     * Fetch all Network Offerings connected with the current VPC via the "listNetworkOfferings" API.
     * @public
     */
    fetchNetworkOfferings () {
      this.modalLoading = true
      api('listNetworkOfferings', {
        forvpc: true,
        guestiptype: 'Isolated',
        supportedServices: 'SourceNat',
        state: 'Enabled'
      }).then(json => {
        this.networkOfferings = json.listnetworkofferingsresponse.networkoffering || []
        this.$nextTick(function () {
          this.form2.networkOffering = this.networkOfferings[0].id
        })
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.modalLoading = false
      })
    },
    /**
     * Fetch all virtual Routers connected with the current VPC via the "listRouters" API.
     * @public
     */
    async fetchRouters () {
      await api('listRouters', { listall: true })
        .then(async json => {
          this.routerData = await json.listroutersresponse.router.filter(router => router.vpcid === this.$route.params.id)
          if (!this.routerData || this.routerData.length === 0) {
            this.routerData = []
          }
        })
        .catch(error => {
          this.$notifyError(error)
        })
        .finally(() => {
        })
    },
    /**
     * Fetch all Networks connected with the current VPC via the "listNetworks" API.
     * @public
     */
    async fetchNetworks () {
      await api('listNetworks', { listall: true })
        .then(json => {
          this.networkData = json.listnetworksresponse.network
          if (!this.networkData || this.networkData.length === 0) {
            this.networkData = []
          }
        })
        .catch(error => {
          this.$notifyError(error)
        })
        .finally(() => {
        })
    },
    /**
     * Fetch all VM's connected with the current VPC via the "listVirtualMachines" API.
     * Then list all volumes for each VM in the response, sum them up for each VM and check which network each VM belongs to (by their nic).
     * @returns {Array} vmData: Final output that contains all VM's belonging to the current VPC with summed up volume sizes and the network ID they belong to.
     * @public
     */
    async fetchVirtualMachines () {
      // fetch Container Deployments for this account to add onto the VM list
      await this.getK8sDeploymentList()
      // list all VMs with this VPC ID
      await api('listVirtualMachines', { account: this.resource.account, domainid: this.resource.domainid })
        .then(async json => {
          const vmResponse = json.listvirtualmachinesresponse.virtualmachine
          if (!vmResponse || vmResponse.length === 0) {
            this.vmData = []
          }
          const newVMData = vmResponse
          // if listVMs API and networkData contains Data already
          if (vmResponse && this.networkData) {
            // for each VM in the Response above:
            // - list all volumes with the VM id and extract the physical size of each volume and create a entry for the summed Volumes in "newVMData"
            // - extract the network id and create a entry for each vm in "newVMData"
            for (var [i, vm] of vmResponse.entries()) {
              await api('listVolumes', {
                virtualmachineid: vm.id,
                account: vm.account,
                domainid: vm.domainid
              })
                .then(json => {
                  const volumeResponse = json.listvolumesresponse.volume
                  this.volumesList.push(...volumeResponse)
                  if (volumeResponse !== undefined) {
                    var summedVolumeSize = 0
                    // sum up volume sizes if one VM has more than one Volume and recalculate them in GB
                    for (const volume in volumeResponse) {
                      summedVolumeSize = summedVolumeSize + (volumeResponse[volume].size / 1024 / 1024 / 1024)
                    }
                    newVMData[i].volumeSizeSummed = summedVolumeSize
                  }
                })
                .catch(error => {
                  // this.$notifyError(error)
                  console.log(error)
                })
              for (const network in this.networkData) {
                // if the VM networkid and the network.id are the same, write them into each VM ("newVMData")
                if (vm.nic[0].networkid === this.networkData[network].id) {
                  newVMData[i].networkid = vm.nic[0].networkid
                }
              }
              await wpapi.getAllTagTextsForVM(vm.id).then(response => {
                if (response.data.length > 0 && response.data.filter(s => s.includes('TSPlus')).length > 0) {
                  vm.hasTsPlus = true
                } else {
                  vm.hasTsPlus = false
                }
              }).catch(error => {
                console.log(error)
              })
            }
            this.vmData = newVMData
          } else {
            this.vmData = []
          }
        })
        .catch(error => {
          console.log(error)
          // this.$notifyError(error)
        })
        .finally(() => {
        })
      for (const volume in this.volumesList) {
        // recalculate disksize values of the volumesList into GB
        var volumeInstance = this.volumesList[volume]
        volumeInstance.size = volumeInstance.size / 1024 / 1024 / 1024
        // set diskofferingname to SSD if volume is Root and its undefined
        if (volumeInstance.diskofferingname === undefined && volumeInstance.type === 'ROOT') {
          volumeInstance.diskofferingname = 'SSD'
        }
      }
    },
    areVmsInThisNetwork (networkID) {
      if (this.vmData.length !== 0) {
        for (var vm in this.vmData) {
          if (this.vmData[vm].networkid === networkID) {
            return true
          }
        }
      } else {
        return false
      }
    },
    deployButtonClick (network) {
      var popoverList = document.getElementsByClassName('deployButtons')
      if (popoverList.length !== 0) {
        for (var openPopover of popoverList) {
          if (openPopover.style.display === 'inline' && openPopover.id !== network.displaytext) {
            openPopover.style.setProperty('display', 'none')
          }
        }
      }
      var openThisPopover = document.getElementById(network.displaytext)
      if (openThisPopover.style.display === 'inline') {
        openThisPopover.style.setProperty('display', 'none')
      } else {
        openThisPopover.style.setProperty('display', 'inline')
      }
    },
    /**
     * Recalculate and create svg coordinates for all orange connection lines and circles in the VPC Overview, even when resizing the browser.
     *
     * @public
     */
    onResize () {
      this.windowWidth = window.outerWidth
      if (this.networkData.length !== 0 && this.routerData.length !== 0) {
        // clear main svg before drawing new lines
        $('#mySVG').empty()
        const svg = document.getElementById('mySVG')
        // calculate exact position of the connectionline of the last network in the vpc
        var connectionLines = $('.cloudConnectionLine').find('[cloudConnectionLineID]').prevObject
        if (connectionLines.length === 0) {
          return
        }
        var elem = connectionLines[connectionLines.length - 1]
        var top = elem.getBoundingClientRect().top
        var bot = elem.getBoundingClientRect().bottom
        var third = top + ((bot - top) / 100 * 63)
        // connection circles at the beginning of the networkConnectionLines
        for (const lines in connectionLines) {
          var line = connectionLines[lines]
          if (line.id === 'cloudConnectionLineID') {
            var linetop = line.getBoundingClientRect().top
            var linebot = line.getBoundingClientRect().bottom
            var lineright = line.getBoundingClientRect().right
            var linethird = linetop + ((linebot - linetop) / 100 * 60)
            const ptt4 = svg.createSVGPoint()
            ptt4.x = lineright
            ptt4.y = linethird
            const svgP4 = ptt4.matrixTransform(svg.getScreenCTM().inverse())
            var circle1 = document.createElementNS('http://www.w3.org/2000/svg', 'circle') // Create a path in SVG's namespace
            const ptt5 = svg.createSVGPoint()
            ptt5.x = lineright - 50
            ptt5.y = linethird
            const svgP5 = ptt5.matrixTransform(svg.getScreenCTM().inverse())
            var circle2 = document.createElementNS('http://www.w3.org/2000/svg', 'circle') // Create a path in SVG's namespace
            circle1.setAttribute('cx', svgP4.x)
            circle1.setAttribute('cy', svgP4.y)
            circle1.setAttribute('r', 5)
            circle1.setAttribute('stroke', '#FFA131')
            circle1.setAttribute('fill', '#FFA131')
            circle2.setAttribute('cx', svgP5.x)
            circle2.setAttribute('cy', svgP5.y)
            circle2.setAttribute('r', 5)
            circle2.setAttribute('stroke', '#FFA131')
            circle2.setAttribute('fill', '#FFA131')
            $('#mySVG').append(circle1)
            $('#mySVG').append(circle2)
          }
        }
        // get Elements (rootContainer for left polyline, RouterContainer for y2 Coordinate and routerbackgroundContainer for x2 and y3 coordinates)
        var leftx = document.getElementById('rootContainer')
        var routerBackgroundContainer = document.getElementById('routerBackgroundElementID')

        // set dom coordinates that define the points where the polyline should be drawn
        var x1 = leftx.getBoundingClientRect().left
        var y2 = routerBackgroundContainer.getBoundingClientRect().bottom
        var routerBackgroundContainerLeft = routerBackgroundContainer.getBoundingClientRect().left
        var routerBackgroundContainerRight = routerBackgroundContainer.getBoundingClientRect().right
        var routerBackgroundContainerTop = routerBackgroundContainer.getBoundingClientRect().top
        var routerBackgroundContainerBottom = routerBackgroundContainer.getBoundingClientRect().bottom

        var x2 = routerBackgroundContainerLeft + ((routerBackgroundContainerRight - routerBackgroundContainerLeft) / 100 * 7)
        var y3 = routerBackgroundContainerTop + ((routerBackgroundContainerBottom - routerBackgroundContainerTop) / 100 * 87)

        // create and define "svg points" that translate the dom coordinates to svg coordinates
        const ptt = svg.createSVGPoint()
        ptt.x = x1
        ptt.y = third
        const svgP = ptt.matrixTransform(svg.getScreenCTM().inverse())
        const ptt1 = svg.createSVGPoint()
        ptt1.x = x1
        ptt1.y = y2
        const svgP1 = ptt1.matrixTransform(svg.getScreenCTM().inverse())
        const ptt2 = svg.createSVGPoint()
        ptt2.x = x2
        ptt2.y = y2
        const svgP2 = ptt2.matrixTransform(svg.getScreenCTM().inverse())
        const ptt3 = svg.createSVGPoint()
        ptt3.x = x2
        ptt3.y = y3
        const svgP3 = ptt3.matrixTransform(svg.getScreenCTM().inverse())

        // create String with svg Coordinates that serve as input for the svg polyline and append them to "mySVG"
        var pointstring = ''
        pointstring = pointstring.concat(String(svgP.x + 1) + ',' + String(svgP.y - 0.4) + ' ')
        pointstring = pointstring.concat(String(svgP1.x + 1) + ',' + String(svgP1.y) + ' ')
        pointstring = pointstring.concat(String(svgP2.x) + ',' + String(svgP2.y) + ' ')
        pointstring = pointstring.concat(String(svgP3.x) + ',' + String(svgP3.y) + ' ')
        var polyline1 = document.createElementNS('http://www.w3.org/2000/svg', 'polyline') // Create a path in SVG's namespace
        var points = pointstring
        // attributes of the polyline
        polyline1.setAttribute('points', points)
        polyline1.setAttribute('stroke', '#FFA131')
        polyline1.setAttribute('stroke-width', '4px')
        polyline1.setAttribute('fill', 'none')
        polyline1.setAttribute('stroke-linejoin', 'round')
        $('#mySVG').append(polyline1)
        // attributes of the circle(s)
        var circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle') // Create a path in SVG's namespace
        circle.setAttribute('cx', svgP3.x)
        circle.setAttribute('cy', svgP3.y + 4)
        circle.setAttribute('r', '5')
        circle.setAttribute('stroke', '#FFA131')
        circle.setAttribute('fill', '#FFA131')
        $('#mySVG').append(circle)
        var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect') // Create a path in SVG's namespace
        rect.setAttribute('x', svgP3.x - 5)
        rect.setAttribute('y', svgP3.y - 1)
        rect.setAttribute('width', '10')
        rect.setAttribute('height', '3')
        rect.setAttribute('stroke', '#f2f2f2')
        rect.setAttribute('fill', '#f2f2f2')
        $('#mySVG').append(rect)
      }
    },
    /**
     * Function to create and Replace an ACL list when clicking on the button in the firewall popover when default_allow or default_deny is active.
     *
     * @param {String} name Network ACL Name
     * @param {String} description Network ACL Description
     * @param {String} vpcId VPC ID which the new ACL belongs to
     * @param {String} networkId Network ID where the ACL list should be replaced
     * @public
     */
    createNetworkAclAndReplace (name, description, vpcId, networkId) {
      this.loading = true
      // create new Network and replace the existing one with the newly created
      this.createNetworkAclList(name, description, vpcId).then(result => {
        this.replaceNetworkAcl(result.createnetworkacllistresponse.id, networkId).then(response => {
          this.$store.dispatch('AddAsyncJob', {
            title: this.$t('label.action.replace.networkacllist'),
            jobid: response.replacenetworkacllistresponse.jobid,
            status: 'progress'
          })
          this.$pollJob({
            jobId: response.replacenetworkacllistresponse.jobid,
            errorMessage: this.$t('message.replace.networkacllist.failed'),
            loadingMessage: `${this.$t('label.action.replace.networkacllist.processing')} ${'ACL_' + name}`,
            successMethod: () => {
              this.fetchData(false)
            },
            catchMessage: this.$t('error.fetching.async.job.result')
          })
        }).catch(error => {
          this.$notifyError(error)
          this.loading = false
        })
      })
    },
    /**
     * Asyncronous function to replace an existing acl list associated with a network.
     *
     * @returns {Promise} aclreplace
     * @param {String} aclId ID of the new ACL list
     * @param {String} networkId Network ID where the ACL list should be replaced
     * @public
     */
    async replaceNetworkAcl (aclId, networkId) {
      const aclreplace = await api('replaceNetworkACLList', {
        aclid: aclId,
        networkid: networkId
      })
      return aclreplace
    },
    closeModal () {
      this.$emit('close-action')
    },
    /**
     * Asyncronous function to create a new ACL list.
     *
     * @returns {Promise} result
     * @param {String} name Name of the new ACL list
     * @param {String} description Description of the new ACL list
     * @param {String} vpcid ID of the VPC, where the new ACL list should belong to
     * @public
     */
    async createNetworkAclList (name, description, vpcid) {
      const result = await api('createNetworkACLList', {
        name: 'ACL_' + name,
        description: 'ACL_' + description,
        vpcid: vpcid
      })
      return result
    },
    redirectProxyLink (vm) {
      if (this.proxyDomain !== undefined) {
        var a = document.createElement('a')
        a.href = 'https://' + this.proxyDomain + '/' + vm.name
        a.setAttribute('target', '_blank')
        a.click()
      } else {
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'There was a Problem generating the Proxy Link'
        })
      }
    },
    // enabling proxy
    async addProxyClientToVm (vm) {
      this.fetchProxyClientsLoading = true
      var params = new FormData()
      params.append('vpcid', this.$route.params.id)
      params.append('accountid', this.vpcOwnerAccUuid)
      params.append('virtualmachineid', vm.id)
      await api2.addProxyClient(params).then(response => {
        if (response.data.status === 'ok') {
          this.createConfirmationModal('VM ' + vm.name + ' wurde zum Proxy hinzugefügt')
          this.fetchProxyClients()
        }
        this.fetchProxyClientsLoading = false
      }).catch(error => {
        this.$notifyError(error)
        this.fetchProxyClientsLoading = false
      })
    },
    // Kubernetes API Calls
    async getK8sTemplateList () {
      var params = new FormData()
      params.append('accountuuid', this.vpcOwnerAccUuid)
      await api2.getK8sTemplateList(params).then(response => {
        this.k8sTemplates = response.data
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async getK8sDeploymentList () {
      var params = new FormData()
      params.append('accountuuid', this.vpcOwnerAccUuid)
      await api2.getK8sDeploymentList(params).then(response => {
        this.k8sDeployments = response.data.deployments === null ? [] : response.data.deployments
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    /**
     * Calls different functions that handle the modal when clicking the "Create new Network" Button
     * @public
     */
    handleOpenAddNetworkModal () {
      this.fetchVPCAclLists(this.isModal)
      this.fetchNetworkOfferings()
      this.showCreateNetworkModal = true
      // set ACL list "default_deny" as default
      this.form2.acl = this.VpcAclLists.find(x => x.name === 'default_deny').id
    },
    /**
     * Calls different functions that handle the "Stop VM modal" when clicking the "Stop VM" Button. this.apiInputVm will be set as the vmObject,
     * which serves as input for the handleStopVmSubmit() function.
     * @param {Object} vmObject VM that should be stopped
     * @public
     */
    handleOpenStopVMModal (vmObject) {
      this.showStopVmModal = true
      this.apiInputVm = vmObject
    },
    handleDeleteContainerModal (deploymentId, deploymentName) {
      this.containerName = deploymentName
      this.containerId = deploymentId.toString()
      this.showDeleteContainerModal = true
    },
    async handleOpenCreateContainerModal (network) {
      this.deployNetwork = network
      this.deployNetworkGateway = network.gateway
      this.deployButtonClick(network)
      /* await this.getK8sTemplateList()
      await this.listFreeIps(networkId) */
      this.showCreateContainerModal = true
    },
    handleOpenCreateVmModal (networkObj) {
      this.deployButtonClick(networkObj)
      this.$router.push({ path: '/action/deployVirtualMachine', query: { networkid: networkObj.id, zoneid: networkObj.zoneid, accname: this.resource.account } })
    },
    async listFreeIps (networkId) {
      var params = new FormData()
      params.append('networkuuid', networkId)
      await api2.listFreeIps(params).then(response => {
        this.freeDeploymentIps = response.data.available
        this.formContainer.ipaddress = this.freeDeploymentIps[0].address
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    /**
     * Handles a submit of the "Stop VM" Modal by calling the according API (stopVirtualMachine).
     * @public
     */
    handleStopVmSubmit () {
      this.loading = true
      this.modalLoading = true
      api('stopVirtualMachine', {
        id: this.apiInputVm.id,
        forced: this.switchIsChecked
      }).then(response => {
        this.$store.dispatch('AddAsyncJob', {
          title: this.$t('label.action.stop.instance'),
          jobid: response.stopvirtualmachineresponse.jobid,
          status: 'progress'
        })
        this.$pollJob({
          jobId: response.stopvirtualmachineresponse.jobid,
          errorMessage: this.$t('message.stop.instance.failed'),
          loadingMessage: `${this.$t('label.action.stop.instance.processing')} ${this.apiInputVm.name}`,
          catchMessage: this.$t('error.fetching.async.job.result'),
          successMethod: () => {
            this.fetchVirtualMachines()
          }
        })
        this.closeAction()
      }).catch(error => {
        this.$notifyError(error)
      }).finally(() => {
        this.fetchData(false)
        this.loading = false
        this.modalLoading = false
      })
    },
    /**
     * Calls different functions that handle the "Detach Iso modal" when clicking the "Detach Iso" Button.
     * this.apiInputVm will be set as the vmObject, which serves as input for the handleDetachIsoSubmit() function.
     * @param {Object} vmObject VM where Iso should be detached
     * @public
     */
    handleOpenDetachIsoModal (vmObject) {
      this.form = this.$form.createForm(this)
      this.showDetachIsoModal = true
      this.apiInputVm = vmObject
    },
    /**
     * Handles a submit of the "Detach Iso" Modal by calling the according API (detachIso).
     * @public
     */
    handleDetachIsoSubmit () {
      this.loading = true
      this.modalLoading = true
      this.formRef.value.validate().then(async () => {
        api('detachIso', {
          virtualmachineid: this.apiInputVm.id
        }).then(response => {
          this.$store.dispatch('AddAsyncJob', {
            title: this.$t('label.action.detach.iso'),
            jobid: response.detachisoresponse.jobid,
            status: 'progress'
          })
          this.$pollJob({
            jobId: response.detachisoresponse.jobid,
            errorMessage: this.$t('message.detach.iso.failedd'),
            loadingMessage: `${this.$t('label.action.detach.iso.processing')} ${this.apiInputVm.name}`,
            catchMessage: this.$t('error.fetching.async.job.result')
          })
          this.closeAction()
        }).catch(error => {
          this.$notifyError(error)
        }).finally(() => {
          this.fetchData(false)
          this.loading = false
          this.modalLoading = false
        })
      })
    },
    /**
     * Handles a submit of the "Add Network" Modal and asigns an existing ACL List OR creates and assignes the created ACL list to the fresh network.
     * This happens only if the option "Create new ACL list for this Network" is selected.
     * @public
     */
    handleAddNetworkSubmit () {
      this.formRef2.value.validate().then(async () => {
        this.loading = true
        const values = toRaw(this.form2)
        /* case if new ACL List should be created */
        if (values.acl === 'createNew') {
          this.showCreateNetworkModal = false
          api('createNetwork', {
            vpcid: this.$route.params.id,
            domainid: this.resource.domainid,
            account: this.resource.account,
            networkOfferingId: values.networkOffering,
            name: values.name,
            displayText: values.name,
            gateway: values.gateway,
            netmask: values.netmask,
            zoneId: this.resource.zoneid,
            externalid: values.externalId
          }).then(createdNetwork => {
            this.createdNetworkID = createdNetwork.createnetworkresponse.network.id
          }).catch(error => {
            this.$notifyError(error)
            this.createNetworkPromisRejected = true
            this.loading = false
          }).finally(() => {
            if (this.createNetworkPromisRejected === false) {
              this.createNetworkAclList(values.name, values.name, this.$route.params.id).then(result => {
                this.replaceNetworkAcl(result.createnetworkacllistresponse.id, this.createdNetworkID).then(response => {
                  this.$notification.success({
                    message: this.$t('message.success.add.network.acllist.replace')
                  })
                })
              })
                .catch(error => {
                  this.$notifyError(error)
                }).finally(() => {
                  this.fetchData(false)
                  this.loading = false
                })
            }
          })
          /* Case if default_allow or default_deny is selected */
        } else {
          this.showCreateNetworkModal = false
          api('createNetwork', {
            vpcid: this.$route.params.id,
            domainid: this.resource.domainid,
            account: this.resource.account,
            networkOfferingId: values.networkOffering,
            name: values.name,
            displayText: values.name,
            gateway: values.gateway,
            netmask: values.netmask,
            zoneId: this.resource.zoneid,
            externalid: values.externalId,
            aclid: values.acl
          }).then(() => {
            this.$notification.success({
              message: this.$t('message.success.add.vpc.network')
            })
          }).catch(error => {
            this.$notifyError(error)
            this.loading = false
          }).finally(() => {
            this.fetchData(false)
            this.loading = false
          })
        }
      })
      this.createdNetworkID = ''
      this.createNetworkPromisRejected = false
    },
    toSize (kb) {
      if (!kb) {
        return '0 KB'
      }
      if (kb < 1024) {
        return kb + ' KB'
      }
      if (kb < 1024 * 1024) {
        return parseFloat(kb / 1024.0).toFixed(2) + ' MB'
      }
      return parseFloat(kb / (1024.0 * 1024.0)).toFixed(2) + ' GB'
    },
    pressStartVMBtn (vmInput) {
      this.showStartVMModal = true
      this.vmInputresource = vmInput
    },
    pressDestroyVMBtn (vmInput) {
      this.showDestroyVMModal = true
      this.vmInputresource = vmInput
    },
    pressAttachIsoBtn (vmInput) {
      this.showAttachIsoModal = true
      this.vmInputresource = vmInput
    },
    /**
     * Close all possible Modals
     * @public
     */
    closeAction () {
      this.showStartVMModal = false
      this.showStopVmModal = false
      this.showDestroyVMModal = false
      this.showAttachIsoModal = false
      this.showDetachIsoModal = false
      this.showDeleteContainerModal = false
      this.showCreateContainerModal = false
      this.showEditContainerModal = false
    },
    updateComputeReplika (value) {
      if (value === '' || value < this.minReplika) {
        this.formContainer.replikaInputValue = this.minReplika
      } else if (value > this.maxReplika) {
        this.formContainer.replikaInputValue = this.maxReplika
      }
    },
    updateComputeCpuNumber (value) {
      if (value === '' || value < this.minCpu) {
        this.formContainer.cpuNumberInputValue = this.minCpu
      } else if (value > this.maxCpu) {
        this.formContainer.cpuNumberInputValue = this.maxCpu
      }
    },
    updateComputeMemory (value) {
      if (value === '' || value < this.minMemory) {
        this.formContainer.memoryInputValue = this.minMemory
      } else if (value > this.maxMemory) {
        this.formContainer.memoryInputValue = this.maxMemory
      }
    },
    updateComputeDisk (value) {
      if (value === '' || value < this.minDisk) {
        this.formContainer.diskInputValue = this.minDisk
      } else if (value > this.maxDisk) {
        this.formContainer.diskInputValue = this.maxDisk
      }
    },
    handleEditContainerModal (vm, networkGateway) {
      // this.listFreeIps(vm.networkid)
      this.updateDeployment = vm
      this.updateGateway = networkGateway
      this.showEditContainerModal = true
      this.containerId = vm.id
    },
    setVpcLoading (loadBoolean) {
      this.loading = loadBoolean
    }
    // function to expand the network cloud(s)
    /*  clickVmOpenIntro (netId) {
      var all = document.getElementsByClassName('networkElementBackgroundCloud')
      var lastEle = all[all.length - 1]
      var theLine = document.getElementById('prolongedLine')
      $(`#${netId}.closeIntro`).show()
      $(`#${netId}.openIntro`).hide()
      $(`#${netId}.vmContainer`).css('overflow', 'none')
      $(`#${netId}.vmContainer`).css('height', 'max-content')
      $(`#${netId}.networkElementBackgroundCloud`).css('height', 'max-content')
      $(`#${netId}.networkElementBackgroundCloud`).css('background-size', '100% 90%')
      $(`#${netId}.vmContainer`).css('min-height', '6.5em')
      if (lastEle.id !== netId) {
        if (document.getElementById('prolongedLine')) {
          var leftx = document.getElementById('internetImageContainer')
          const svg1 = document.getElementById('mySVG')
          const ptt = svg1.createSVGPoint()
          var x2 = leftx.getBoundingClientRect().left + window.pageXOffset
          ptt.x = x2
          ptt.y = 629
          const svgP1 = ptt.matrixTransform(svg1.getScreenCTM().inverse())
          theLine.setAttribute('x1', svgP1.x + 3.5)
          theLine.setAttribute('y1', 629)
          theLine.setAttribute('x2', svgP1.x + 3.5)
          theLine.setAttribute('y2', lastEle.getBoundingClientRect().top + window.pageYOffset - 35)
        }
      }
    }, */
    // function to collapse the network cloud(s)
    /*  clickVmCloseIntro (netId) {
      var all = document.getElementsByClassName('networkElementBackgroundCloud')
      var lastEle = all[all.length - 1]
      var theLine = document.getElementById('prolongedLine')
      $(`#${netId}.closeIntro`).hide()
      $(`#${netId}.openIntro`).show()
      $(`#${netId}.vmContainer`).css('overflow', 'hidden')
      $(`#${netId}.vmContainer`).css('height', '6.5em')
      $(`#${netId}.networkElementBackgroundCloud`).css('height', '18em')
      if (lastEle.id !== netId) {
        theLine.setAttribute('y2', lastEle.getBoundingClientRect().top + window.pageYOffset - 35)
      }
    }, */
  }
}
</script>

<style scoped>
.deployButtons {
  display: none;
  background-color: white;
  position: absolute;
  height: 150px;
  top: 55%;
  left: 36.4%;
  width: 400px;
  z-index: 10000;
  border-radius: 6px;
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
}
.deployVmButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #3D7CAF;
}
.deployVmButton:hover {
  cursor: pointer;
}

.deployContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #3D7CAF;
  margin-left: 10px;
}
.deployContainerButton:hover {
  cursor: pointer;
}
.routerContainer {
position: relative;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

.routerBackgroundElement {
display: flex;
background-image: url("../../assets/icons-cloud/router-hell.png");
background-size: contain;
background-repeat: no-repeat;
background-position: center center;
position: relative;
height: 0;
padding: 0;
padding-bottom: calc(100% * 1 / 3.7);
z-index: 1.5;
}

.routerCIDR {
position: absolute;
left: 2%;
top: 57%;
font-weight: bold;
font-size: 0.8vw;
color: #fff;
z-index: 8;
background: transparent;
border: none;
}

.routerIP {
position: absolute;
left: 76%;
top: 56%;
font-weight: bold;
font-size: 0.8vw;
color: #fff;
}

.ngfPlaceholder {
display: flex;
flex-direction: row;
top: 24.5%;
left: 19.5%;
position: absolute;
width: 16em;
height: 4em;
z-index: 8;
}
.ngfAndProxyContainer {
display: flex;
flex-direction: column;
align-items: center;
position: absolute;
width: 110%;
height: 100%;
z-index: 8;
}
.mySwitch {
    left: -5%;
  }
.ngfContainer {
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
z-index: 8;
width: 100%;
height: 50%;
}

.proxyContainer {
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
height: 50%;
justify-content: space-between;
z-index: 8;
}

.routerButtons {
display: flex;
flex-direction: column;
top: 48.5%;
left: 38%;
position: absolute;
width: 18%;
height: 27%;
z-index: 8;
}

.singleRouterButtonContainer {
display: flex;
justify-content: left;
align-items:center;
width: 110%;
height: 100%;
}

.ngfLabel {
margin-left: 5px;
}
.singleRouterButtonContainer a.router-button {
color: #fff;
display: flex;
justify-content: flex-start;
align-items: center;
align-content: center;
background: #4d4d4d;
border-radius: 16px;
font-size: 0.7vw;
height: 32px;
width: 188px;
padding: 8px;
border: 2px solid #7F7F7F;
border-top: none;
}

.singleRouterButtonContainer .router-button-icon {
height: 1vw;
width: 1vw;
background-image: url("../../assets/icons-cloud/cog.png");
background-size: contain;
background-repeat: no-repeat;
background-position: center center;
margin-right: 10px;
}
@media screen and (min-width: 1620px) {
  .singleRouterButtonContainer a.router-button {
    font-size: 14px;
  }
  .singleRouterButtonContainer .router-button-icon {
    height: 19px;
    width: 19px;
  }
}
.networkContainer {
display: flex;
position: relative;
flex-direction: column;
align-items: left;
width: auto;
height: max-content;
margin: 2rem 0 0 2rem;
}

.network-element-inner {
display: grid;
grid-template-columns: 294px 100%;
}

.container-element {
  /* background-color:rgba(61, 124, 175, 1); */
  /* border: 2px solid rgba(61, 124, 175, 0.8); */

}
.vmContainer {
position: relative;
width: calc(100% - 294px);
height: 90%;
z-index: 7;
padding-left: 1rem;
}

.addNetworkButton {
position: relative;
z-index: 8;
}

.elementHeader {
/* display: flex;
flex-direction: row; */
  z-index: 8;
}

.elementHeader:hover{
  color: black;
}

.off {
  background-image: url("../../assets/icons/monitor_off_big.png");
  z-index: 8;
}

.server {
  background-image: url("../../assets/icons/Server_on_double.png");
  z-index: 8;
}

.serverOff {
  background-image: url("../../assets/icons/Server_off_double.png");
  z-index: 8;
}

.testSVG {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.notAllowed:hover {
  cursor: not-allowed;
}

.ngfPlaceholder {
  font-size: 12px;
  color: #fff;
  top: 60.5%;
  width: 13em;
}

.ngfPlaceholder .ant-switch {
  font-size: 12px;
  height: 14px;
  line-height: 12px;
  min-width: 37px;
}

.ngfPlaceholder  .ant-switch-loading-icon,
.ngfPlaceholder .ant-switch:after {
  height: 13px;
  width: 13px;
  top: -1px;
}
.deploy-vm-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 99%);
  height: 54px;
  width: 350px;
  text-align: center;

  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #3D7CAF;
  z-index: 3;
  background: #B5B5B5;
  border-top: none;
  cursor: pointer;
  z-index: 1000;
}
.deploy-vm-button-container {
  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
  background: #eeeeee;
  width: 348px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: -2px;
  position: relative;

}
.deploy-vm-button-container i {
  margin-right: 8px;
}
@media only screen and (max-width: 998px) {
  .network-element-inner {
    grid-template-columns: 1fr;
  }
  .vmContainer {
    padding-left: 7rem;
  }
}

@media only screen and (max-width: 1200px) {
  .ngfPlaceholder {
    transform: scale(0.8);
    left: 17%;
    width: 15em;
    top: 58%;
  }

  .routerButtons {
    transform: scale(0.7);
    left: 44%;
  }
}

@media only screen and (max-width: 1300px) {

  .ngfAndProxyContainer {
    left: 12%;
  }
  .ngfPlaceholder {
    width: 15em;
  }
}
@media only screen and (max-width: 1599px) {
  .singleRouterButtonContainer a.router-button {
    height: 26px;
  }

  .routerButtons {
    left: 38%;
  }
}
@media only screen and (max-width: 1550px) {
  .ngfPlaceholder {
    left: -10%;
  }
}
@media only screen and (max-width: 1587px) {
  .mySwitch {
    left: -13%;
  }
}

@media only screen and (max-width: 1660px) {
  .ngfPlaceholder {
    left: 20%;
    width: 14em;
  }
  .mySwitch {
    left: -13%;
  }
}

@media only screen and (max-width: 1720px) {
.ngfAndProxyContainer {
    left: -15%;
  }
}
@media only screen and (max-width: 1750px) {
.routerButtons {
  left: 41%;
}
}
@media only screen and (min-width: 1930px) {
  /*.routerContainer {
    max-width: 110em;
    margin-left: 5vw;
  }*/
  .routerIP {
    font-size: 1em;
  }
  .routerButtons h4 {
    font-size: 1.3em;
  }
  .routerCIDR {
    font-size: 0.8em;
  }
}
@media only screen and (max-width: 1600px) {

  .network-cloud {
    width: 294px !important;
    margin: auto;
  }
  .ngfPlaceholder {
    width: 15em;
    top: 58%;
  }
}
</style>
