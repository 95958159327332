// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import { AppDeviceEnquire } from '@/utils/mixin'

export default {
  mixins: [AppDeviceEnquire],
  data () {
    return {
      locale: enUS,
      configs: {}
    }
  },
  created () {
    // window.less.modifyVars(this.$config.theme)
    console.log('config and theme applied')
  }
}
</script>
<style>
#app {
  height: 100%;
}
/* WP Implementation */
@font-face {
  font-family: 'digital-clock';
  font-display: auto;
  font-style: normal;
  src: local('digital-clock'),
      url('./assets/icons/digital-dream.fat-narrow.ttf');
}
/* /WP Implementation */
</style>
