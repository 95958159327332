/* This script was implemented by Moritz Stöcklin, Web + Phone */
<template>
  <div>
    <!-- Moritz Code -->
    <a-modal
      v-model:visible="showModal"
      :title="$t('label.download.invoice')"
      :okText="$t('label.download')"
      @ok="submitDownloadModal"
      @cancel="cancel"
      :destroyOnClose="true">
      <a-spin :spinning="loading" tip="Erstelle Rechnung...">
      <a-form
        v-ctrl-enter="submitDownloadModal"
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="submitDownloadModal"
        layout="vertical"
        class="invoiceSelection"
        >
          <a-form-item ref="year" name="year" :label="$t('label.year')">
            <a-select
              v-model:value="form.year"
              style="width: 14em;"
              size="default"
              @change="resetMonthSelection"
              >
              <a-select-option v-for="year in getCurrentYearList" :key="year">
                {{ year }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item ref="months" name="months" :label="$t('label.month')">
            <a-select
              v-model:value="form.months"
              size="default"
              style="width: 14em;"
              :placeholder="$t('label.month')"
              @change="setMonthSelection"
              >
              <a-select-option value="January" v-if="checkInvoiceStatus('January')" :disabled="checkInvoiceStatus('January') ? false : true">
                Januar
              </a-select-option>
              <a-select-option value="February" v-if="checkInvoiceStatus('February')" :disabled="checkInvoiceStatus('February') ? false : true">
                Februar
              </a-select-option>
              <a-select-option value="March" v-if="checkInvoiceStatus('March')" :disabled="checkInvoiceStatus('March') ? false : true">
                März
              </a-select-option>
              <a-select-option value="April" v-if="checkInvoiceStatus('April')" :disabled="checkInvoiceStatus('April') ? false : true">
                April
              </a-select-option>
              <a-select-option value="May" v-if="checkInvoiceStatus('May')" :disabled="checkInvoiceStatus('May') ? false : true">
                Mai
              </a-select-option>
              <a-select-option value="June" v-if="checkInvoiceStatus('June')" :disabled="checkInvoiceStatus('June') ? false : true">
                Juni
              </a-select-option>
              <a-select-option value="July" v-if="checkInvoiceStatus('July')" :disabled="checkInvoiceStatus('July') ? false : true">
                Juli
              </a-select-option>
              <a-select-option value="August" v-if="checkInvoiceStatus('August')" :disabled="checkInvoiceStatus('August') ? false : true">
                August
              </a-select-option>
              <a-select-option value="September" v-if="checkInvoiceStatus('September')" :disabled="checkInvoiceStatus('September') ? false : true">
                September
              </a-select-option>
              <a-select-option value="October" v-if="checkInvoiceStatus('October')" :disabled="checkInvoiceStatus('October') ? false : true">
                Oktober
              </a-select-option>
              <a-select-option value="November" v-if="checkInvoiceStatus('November')" :disabled="checkInvoiceStatus('November') ? false : true">
                November
              </a-select-option>
              <a-select-option value="December" v-if="checkInvoiceStatus('December')" :disabled="checkInvoiceStatus('December') ? false : true">
                Dezember
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
    <a-tooltip placement="right">
      <template #title v-if="collapsed">
        {{ $t('label.download.invoice') }}
      </template>
      <div :onclick="openModal" class='button-invoice-inner'>
        <DollarOutlined v-if="collapsed" style="padding-left: 8px;" class="invoiceIcon" type="dollar" @click="openModal"></DollarOutlined>
        <DollarOutlined v-else class="invoiceIcon" type="dollar" @click="openModal"></DollarOutlined>
        <a-button v-if="!collapsed" @click="openModal" class='invoice-download-button'>{{ $t('label.download.invoice') }}</a-button>
      </div>
    </a-tooltip>
  </div>
</template>

<script>
import wpapi from '@/wpApi/myApi'
import { reactive, ref, toRaw } from 'vue'
import { DollarOutlined } from '@ant-design/icons-vue'

export default {
  name: 'DownloadInvoices',
  components: {
    DollarOutlined
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    getCurrentMonth: function () {
      var d = new Date()
      return this.months[d.getMonth()]
    },
    getMonthBeforeCurrent: function () {
      var d = new Date()
      return this.months[d.getMonth() - 1]
    },
    getCurrentYear: function () {
      var d = new Date()
      return d.getFullYear()
    },
    getCurrentYearList: function () {
      // get current year and last year as a list
      var currentYear = this.getCurrentYear
      return [currentYear, currentYear - 1]
    }
  },
  data () {
    return {
      invoiceStatusInfo: [],
      showModal: false,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      loading: false,
      selectedYear: undefined,
      selectedMonth: undefined,
      accountUUID: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchAccountInvoiceStatuses()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        year: this.getCurrentYear || undefined,
        months: undefined
      })
      this.rules = reactive({
        year: [{ required: true, message: this.$t('label.required') }],
        months: [{ required: true, message: this.$t('label.required') }]
      })
    },
    async fetchAccountInvoiceStatuses () {
      await wpapi.getInvoiceStatusAccounts(this.accountUUID).then(response => {
        // JSON responses are automatically parsed.
        // filter api response for current month data only!
        this.invoiceStatusInfo = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    setMonthSelection (month) {
      this.selectedMonth = month
    },
    resetMonthSelection (value) {
      console.log(value)
      this.selectedMonth = undefined
      this.form.months = undefined
      // this.form.setFieldsValue({ months: undefined })
      this.selectedYear = value
    },
    /* invoicesInSelectedYearCheck () {
      if (this.invoiceStatusInfo.filter(x => x.invoiceYear === this.selectedYear)[0] === undefined) {
        return true
      } else {
        return false
      }
    }, */
    checkInvoiceStatus (month) {
      var invoiceDataYear = this.invoiceStatusInfo.filter(x => x.invoiceYear === this.selectedYear)
      var invoiceDataMonth = invoiceDataYear.filter(x => x.invoiceMonth === month)
      if (invoiceDataMonth.length > 0) {
        if (invoiceDataMonth[0].checked === true && invoiceDataMonth[0].invoiceNumber !== null) {
          return true
        } else { return false }
      } else { return false }
    },
    openModal () {
      this.form.year = this.getCurrentYear
      this.form.months = undefined
      this.selectedYear = this.getCurrentYear
      this.showModal = true
    },
    cancel () {
      this.showModal = false
      this.selectedMonth = undefined
    },
    submitDownloadModal () {
      this.formRef.value.validate().then(async () => {
        const values = toRaw(this.form)
        this.printInvoice(values.months, values.year)
      })
    },
    async printInvoice (month, year) {
      this.loading = true
      await wpapi.createInvoice(this.accountUUID, month, year).then(response => {
        var filename = response.headers['content-disposition'].split('filename=')[1]
        var blob = new Blob([response.data], { type: 'application/pdf' })
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      }).catch(error => {
        console.log(error)
      })
      this.loading = false
      this.showModal = false
    }
  }
  // /Moritz Code
}
</script>

<style lang="scss" scoped>

.invoiceSelection{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.invoiceIcon {
  font-size: 18px;
  margin-left: 1.5rem;
  margin-right: 0.75rem;
}

.invoiceIcon:hover {
  color: #3D7CAF;
}
</style>
