<template>
  <a-spin :spinning="loading">
    <div class='card-grey'>
    <div>
      <div class="card-grey-header">{{ $t('label.timer') }}</div>
    </div>
    <div>
      <div class="text">{{ $t('label.timer.explanation') }}</div>
      <div class="text" style="margin-top: 10px; margin-bottom: 10px;">{{ $t('label.timer.currentServerTime') + currentServerTime }}</div>
    </div>
    <a-form
      v-ctrl-enter="submitSetTimer"
      :ref="formRef"
      :model="form"
      :rules="rules"
      @finish="submitSetTimer"
      layout="vertical"
    >
      <div style="margin-bottom: 20px; max-width: 40em;" v-if="instanceTimerData !== undefined">
        <a-table
          :bordered="true"
          size="small"
          style="overflow-y: auto"
          :columns="instancetimerColumns"
          :dataSource="instanceTimerData"
          :rowKey="record => record.day"
          :pagination="false"
        >
        <template #customTitle>
          <span style="margin-right: 5px;">{{ $t('label.activate') }}</span><a-checkbox @change="allCheckboxes" v-model:checked="called"></a-checkbox>
        </template>
        <template #start="{ record }">
          <a-form-item :ref="record.day + '_start'" :name="record.day + '_start'" >
            <a-time-picker v-model:value="form[record.day + '_start']" format="HH:mm" :minute-step="5" :disabled="!form[record.day + '_check']"></a-time-picker>
          </a-form-item>
        </template>
        <template #stop="{ record }">
          <a-form-item :ref="record.day + '_stop'" :name="record.day + '_stop'">
            <a-time-picker v-model:value="form[record.day + '_stop']" format="HH:mm" :minute-step="5" :disabled="!form[record.day + '_check']"></a-time-picker>
          </a-form-item>
        </template>
        <template #day="{ record }">
          <span>{{ $t('label.' + record.day) }}</span>
        </template>
        <template #enabled="{ record }">
          <a-form-item :ref="record.day + '_check'" :name="record.day + '_check'">
            <a-checkbox v-model:checked="form[record.day + '_check']"></a-checkbox>
          </a-form-item>
        </template>
        </a-table>
      </div>
      <div>
        <a-button type="primary" @click="submitSetTimer()">{{ $t('label.save') }}</a-button>
      </div>
    </a-form>
    <a-table
      size="small"
      style="margin-top: 15px; overflow: auto; max-height: 40em;"
      :columns="logColumns"
      :dataSource="timerLogs"
      :rowKey="record => record.timestamp"
      :pagination="false"
    >
    </a-table>
    </div>
  </a-spin>
</template>

<script>
import TooltipButton from '@/components/widgets/TooltipButton'
import { ref, reactive, toRaw } from 'vue'
import api2 from '@/wpApi/api2'
import moment from 'moment'

export default {
  name: 'VmSchedules',
  components: {
    TooltipButton
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      instanceTimerData: [],
      timerLogs: [],
      logColumns: [
        {
          title: this.$t('label.type'),
          dataIndex: 'level'
        },
        {
          title: this.$t('label.status'),
          dataIndex: 'event'
        },
        {
          title: this.$t('label.created'),
          dataIndex: 'timestamp',
          width: '12em'
        }
      ],
      // eslint-disable-next-line
      called: false,
      loading: false,
      currentServerTime: '',
      showAddAlert: false,
      showDeleteAlert: false,
      mockTimer: {
        uuid: '123',
        days: [
          {
            day: 'monday',
            enabled: true,
            start: '08:00',
            stop: '22:00'
          },
          {
            day: 'tuesday',
            enabled: true,
            start: '08:00',
            stop: '22:00'
          },
          {
            day: 'wednesday',
            enabled: true,
            start: '08:00',
            stop: '22:00'
          },
          {
            day: 'thursday',
            enabled: true,
            start: '08:00',
            stop: '22:00'
          },
          {
            day: 'friday',
            enabled: true,
            start: '08:00',
            stop: '22:00'
          },
          {
            day: 'saturday',
            enabled: true,
            start: '08:00',
            stop: '22:00'
          },
          {
            day: 'sunday',
            enabled: true,
            start: '08:00',
            stop: '22:00'
          }
        ],
        error: ''
      },
      dummyInstancetimer: {
        uuid: this.$route.params.id,
        days: [
          {
            day: 'monday',
            enabled: false,
            start: '',
            stop: ''
          },
          {
            day: 'tuesday',
            enabled: false,
            start: '08:00',
            stop: '22:00'
          },
          {
            day: 'wednesday',
            enabled: false,
            start: '',
            stop: ''
          },
          {
            day: 'thursday',
            enabled: false,
            start: '',
            stop: ''
          },
          {
            day: 'friday',
            enabled: false,
            start: '',
            stop: ''
          },
          {
            day: 'saturday',
            enabled: false,
            start: '',
            stop: ''
          },
          {
            day: 'sunday',
            enabled: false,
            start: '',
            stop: ''
          }
        ],
        error: ''
      },
      instancetimerColumns: [
        {
          title: this.$t('label.day'),
          dataIndex: 'day',
          slots: { customRender: 'day' },
          width: '6em'
        },
        {
          title: this.$t('label.start.time'),
          dataIndex: 'start',
          slots: { customRender: 'start' },
          width: '8.5em'
        },
        {
          title: this.$t('label.end.time'),
          dataIndex: 'stop',
          slots: { customRender: 'stop' },
          width: '8.5em'
        },
        {
          dataIndex: 'enabled',
          slots: { customRender: 'enabled', title: 'customTitle' },
          align: 'center',
          width: '6em'
        }
      ]
    }
  },
  created () {
    this.initForm()
  },
  async mounted () {
    this.fetchTimerData()
    this.getCurrentServerTime()
    this.fetchTimerLogs()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
      })
      this.rules = reactive({
      })
    },
    getCurrentServerTime () {
      // Incoming time format is 15:10
      api2.getCurrentServerTime().then(response => {
        this.currentServerTime = response.data
      })
    },
    async fetchTimerData () {
      this.loading = true
      api2.getInstanceTimer(this.$route.params.id).then(response => {
        this.instanceTimerData = response.data.days
        this.getTimerData(response.data)
      }).catch(async error => {
        console.log('error', error.response)
        if (error.response.status === 500) {
          this.instanceTimerData = this.dummyInstancetimer.days
          await api2.postInstanceTimer(this.dummyInstancetimer).then(async response => {
          }).catch(error => {
            console.log('error', error.response)
          })
          await api2.getInstanceTimer(this.$route.params.id).then(async response => {
            this.instanceTimerData = response.data.days
            this.getTimerData(response.data)
          }).catch(error => {
            console.log('error', error.response)
          })
        } else {
          this.instanceTimerData = this.dummyInstancetimer.days
        }
      })
      this.loading = false
    },
    async fetchTimerLogs () {
      await api2.getTimerLogs(this.$route.params.id).then(response => {
        this.timerLogs = response.data
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    allCheckboxes (event) {
      if (this.called) {
        this.form.monday_check = true
        this.form.tuesday_check = true
        this.form.wednesday_check = true
        this.form.thursday_check = true
        this.form.friday_check = true
        this.form.saturday_check = true
        this.form.sunday_check = true
      } else {
        this.form.monday_check = false
        this.form.tuesday_check = false
        this.form.wednesday_check = false
        this.form.thursday_check = false
        this.form.friday_check = false
        this.form.saturday_check = false
        this.form.sunday_check = false
      }
    },
    async getTimerData (input) {
      var startTimeString = input.days[0].start.split(':')
      var endTimeString = input.days[0].stop.split(':')
      this.form.monday_start = (!startTimeString[0]) ? null : moment().set({ hour: startTimeString[0], minute: startTimeString[1] })
      this.form.monday_stop = (!endTimeString[0]) ? null : moment().set({ hour: endTimeString[0], minute: endTimeString[1] })

      startTimeString = input.days[1].start.split(':')
      endTimeString = input.days[1].stop.split(':')
      this.form.tuesday_start = (!startTimeString[0]) ? null : moment().set({ hour: startTimeString[0], minute: startTimeString[1] })
      this.form.tuesday_stop = (!endTimeString[0]) ? null : moment().set({ hour: endTimeString[0], minute: endTimeString[1] })

      startTimeString = input.days[2].start.split(':')
      endTimeString = input.days[2].stop.split(':')
      this.form.wednesday_start = (!startTimeString[0]) ? null : moment().set({ hour: startTimeString[0], minute: startTimeString[1] })
      this.form.wednesday_stop = (!endTimeString[0]) ? null : moment().set({ hour: endTimeString[0], minute: endTimeString[1] })

      startTimeString = input.days[3].start.split(':')
      endTimeString = input.days[3].stop.split(':')
      this.form.thursday_start = (!startTimeString[0]) ? null : moment().set({ hour: startTimeString[0], minute: startTimeString[1] })
      this.form.thursday_stop = (!endTimeString[0]) ? null : moment().set({ hour: endTimeString[0], minute: endTimeString[1] })

      startTimeString = input.days[4].start.split(':')
      endTimeString = input.days[4].stop.split(':')
      this.form.friday_start = (!startTimeString[0]) ? null : moment().set({ hour: startTimeString[0], minute: startTimeString[1] })
      this.form.friday_stop = (!endTimeString[0]) ? null : moment().set({ hour: endTimeString[0], minute: endTimeString[1] })

      startTimeString = input.days[5].start.split(':')
      endTimeString = input.days[5].stop.split(':')
      this.form.saturday_start = (!startTimeString[0]) ? null : moment().set({ hour: startTimeString[0], minute: startTimeString[1] })
      this.form.saturday_stop = (!endTimeString[0]) ? null : moment().set({ hour: endTimeString[0], minute: endTimeString[1] })

      startTimeString = input.days[6].start.split(':')
      endTimeString = input.days[6].stop.split(':')
      this.form.sunday_start = (!startTimeString[0]) ? null : moment().set({ hour: startTimeString[0], minute: startTimeString[1] })
      this.form.sunday_stop = (!endTimeString[0]) ? null : moment().set({ hour: endTimeString[0], minute: endTimeString[1] })

      this.form.monday_check = input.days[0].enabled
      this.form.tuesday_check = input.days[1].enabled
      this.form.wednesday_check = input.days[2].enabled
      this.form.thursday_check = input.days[3].enabled
      this.form.friday_check = input.days[4].enabled
      this.form.saturday_check = input.days[5].enabled
      this.form.sunday_check = input.days[6].enabled

      this.loading = false
    },
    submitSetTimer () {
      // TODO check if values are null, if so set to ""
      this.formRef.value.validate().then(() => {
        this.loading = true
        const values = toRaw(this.form)
        this.dummyInstancetimer.days[0].enabled = values.monday_check
        this.dummyInstancetimer.days[0].start = (values.monday_start) ? values.monday_start.format('HH:mm') : ''
        this.dummyInstancetimer.days[0].stop = (values.monday_stop) ? values.monday_stop.format('HH:mm') : ''
        this.dummyInstancetimer.days[1].enabled = values.tuesday_check
        this.dummyInstancetimer.days[1].start = (values.tuesday_start) ? values.tuesday_start.format('HH:mm') : ''
        this.dummyInstancetimer.days[1].stop = (values.tuesday_stop) ? values.tuesday_stop.format('HH:mm') : ''
        this.dummyInstancetimer.days[2].enabled = values.wednesday_check
        this.dummyInstancetimer.days[2].start = (values.wednesday_start) ? values.wednesday_start.format('HH:mm') : ''
        this.dummyInstancetimer.days[2].stop = (values.wednesday_stop) ? values.wednesday_stop.format('HH:mm') : ''
        this.dummyInstancetimer.days[3].enabled = values.thursday_check
        this.dummyInstancetimer.days[3].start = (values.thursday_start) ? values.thursday_start.format('HH:mm') : ''
        this.dummyInstancetimer.days[3].stop = (values.thursday_stop) ? values.thursday_stop.format('HH:mm') : ''
        this.dummyInstancetimer.days[4].enabled = values.friday_check
        this.dummyInstancetimer.days[4].start = (values.friday_start) ? values.friday_start.format('HH:mm') : ''
        this.dummyInstancetimer.days[4].stop = (values.friday_stop) ? values.friday_stop.format('HH:mm') : ''
        this.dummyInstancetimer.days[5].enabled = values.saturday_check
        this.dummyInstancetimer.days[5].start = (values.saturday_start) ? values.saturday_start.format('HH:mm') : ''
        this.dummyInstancetimer.days[5].stop = (values.saturday_stop) ? values.saturday_stop.format('HH:mm') : ''
        this.dummyInstancetimer.days[6].enabled = values.sunday_check
        this.dummyInstancetimer.days[6].start = (values.sunday_start) ? values.sunday_start.format('HH:mm') : ''
        this.dummyInstancetimer.days[6].stop = (values.sunday_stop) ? values.sunday_stop.format('HH:mm') : ''
        this.dummyInstancetimer.uuid = this.$route.params.id
        api2.updateInstanceTimer(this.dummyInstancetimer).then(response => {
          this.loading = false
        }).catch(error => {
          this.$notifyError(error)
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    font-weight: bold;
  }
</style>
