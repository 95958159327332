// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div :class="['form-layout', { 'form-list': selectedRowKeys.length > 0 }]" v-ctrl-enter="handleSubmit">
    <div v-if="selectedRowKeys.length === 0">
      <a-alert type="warning">
        <template #message>
          <span v-html="resource.backupofferingid ? $t('message.action.destroy.instance.with.backups') : $t('message.action.destroy.instance')"></span>
        </template>
      </a-alert>
      <br/>
      <span style="color: red;" v-if="resource.state === 'Running'">{{  this.$t('message.delete.vm') }}</span>
      <a-spin :spinning="loading">
        <a-form
          :model="form"
          :ref="formRef"
          :rules="rules"
          @finish="handleSubmit"
          layout="vertical">
          <!-- <a-form-item
            name="expunge"
            ref="expunge"
            v-if="$store.getters.userInfo.roletype === 'Admin' || $store.getters.features.allowuserexpungerecovervm">
            <template #label>
              <tooltip-label :title="$t('label.expunge')" :tooltip="apiParams.expunge.description"/>
            </template>
            <a-switch v-model:checked="form.expunge" v-focus="true" />
          </a-form-item>

          <a-form-item v-if="volumes.length > 0" name="volumeids" ref="volumeids">
            <template #label>
              <tooltip-label :title="$t('label.delete.volumes')" :tooltip="apiParams.volumeids.description"/>
            </template>
            <a-select
              v-model:value="form.volumeids"
              :placeholder="$t('label.delete.volumes')"
              mode="multiple"
              :loading="loading"
              v-focus="$store.getters.userInfo.roletype !== 'Admin' && !$store.getters.features.allowuserexpungerecovervm"
              showSearch
              optionFilterProp="label"
              :filterOption="(input, option) => {
                return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }" >
              <a-select-option v-for="volume in volumes" :key="volume.id">
                {{ volume.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <p v-else v-html="$t('label.volume.empty')" /> -->

          <div :span="24" class="action-button">
            <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
            <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit" :disabled="resource.state === 'Running'">{{ $t('label.ok') }}</a-button>
          </div>
        </a-form>
      </a-spin>
    </div>
    <div v-else>
      <div v-if="!showGroupActionModal">
        <div>
          <a-alert type="error">
            <message-outlined type="exclamation-circle" style="color: red; fontSize: 30px; display: inline-flex" />
            <template #message>
              <span style="padding-left: 5px" v-html="`<b>${selectedRowKeys.length} ` + $t('label.items.selected') + `. </b>`" />
              <span v-html="$t(action.currentAction.message)" />
            </template>
          </a-alert>
        </div>
        <div v-if="selectedRowKeys.length > 0" class="row-keys">
          <a-divider />
          <a-form layout="vertical">
            <a-table
              v-if="selectedRowKeys.length > 0"
              size="middle"
              :columns="chosenColumns"
              :dataSource="selectedItems"
              :rowKey="(record, idx) => record.id || record.name || record.usageType || idx + '-' + Math.random()"
              :pagination="true"
              style="overflow-y: auto"
            >
              <template
                #expandedRowRender="{ record } "
                style="margin: 0">
                <a-form-item :label="$t('label.delete.volumes')" v-if="listVolumes[record.id].opts.length > 0">
                  <a-select
                    mode="multiple"
                    showSearch
                    optionFilterProp="label"
                    :filterOption="(input, option) => {
                      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }"
                    :loading="listVolumes[record.id].loading"
                    :placeholder="$t('label.delete.volumes')"
                    @change="(value) => onChangeVolume(record.id, value)">
                    <a-select-option v-for="item in listVolumes[record.id].opts" :key="item.id">
                      {{ item.name || item.description }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <span v-else v-html="$t('label.volume.empty')" />
              </template>
            </a-table>
            <!-- <a-form-item v-if="$store.getters.userInfo.roletype === 'Admin' || $store.getters.features.allowuserexpungerecovervm">
              <template #label>
                <tooltip-label :title="$t('label.expunge')" :tooltip="apiParams.expunge.description"/>
              </template>
              <a-switch v-model:checked="expunge" v-focus="true" />
            </a-form-item> -->
          </a-form>
        </div>
        <p v-if="selectedItems.filter(vm => vm.state === 'Running')[0] !== undefined" style="color: red;"> {{ this.$t('message.delete.vm.multiselect') }}</p>
        <div :span="24" class="action-button">
          <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
          <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit" :disabled="selectedItems.filter(vm => vm.state === 'Running')[0] !== undefined">{{ $t('label.ok') }}</a-button>
        </div>
      </div>
    </div>
    <bulk-action-progress
      :showGroupActionModal="showGroupActionModal"
      :selectedItems="selectedItemsProgress"
      :selectedColumns="selectedColumns"
      :message="modalInfo"
      @handle-cancel="handleCancel" />
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { api } from '@/api'
import wpapi from '../../wpApi/myApi'
import api2 from '@/wpApi/api2'
import TooltipLabel from '@/components/widgets/TooltipLabel'
import BulkActionProgress from '@/components/view/BulkActionProgress'

export default {
  name: 'DestroyVM',
  components: {
    TooltipLabel,
    BulkActionProgress
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    action: {
      type: Object,
      default: () => {}
    },
    selectedRowKeys: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Array,
      default: () => []
    },
    chosenColumns: {
      type: Array,
      default: () => []
    }
  },
  inject: ['parentFetchData'],
  data () {
    return {
      // WP Implementation
      destroyVmInput: undefined,
      // /WP Implementation
      volumes: [],
      loading: false,
      diskVolumeInput: [],
      volumeIds: {},
      listVolumes: {},
      selectedColumns: [],
      selectedItemsProgress: [],
      showGroupActionModal: false,
      modalInfo: {},
      expunge: true,
      vmOwnerAccUuid: String,
      isActivated: false,
      acronisTenantIdCheck: /^[A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12}$/,
      proxyId: '',
      getVgpuInfoResponse: ''
    }
  },
  computed: {
    pollJobBool () {
      if (this.getVgpuInfoResponse !== false && this.getVgpuInfoResponse !== '') {
        return true
      } else { return false }
    }
  },
  beforeCreate () {
    this.apiParams = this.$getApiParams('destroyVirtualMachine')
  },
  async created () {
    this.formRef = ref()
    this.form = reactive({})
    this.rules = reactive({
      volumeids: [{ type: 'array' }]
    })
    this.fetchData()
    this.fetchResellerCustomerAccounts()
    await this.getVmOwnerAccId()
    this.fetchProxyData()
    this.fetchAcronisVmStatus()
  },
  mounted () {
    this.fetchVgpu()
  },
  methods: {
    // WP Implementation
    fetchVgpu () {
      var vgpuInput = new FormData()
      vgpuInput.append('vmuuid', this.resource.id)
      api2.getAllocatedGpu(vgpuInput).then(response => {
        this.getVgpuInfoResponse = response.data
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: 'Error fetching GPU State of this VM'
        })
      })
    },
    async fetchResellerCustomerAccounts () {
      if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' || this.$store.getters.userInfo.roletype === 'Admin') {
        await wpapi.getResellerSubAccounts(this.$store.getters.userInfo.accountid).then(response => {
          if (response.data.filter(x => x.accountName === this.resource.account).length > 0) {
            this.destroyVmInput = response.data.filter(x => x.accountName === this.resource.account)[0].uuid
          } else { this.destroyVmInput = this.$store.getters.userInfo.accountid }
        }).catch(error => {
          console.log(error)
        })
      } else { this.destroyVmInput = this.$store.getters.userInfo.accountid }
    },
    /**
     * Get the AccountId of the owner of the current VM
     *
     * call clodustack "listAccounts" Api and compare its accountname with the accountname of the current VM
     */
    async getVmOwnerAccId () {
      this.loading = true
      await api('listAccounts', { listall: true, name: this.resource.account }).then(response => {
        var responseAccUuid = response.listaccountsresponse.account.filter(account => account.name === this.resource.account)[0].id
        this.vmOwnerAccUuid = responseAccUuid !== undefined ? responseAccUuid : ''
        this.loading = false
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    /**
     * Get the status of the current VM, if its connected with acronis or not.
     *
     * call API2 "getAcronisVM" with vmid (VmUUID) to fetch the VM Status and save it in the global variable "isActivated"
     */
    async fetchAcronisVmStatus () {
      this.loading = true
      var getUserParams = new FormData()
      getUserParams.append('vmid', this.resource.id)
      await api2.getAcronisVM(getUserParams).then(response => {
        if (response.data && response.data.status && response.data.status === 'error') {
          this.isActivated = false
          this.loading = false
        } else if (response.data && response.data.id && this.acronisTenantIdCheck.test(response.data.id)) {
          this.isActivated = true
          this.loading = false
        } else {
          console.log('Failed to request Acronis VM Status')
          this.isActivated = false
          this.loading = false
        }
      }).catch(error => {
        this.$notifyError(error)
        this.isActivated = false
        this.loading = false
      })
    },
    async fetchProxyData () {
      this.loading = true
      var getProxyparams = new FormData()
      getProxyparams.append('accountid', this.vmOwnerAccUuid)
      getProxyparams.append('vpcid', this.resource.nic[0].vpcid)
      await api2.getProxySettings(getProxyparams).then(response => {
        this.proxyId = response.data.proxyid
        // this.proxyDomain = response.data.domain
      }).catch(error => {
        this.$notifyError(error)
      })
      this.loading = false
    },
    async deleteProxyClient () {
      if (this.proxyId !== undefined && this.proxyId !== '') {
        var params = new FormData()
        params.append('virtualmachineid', this.resource.id)
        params.append('proxyid', this.proxyId)
        await api2.delProxyClient(params).then(response => {
          if (response.data.status === 'error') {
            this.$notification.error({
              message: 'Error deleting Proxy Client!',
              description: this.$t('message.error.delete.proxy.client'),
              duration: 0
            })
          }
        }).catch(error => {
          this.$notifyError(error)
        })
      }
    },
    // /WP Implementation
    fetchData () {
      if (this.selectedRowKeys.length === 0) {
        this.fetchVolumes()
      } else {
        const promises = []
        this.selectedRowKeys.forEach(vmId => {
          this.listVolumes[vmId] = {
            loading: true,
            opts: []
          }
          promises.push(this.callListVolume(vmId))
        })
        Promise.all(promises).then((data) => {
          data.forEach(item => {
            this.listVolumes[item.id].loading = false
            this.listVolumes[item.id].opts = item.volumes || []
          })
        })
      }
    },
    async fetchVolumes () {
      this.loading = true
      const data = await this.callListVolume(this.resource.id)
      this.volumes = data.volumes || []
      if (this.volumes.length > 0) {
        for (const volume in this.volumes) {
          this.diskVolumeInput.push(this.volumes[volume].id)
        }
      }
      this.loading = false
    },
    callListVolume (vmId) {
      return new Promise((resolve) => {
        this.volumes = []
        api('listVolumes', {
          virtualMachineId: vmId,
          type: 'DATADISK',
          details: 'min',
          listall: 'true'
        }).then(json => {
          const volumes = json.listvolumesresponse.volume || []
          resolve({
            id: vmId,
            volumes
          })
        })
      })
    },
    onChangeVolume (vmId, volumes) {
      this.volumeIds[vmId] = volumes
    },
    handleCancel () {
      this.$emit('cancel-bulk-action')
      this.showGroupActionModal = false
      this.selectedItemsProgress = []
      this.selectedColumns = []
      this.closeAction()
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.resource.name.startsWith('saas-manager')) {
        this.$notification.info({
          message: 'SaaS-Manager VM',
          description: this.$t('message.error.delete.saas.vm'),
          duration: 0
        })
        return
      }
      if (this.loading) return
      if (this.selectedRowKeys.length > 0) {
        this.destroyGroupVMs()
      } else {
        this.formRef.value.validate().then(async () => {
          const params = {
            id: this.resource.id,
            volumeids: [],
            expunge: true
          }
          // WP Implementation
          if (this.volumes.length > 0) {
            params.volumeids = this.diskVolumeInput.join(',')
          }
          if (this.$store.getters.userInfo.roletype === 'Admin' || this.$store.getters.features.allowuserexpungerecovervm) {
            params.expunge = true
          }
          // /WP Implementation
          this.loading = true
          try {
            const jobId = await this.destroyVM(params)
            await this.$pollJob({
              jobId,
              callApi2: this.pollJobBool,
              title: this.$t('label.action.destroy.instance'),
              description: this.resource.name,
              loadingMessage: `${this.$t('message.deleting.vm')} ${this.resource.name}`,
              catchMessage: this.$t('error.fetching.async.job.result'),
              successMessage: `${this.$t('message.success.delete.vm')} ${this.resource.name}`,
              successMethod: () => {
                // WP Implementation
                if (this.$route.path.includes('/vm/')) {
                  // /WP Implementation
                  this.$router.go(-1)
                } else {
                  this.parentFetchData()
                }
                // WP Implementation
                wpapi.vmBillingApiCreateDestroyVm(this.destroyVmInput).then(response => {
                  console.log('WP API CHECK FOR CREATION OR DELETION OF VM!')
                }).catch(error => {
                  this.$notifyError(error)
                })
                api2.deleteInstanceTimer(this.resource.id).then(response => {
                }).catch(error => {
                  console.log(error)
                })
                // delete Acronis VM if active
                if (this.isActivated) {
                  var deleteAcronisVmParams = new FormData()
                  deleteAcronisVmParams.append('cloud_account_id', this.vmOwnerAccUuid)
                  deleteAcronisVmParams.append('vm_id', this.resource.id)
                  api2.deleteAcronisVM(deleteAcronisVmParams).then(response => {
                  }).catch(error => {
                    this.$notifyError(error)
                  })
                }
                // delete Proxy Client
                this.deleteProxyClient()
                // /WP Implementation
              },
              action: {
                isFetchData: false
              }
            })
            await this.closeAction()
            this.loading = false
          } catch (error) {
            await this.$notifyError(error)
            await this.closeAction()
            this.loading = false
          }
        }).catch(error => {
          this.formRef.value.scrollToField(error.errorFields[0].name)
        })
      }
    },
    destroyVM (params) {
      return new Promise((resolve, reject) => {
        if (this.getVgpuInfoResponse !== false && this.getVgpuInfoResponse !== '') {
          var api2Params = new FormData()
          api2Params.append('vmid', this.resource.id)
          api2.destroyVirtualMachine(api2Params).then(response => {
            console.log('destroyVM API2 response', response)
            const jobId = response.data
            return resolve(jobId)
          }).catch(error => {
            return reject(error)
          })
        } else {
          api('destroyVirtualMachine', params).then(json => {
            const jobId = json.destroyvirtualmachineresponse.jobid
            return resolve(jobId)
          }).catch(error => {
            return reject(error)
          })
        }
      })
    },
    destroyGroupVMs () {
      this.selectedColumns = Array.from(this.chosenColumns)
      this.selectedItemsProgress = Array.from(this.selectedItems)
      this.selectedItemsProgress = this.selectedItemsProgress.map(v => ({ ...v, status: 'InProgress' }))
      this.selectedColumns.splice(0, 0, {
        dataIndex: 'status',
        title: this.$t('label.operation.status'),
        slots: { customRender: 'status' },
        filters: [
          { text: 'In Progress', value: 'InProgress' },
          { text: 'Success', value: 'success' },
          { text: 'Failed', value: 'failed' }
        ]
      })
      this.showGroupActionModal = true
      this.modalInfo.title = this.action.currentAction.label
      this.modalInfo.docHelp = this.action.currentAction.docHelp
      const promises = []
      this.selectedRowKeys.forEach(vmId => {
        const params = {}
        params.id = vmId
        if (this.volumeIds[vmId] && this.volumeIds[vmId].length > 0) {
          params.volumeids = this.volumeIds[vmId].join(',')
        }
        if (this.expunge) {
          params.expunge = this.expunge
        }
        promises.push(this.callGroupApi(params))
      })
      this.$message.info({
        content: this.$t(this.action.currentAction.label),
        key: this.action.currentAction.label,
        duration: 3
      })
      this.loading = true
      Promise.all(promises).finally(() => {
        this.loading = false
        this.parentFetchData()
      })
    },
    callGroupApi (params) {
      return new Promise((resolve, reject) => {
        const resource = this.selectedItems.filter(item => item.id === params.id)[0] || {}
        this.destroyVM(params).then(jobId => {
          this.updateResourceState(resource.id, 'InProgress', jobId)
          this.$pollJob({
            jobId,
            showLoading: false,
            bulkAction: false,
            title: this.$t('label.action.destroy.instance'),
            catchMessage: this.$t('error.fetching.async.job.result'),
            successMessage: `${this.$t('message.success.delete.vm')} ${resource.name}`,
            successMethod: () => {
              this.updateResourceState(resource.id, 'success')
              return resolve()
            },
            errorMethod: () => {
              this.updateResourceState(resource.id, 'failed')
            },
            action: {
              isFetchData: false
            }
          })
        }).catch(error => {
          this.$notifyError(error)
          this.updateResourceState(resource.id, 'failed')
          return reject(error)
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    },
    updateResourceState (resource, state, jobId) {
      const objIndex = this.selectedItemsProgress.findIndex(item => item.id === resource)
      if (state && objIndex !== -1) {
        this.selectedItemsProgress[objIndex].status = state
      }
      if (jobId && objIndex !== -1) {
        this.selectedItemsProgress[objIndex].jobid = jobId
      }
    },
    async closeAction () {
      this.$emit('close-action')
    }
  }
}
</script>

<style scoped lang="less">
  .form-layout {
    &.form-list {
      max-width: 60vw;
    }

    &:not(.form-list) {
      width: 60vw;

      @media (min-width: 500px) {
        width: 450px;
      }
    }

    .row-keys {
      .ant-select {
        display: block;
        width: 90%;
      }
    }
  }
</style>
